import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

interface User {
  picture: string | undefined;
  name: string | undefined;
}

interface AvatarProps {
  user?: User;
  userName?: string | null;
  userPicture?: string | null;
  size?: number;
  className?: string;
}

const Avatar = ({ className, size = 24, userName, userPicture }: AvatarProps) => {
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const avatar = userPicture || '';
  const name = userName || '';
  return (
    <AvatarContainer size={size} className={className}>
      {avatar && !failedToLoad ? (
        <img src={avatar} alt="" onError={() => setFailedToLoad(true)} />
      ) : (
        <span> {name.substring(0, 1).toUpperCase()}</span>
      )}
    </AvatarContainer>
  );
};

interface AvatarContainerProps {
  size: number;
}

const AvatarContainer = styled.div<AvatarContainerProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) => props.theme.colors.gray[90]};
  border-radius: 24px;
  color: white;
  display: flex;
  justify-content: center;
  span {
    font-size: 13px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fonts.text};
    align-self: center;
  }
  img {
    align-self: center;
    border-radius: 16px;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }
`;
AvatarContainer.displayName = 'AvatarContainer';

export default Avatar;
