import * as amplitude from '@amplitude/analytics-browser';
import { NavigateFunction } from 'react-router-dom';
import { all, call, delay, fork, put, select, take, takeLeading } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { HubSpotEvent, USE_SITE_AUTH } from 'src/declarations/constants';
import { Urls } from 'src/declarations/urls';
import { AuthError, AuthResult } from 'src/interfaces/Auth';
import { BootParams } from 'src/interfaces/BootParams';
import { Credentials } from 'src/interfaces/Credentials';
import { AuthStore } from 'src/interfaces/StateStore';
import { User } from 'src/interfaces/User';
import { UserPermissions } from 'src/interfaces/UserPermissions';
import utils from 'src/redux/reducks/utils';
import { getCookies } from 'src/redux/selectors';
import { BrowserStorage, isPlanID, planPricing, ProductPlan } from 'src/utils';
import { isApiErrorResponse } from 'src/utils/api';
import * as BaseDuck from './BaseDuck';
import { SagaDuck } from './SagaDuck';
import { apiRequests } from './api';

export interface LoginPayload {
  email: string;
  password: string;
}

export interface SignupPayload {
  email: string;
  username: string;
  password: string;
  name: string;
}

export interface UpdateUserPayload {
  username?: string;
  name?: string;
  email?: string;
  new_password?: string;
  password?: string;
  avatar?: string;
}

interface UpdateUserBody {
  user: User;
  body: UpdateUserPayload;
  closeModals?: boolean;
}

export type LoginAction = BaseDuck.Action<'LOGIN_USER', LoginPayload>;
export type SignupAction = BaseDuck.Action<'SIGNUP_USER', SignupPayload>;
export type LoadCurrentUser = BaseDuck.Action<'LOAD_CURRENT_USER', Pick<AuthStore, 'isImpersonating'>>;
export type PutCurrentUser = BaseDuck.Action<'PUT_CURRENT_USER', User>;
export type UpdateCurrentUser = BaseDuck.Action<'UPDATE_CURRENT_USER', UpdateUserBody>;
export type ImpersonateUser = BaseDuck.Action<
  'IMPERSONATE_USER',
  {
    userId: string | number;
    redirect?: string;
  }
>;
export type StartPasswordReset = BaseDuck.Action<
  'START_PASSWORD_RESET',
  {
    email: string;
    recaptcha: string;
  }
>;
export type FinishPasswordReset = BaseDuck.Action<
  'FINISH_PASSWORD_RESET',
  {
    token: string;
    password: string;
    recaptcha: string;
    navigate: NavigateFunction;
  }
>;
export type LogoutAction = BaseDuck.Action<'LOGOUT_USER', {}>;
export type ClearSession = BaseDuck.Action<'LOGOUT', {}>;
export type ReceiveOAuth2Auth = BaseDuck.Action<
  'RECEIVE_OAUTH2_AUTH',
  { err?: AuthError; result?: AuthResult; pathname?: string; search?: string }
>;
export type ReceiveCredentials = BaseDuck.Action<'RECEIVE_CREDENTIALS', Credentials>;
export type GetUserPermissions = BaseDuck.Action<
  'GET_USER_PERMISSIONS',
  {
    userId: string | number;
  }
>;
export type ReceiveUserPermissions = BaseDuck.Action<'RECEIVE_USER_PERMISSIONS', UserPermissions>;
export type ReloadAllUserInfos = BaseDuck.Action<
  'RELOAD_ALL_USER_INFOS',
  {
    userId?: string | number;
  }
>;
export type ReloadAllUserInfosAndRedirect = BaseDuck.Action<
  'RELOAD_ALL_USER_INFOS_AND_REDIRECT',
  { userId?: string | number; redirectTo: string; navigate: NavigateFunction }
>;
export type ReceiveBootParams = BaseDuck.Action<'RECEIVE_BOOT_PARAMS', BootParams>;
export type UpdateBootInfo = BaseDuck.Action<'UPDATE_BOOT_INFO', Partial<AuthStore['bootInfo']>>;
export type Start = BaseDuck.Action<
  'BOOTSTRAP_APP_START',
  {
    bootParams?: BootParams;
    pathname?: string;
    search?: string;
  }
>;

export type AuthActions = {
  login: LoginAction;
  signup: SignupAction;
  logout: LogoutAction;
  loadCurrentUser: LoadCurrentUser;
  startPasswordReset: StartPasswordReset;
  finishPasswordReset: FinishPasswordReset;
  receiveOAuth2Auth: ReceiveOAuth2Auth;
  receiveCredentials: ReceiveCredentials;
  putCurrentUser: PutCurrentUser;
  impersonateUser: ImpersonateUser;
  clearSession: ClearSession;
  getUserPermissions: GetUserPermissions;
  receiveUserPermissions: ReceiveUserPermissions;
  reloadAllUserInfos: ReloadAllUserInfos;
  reloadAllUserInfosAndRedirect: ReloadAllUserInfosAndRedirect;
  receiveBootParams: ReceiveBootParams;
  updateBootInfo: UpdateBootInfo;
  bootStart: Start;
};

export type AllActions = BaseDuck.AllActions<AuthActions>;

export class AuthDuck extends SagaDuck<'auth', AuthActions> {
  actions = {
    login: utils.actionMaker<LoginAction>('LOGIN_USER'),
    signup: utils.actionMaker<SignupAction>('SIGNUP_USER'),
    logout: utils.actionMaker<LogoutAction>('LOGOUT_USER'),
    loadCurrentUser: utils.actionMaker<LoadCurrentUser>('LOAD_CURRENT_USER'),
    startPasswordReset: utils.actionMaker<StartPasswordReset>('START_PASSWORD_RESET'),
    finishPasswordReset: utils.actionMaker<FinishPasswordReset>('FINISH_PASSWORD_RESET'),
    receiveOAuth2Auth: utils.actionMaker<ReceiveOAuth2Auth>('RECEIVE_OAUTH2_AUTH'),
    receiveCredentials: utils.actionMaker<ReceiveCredentials>('RECEIVE_CREDENTIALS'),
    putCurrentUser: utils.actionMaker<PutCurrentUser>('PUT_CURRENT_USER'),
    updateCurrentUser: utils.actionMaker<UpdateCurrentUser>('UPDATE_CURRENT_USER'),
    impersonateUser: utils.actionMaker<ImpersonateUser>('IMPERSONATE_USER'),
    clearSession: utils.actionMaker<ClearSession>('LOGOUT'),
    getUserPermissions: utils.actionMaker<GetUserPermissions>('GET_USER_PERMISSIONS'),
    receiveUserPermissions: utils.actionMaker<ReceiveUserPermissions>('RECEIVE_USER_PERMISSIONS'),
    reloadAllUserInfos: utils.actionMaker<ReloadAllUserInfos>('RELOAD_ALL_USER_INFOS'),
    reloadAllUserInfosAndRedirect: utils.actionMaker<ReloadAllUserInfosAndRedirect>('RELOAD_ALL_USER_INFOS_AND_REDIRECT'),
    receiveBootParams: utils.actionMaker<ReceiveBootParams>('RECEIVE_BOOT_PARAMS'),
    updateBootInfo: utils.actionMaker<UpdateBootInfo>('UPDATE_BOOT_INFO'),
    bootStart: utils.actionMaker<Start>('BOOTSTRAP_APP_START'),
  };

  *mainSaga() {
    yield fork([this, this.authFlowLoop]);
    yield this.forkAndListen(takeLeading([this.actions.login.type, this.actions.signup.type], this.loginOrSignup.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.loadCurrentUser.type, this.loadCurrentUser.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.startPasswordReset.type, this.startPasswordReset.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.finishPasswordReset.type, this.finishPasswordRequest.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.receiveOAuth2Auth.type, this.handleOAuth2Authentication.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.updateCurrentUser.type, this.updateCurrentUser.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.impersonateUser.type, this.impersonateUser.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.getUserPermissions.type, this.getUserPermissions.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.reloadAllUserInfos.type, this.reloadAllUserInfos.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.reloadAllUserInfosAndRedirect.type, this.reloadAllUserInfosAndRedirect.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.bootStart.type, this.bootStart.bind(this)));
  }

  *bootStart(bootstrapAction: Start) {
    let bootParams: BootParams;
    if (bootstrapAction.payload?.bootParams) {
      bootParams = bootstrapAction.payload.bootParams;
    } else {
      bootParams = yield call([this, this.determineBootstrapParams], bootstrapAction.payload.pathname!, bootstrapAction.payload.search);
    }
    yield put(this.actions.receiveBootParams(bootParams));

    // @ts-ignore
    const creds = yield call([BrowserStorage, BrowserStorage.restoreCredentials]);
    const pathOverride = bootParams.nextPath;
    if (creds) {
      // store them in state store for all to use and trigger login flow
      yield put(this.actions.updateBootInfo({ pathOverride }));
      yield put(auth.actions.receiveCredentials(creds));
    } else {
      // Change from loader to Login Pages
      yield put(
        this.actions.updateBootInfo({
          isLoading: false,
          isAuthed: false,
          pathOverride,
        })
      );
    }
  }

  determineBootstrapParams(initialPath: string, initialSearch?: string): BootParams {
    // determine where they will go after signup/login/bootstrap
    // based on the plan, product, and initial route params
    const searchParams = new URLSearchParams(initialSearch);

    const wantsToStartNativeTrial = searchParams.get('native_trial') === '1';

    const planId = searchParams.get('plan');
    const plan = isPlanID(planId) ? planPricing[planId] : undefined;

    // determine where we will send them when done bootstrapping
    const nextPath = this.determineNextRoute(initialPath, initialSearch, plan);

    // The source generally comes from a query param except in the
    // case of sso (forum, marketplace, etc.) in that case we infer via the path
    let source = searchParams.get('source') || 'dash';
    let ssoRedirectInfo: BootParams['ssoRedirectInfo'];
    if (initialPath.startsWith('/sso/')) {
      source = initialPath.split('/')[2];
      if (source && initialSearch) {
        ssoRedirectInfo = { source, query: initialSearch };
      }
    }

    const utm_campaigns = searchParams.get('utm_campaigns');
    const utm_content = searchParams.get('utm_content');
    const utm_term = searchParams.get('utm_term');
    const utm_source = searchParams.get('utm_source');
    const utm_medium = searchParams.get('utm_medium');
    const utmParams: BootParams['utmParams'] = {
      utm_campaigns: utm_campaigns || undefined,
      utm_content: utm_content || undefined,
      utm_term: utm_term || undefined,
      utm_source: utm_source || undefined,
      utm_medium: utm_medium || undefined,
    };

    // The hubspot id will be synced after login is verified to associate
    // the hubspot tracking id with the user for marketing purposes
    const { hubspotutk } = getCookies(document.cookie);
    const hsid = searchParams.get('hsid') || hubspotutk || undefined;
    searchParams.delete('hsid');

    return {
      hsid,
      source,
      plan,
      nextPath,
      ssoRedirectInfo,
      utmParams,
      wantsToStartNativeTrial,
    };
  }

  private determineNextRoute(initialPath: string, initialSearch?: string, plan?: ProductPlan) {
    const ignoredPaths = ['/', '/login', '/dash', '/reset-password', '/impersonate'];
    if (plan && !plan.sku.includes('starter')) {
      return `/plan-setup/${plan.sku}/${plan.term}`;
    } else if (ignoredPaths.includes(initialPath)) {
      return undefined;
    } else {
      return `${initialPath}${initialSearch || ''}`;
    }
  }

  *authFlowLoop() {
    while (true) {
      // Wait for restored credentials
      // NOTE: according to docs effects fire after reducers so the state should be correct after RECEIVE_CREDS
      const receiveCredsAction: ReceiveCredentials = yield take(this.actions.receiveCredentials.type);
      const creds = receiveCredsAction.payload;

      // Load the user
      let user: User = yield call([this, this.loadCurrentUser], this.actions.loadCurrentUser({}));

      // Something went wrong so logout and clear boot stuffs & wait for another receive creds
      if (!user || !creds || !creds.token) {
        // just clear impersonation data from local storage so if this is the result of an
        // impersonation token expiring, the user doesn't need to explicitly log in again
        BrowserStorage.overwriteToken.delete();
        BrowserStorage.overwriteUserId.delete();
        BrowserStorage.overwriteTime.delete();

        yield put(this.actions.clearSession({}));
        // update the boot saga
        yield put(
          this.actions.updateBootInfo({
            isAuthed: false,
            isLoading: false,
          })
        );
        continue;
      }

      // store the creds/user to keep them logged in
      if (BrowserStorage.overwriteToken.get() !== creds.token) {
        BrowserStorage.token.set(creds.token);
        BrowserStorage.userId.set(`${user.id}`);
      }

      // Init all the session stuff
      try {
        yield put(this.actions.getUserPermissions({ userId: user.id.toString() }));
      } catch (e) {
        yield put(this.actions.clearSession({}));
        // update the boot saga
        yield put(
          this.actions.updateBootInfo({
            isAuthed: false,
            isLoading: false,
          })
        );
        continue;
      }

      // Amplitude
      amplitude.setUserId(user.id.toString());

      // identify in hubspot
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push(['identify', { email: user.email, id: user.id }]);
      _hsq.push(['trackEvent', { id: HubSpotEvent.login }]);

      // Grab the bootInfo and do SSO login for forum/market if needed
      const bootParams: BootParams = yield select(this.selectors.getBootParams);
      if (bootParams && bootParams.ssoRedirectInfo) {
        const url = new URL(`/oauth/${bootParams.ssoRedirectInfo.source}`, Urls.FrameworkHome);
        url.search = bootParams.ssoRedirectInfo.query;
        window.location.assign(url.href);
        continue;
      }

      // Alias with our API so hubspot can track sync the user in framework site to the dashboard
      if (bootParams) {
        const { source, hsid, utmParams } = bootParams;
        // @ts-ignore
        const token = yield select(this.getAuthToken);
        yield call(
          this.sagaCallApi,
          'SYNC_ALIASES',
          {
            endpoint: `/users/${user.id}/aliases`,
            method: 'POST',
            body: { source, hsid, ...utmParams },
            quiet: true,
          },
          token
        );
      }

      // Update the Boot Saga
      yield put(
        this.actions.updateBootInfo({
          isAuthed: true,
          isLoading: false,
        })
      );

      for (let attempt = 0; user.trial_info.trial_status === 'eligibility_pending' && attempt < 5; attempt++) {
        // poll with exponential backoff until trial eligibility is determined
        yield delay(1000 * Math.pow(2, attempt));
        user = yield call([this, this.loadCurrentUser], this.actions.loadCurrentUser({}));
      }

      // wait for logout
      const logoutAction: LogoutAction = yield take(this.actions.logout.type);
      // @ts-ignore
      const token = yield select(this.getAuthToken);
      // Do log out things
      yield call(
        this.sagaCallApi,
        logoutAction.type,
        {
          method: 'POST',
          endpoint: '/logout',
          body: {},
        },
        token
      );
      BrowserStorage.logout();
      yield put(this.actions.clearSession({}));

      // Amplitude
      amplitude.reset();

      if (USE_SITE_AUTH) {
        window.location.href = Urls.Logout;
      } else {
        // Update the Boot Saga
        yield put(
          this.actions.updateBootInfo({
            isAuthed: false,
            isLoading: false,
            pathOverride: undefined,
          })
        );
      }
    }
  }

  *impersonateUser(action: ImpersonateUser) {
    // @ts-ignore
    const token = yield select(this.getAuthToken);
    const resp: ApiResponse<{ token: string; userId: string }> = yield call(
      this.sagaCallApi,
      action.type,
      {
        endpoint: `/utils/impersonate?user_id=${action.payload.userId}`,
        method: 'GET',
      },
      token
    );

    if (isApiErrorResponse(resp)) {
      return;
    }

    // Store overwrite data, so we can reference it from here on out
    BrowserStorage.overwriteToken.set(resp.data.token);
    BrowserStorage.overwriteUserId.set(resp.data.userId);
    // This token is temporary and expires in an hour, so let's keep the option to show when it will expire
    BrowserStorage.overwriteTime.set(Date.now().toString());
    BrowserStorage.recentApps.delete();

    // This very intentionally, very lazily uses a full page reload to reload all the user info
    // and remove any info that might be persisted from the impersonated user.
    setTimeout(() => {
      // Inside of set timeout to put this at the end of the stack, giving
      // the browser storage time to update its storage
      window.location.pathname = action.payload.redirect || '/';
    });
  }

  *getUserPermissions(action: GetUserPermissions) {
    const resp: ApiResponse<any> = yield call(
      this.sagaCallApi,
      action.type,
      {
        endpoint: `/users/${action.payload.userId}/permissions`,
        method: 'GET',
      },
      yield select(this.getAuthToken)
    );
    if (isApiErrorResponse(resp)) {
      // handles error codes from api
      return;
    }
    yield put(this.actions.receiveUserPermissions(resp.data));
    return resp.data;
  }

  /**
   * This is a utility function to load all the user infos.
   */
  *reloadAllUserInfos(action: ReloadAllUserInfos) {
    // @ts-ignore
    const user = yield call([this, this.loadCurrentUser], this.actions.loadCurrentUser({}));
    // @ts-ignore
    const otherCalls = yield all([
      call([this, this.getUserPermissions], this.actions.getUserPermissions({ userId: action.payload.userId || user.id })),
    ]);
    return [user, ...otherCalls];
  }

  /**
   * This is a utility function to load all the user infos and redirect to another location.
   */
  *reloadAllUserInfosAndRedirect(action: ReloadAllUserInfosAndRedirect) {
    yield call([this, this.reloadAllUserInfos], this.actions.reloadAllUserInfos({ userId: action.payload.userId }));
    action.payload.navigate(action.payload.redirectTo);
  }

  *updateCurrentUser(action: UpdateCurrentUser) {
    yield call(
      this.sagaCallApi,
      action.type,
      {
        endpoint: `/users/${action.payload.user.id}`,
        method: 'PATCH',
        body: action.payload.body,
      },
      yield select(this.getAuthToken)
    );
    yield put(this.actions.loadCurrentUser({}));
  }

  *startPasswordReset(action: StartPasswordReset) {
    yield call(this.sagaCallApi, action.type, {
      body: { ...action.payload, source: 'dash' },
      endpoint: '/auth/password-reset',
      method: 'POST',
      skipAuth: true,
      quiet: true,
    });
  }

  /**
   * Update a forgotten password
   */
  *finishPasswordRequest(action: FinishPasswordReset) {
    // @ts-ignore
    const resp = yield call(this.sagaCallApi, action.type, {
      body: action.payload,
      endpoint: '/auth/password-update',
      method: 'PATCH',
      skipAuth: true,
    });
    if (!isApiErrorResponse(resp)) {
      action.payload.navigate('/login');
    }
  }

  *loginOrSignup(action: LoginAction | SignupAction) {
    const result: ApiResponse<{
      token: string;
      user: User;
    }> = yield call(this.sagaCallApi, action.type, {
      body: { ...action.payload, source: 'dash' },
      endpoint: action.type.includes('LOGIN') ? '/login' : '/signup',
      method: 'POST',
      skipAuth: true,
    });
    if (isApiErrorResponse(result)) {
      return;
    }
    const { user, token } = result.data;
    yield put(
      this.actions.receiveCredentials({
        userId: user.id.toString(),
        token,
      })
    );
  }

  *handleOAuth2Authentication(action: ReceiveOAuth2Auth) {
    const { err, result, pathname, search } = action.payload;
    if (result && result.accessToken) {
      const { accessToken, bootParams } = result;
      // boot with previous state
      yield call([BrowserStorage, BrowserStorage.token.set], accessToken);
      yield call([BrowserStorage, BrowserStorage.userId.set], 'self');
      yield put(this.actions.bootStart({ bootParams }));
      yield put(
        this.actions.receiveCredentials({
          token: accessToken,
          userId: 'self',
        })
      );
    } else if (err) {
      // boot with no state
      yield put(this.actions.bootStart({ pathname, search }));

      // Put the error in api requests saga
      yield put(
        apiRequests.actions.storeApiRequestMetadata({
          type: 'ERROR',
          originalAction: action.type,
          error: {
            type: err.error,
            message: err.error_description || '',
          },
        })
      );
    }
  }

  *loadCurrentUser(action: LoadCurrentUser) {
    const authStore: AuthStore = yield select(this.selectors.getAuthState);
    if (!authStore.credentials) {
      yield put(this.actions.logout({}));
      return;
    }
    const { userId } = authStore.credentials;
    const result: ApiResponse<User> = yield call(
      this.sagaCallApi,
      action.type,
      {
        endpoint: `/users/${userId}?fields=oauth_identities`,
        skipCamelize: true,
      },
      yield select(this.getAuthToken)
    );
    if (isApiErrorResponse(result)) {
      return;
    }
    yield put(this.actions.putCurrentUser(result.data));
    return result.data;
  }

  selectors = {
    getAuthState: this.mainSelect,
    getCredentials: createSelector(this.mainSelect, (auth) => auth.credentials),
    getUser: createSelector(this.mainSelect, (auth) => auth.user),
    getIsImpersonating: createSelector(this.mainSelect, (auth) => auth.isImpersonating),
    getUserGeneralPermissions: createSelector(this.mainSelect, (auth) => (auth.userPermissions ? auth.userPermissions.general : undefined)),
    getBootParams: createSelector(this.mainSelect, (auth) => auth.bootParams),
    getBootInfo: createSelector(this.mainSelect, (auth) => auth.bootInfo),
  };

  reducer(state: AuthStore = this.initialState, action: AllActions): AuthStore {
    switch (action.type) {
      case this.actions.clearSession.type:
        return { ...state, user: undefined, credentials: undefined };
      case this.actions.receiveCredentials.type:
        return { ...state, credentials: action.payload };
      case this.actions.putCurrentUser.type:
        return { ...state, user: action.payload };
      case this.actions.loadCurrentUser.type:
        const { isImpersonating } = action.payload;
        return { ...state, isImpersonating };
      case this.actions.receiveUserPermissions.type:
        return { ...state, userPermissions: action.payload };
      case this.actions.receiveBootParams.type: {
        const bootParams = action.payload;
        return { ...state, bootParams };
      }
      case this.actions.updateBootInfo.type:
        return { ...state, bootInfo: { ...state.bootInfo, ...action.payload } };
      default:
        return state;
    }
  }
}

export const auth = new AuthDuck(
  {
    user: undefined,
    bootInfo: { isAuthed: false, isLoading: true },
  },
  'auth'
);
