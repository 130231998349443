import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'src/components';
import { IonIcon } from 'src/components/icon';
import './HeaderNotification.scss';

export interface HeaderNotificationProps {
  showCloseButton: boolean;
  icon: string;
  text: ReactNode;
  textColor: string;
  color: string;
  buttonText?: string;
  /** Optional text color for the action button. Defaults to the banners background color if no value is specified.  */
  buttonTextColor?: string;
  buttonAction?: () => void;
  closeButtonAction?: () => void;
  skipTimedShow?: boolean;
  showHeaderNotification: () => void;
  hideHeaderNotification: () => void;
}

export const HeaderNotification = (props: HeaderNotificationProps): JSX.Element => {
  const [notificationDisplay, setNotificationDisplay] = useState('block');
  const [notificationHeight, setNotificationHeight] = useState('0');

  useEffect(() => {
    let innerTimer: NodeJS.Timeout;
    let timer: NodeJS.Timeout;

    if (props.skipTimedShow) {
      props.showHeaderNotification();
      setNotificationHeight('48px');
    } else {
      timer = setTimeout(() => {
        props.showHeaderNotification();
        setNotificationHeight('48px');
        innerTimer = setTimeout(() => {
          setNotificationHeight('auto');
        }, 500);
      }, 1500);
    }

    return () => {
      timer && clearTimeout(timer);
      innerTimer && clearTimeout(innerTimer);
    };
    // Need this to run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const close = (animateClose: boolean = true) => {
    if (animateClose) {
      // need to set this back to a number value so it will animate, then set it to zero on the next tick
      setNotificationDisplay('');
      setNotificationHeight('48px');

      setTimeout(() => {
        props.hideHeaderNotification();
        setNotificationHeight('0');
      }, 1);
      return;
    }

    props.hideHeaderNotification();
    setNotificationDisplay('none');
    setNotificationHeight('0');
  };

  const handleButtonClick = () => {
    close(false);
    props.buttonAction && props.buttonAction();
  };

  const handleCloseButtonClick = () => {
    close();
    props.closeButtonAction && props.closeButtonAction();
  };

  const notificationStyle = {
    height: notificationHeight,
    display: notificationDisplay,
    backgroundColor: props.color,
  };

  const { buttonText, color, icon, showCloseButton, text, textColor, buttonTextColor = color } = props;

  return (
    <div className="header-notification" style={notificationStyle}>
      <div className="notification-container">
        <div className="notification-text">
          <div className="notification-info" style={{ color: textColor, display: 'flex', alignItems: 'center' }}>
            <span>
              <StyledIonIcon icon={icon} className="styled-icon" /> {text}
            </span>
          </div>
        </div>
        <div className="notification-action">
          {buttonText && (
            <Button size="small" kind="tertiary" onClick={handleButtonClick} style={{ color: buttonTextColor }}>
              {props.buttonText}
            </Button>
          )}
          {showCloseButton !== false && (
            <img alt="Close" className="close-btn" onClick={handleCloseButtonClick} src="/svg/close-icon.svg" />
          )}
        </div>
      </div>
    </div>
  );
};

const StyledIonIcon = styled(IonIcon)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;

  ion-icon {
    color: inherit;
  }
`;
export default HeaderNotification;
