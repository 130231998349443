import * as BaseDuck from 'src/redux/reducks/modules/BaseDuck';

/**
 * A function the makes the ActionCalls for a Duck
 * @param type The type of the action
 * @param sagaTakeFunc An optional redux saga take effect object to listen for events with it will be passed the action type as the first arg.
 */
const actionMaker = <A extends BaseDuck.Action<any, any>>(type: A['type']): BaseDuck.ActionCall<Pick<A, 'type' | 'payload'>> => {
  return Object.assign((payload: A['payload']) => ({ type, payload }), { type });
};

const utils = {
  actionMaker,
};

export default utils;
