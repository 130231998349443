import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'sonner';
import styled from 'styled-components';
import { Column } from 'src/components';
import configureStore from 'src/redux/store/configureStore';
import { AppflowTheme } from 'src/styled';
import { BootstrapApp } from './BootstrapApp';
import { WebhookProvider } from './WebhookProvider';

const DashContainer = styled(Column)`
  height: 100vh;
`;
DashContainer.displayName = 'DashContainer';

export const DashRoot: React.FC = () => {
  const store = configureStore();

  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <WebhookProvider>
          <AppflowTheme>
            <Router>
              <DashContainer flex={1}>
                <BootstrapApp />
              </DashContainer>
              <Toaster />
            </Router>
          </AppflowTheme>
          <ReactQueryDevtools position="bottom-right" />
          <div id="portal-modal" style={{ position: 'relative', zIndex: 1005 }} />
        </WebhookProvider>
      </Provider>
    </QueryClientProvider>
  );
};
