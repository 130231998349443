import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface FormContentProps {
  uncenter?: boolean;
  forBilling?: boolean;
}

const FormContent: React.FC<PropsWithChildren<FormContentProps>> = ({ uncenter, forBilling, children }) => {
  return (
    <Wrapper forBilling={forBilling}>
      <div className={uncenter === true ? '' : 'center-content'}>{children}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ forBilling?: boolean }>`
  padding: ${(props) => (props.forBilling ? '4px 0' : '38px 0')};
  flex: 1;
  display: flex;
  flex-direction: column;

  .billing-details {
    padding: 4px 0;
  }

  .center-content {
    margin: auto 0;
  }

  form {
    margin: 0;
  }

  legend {
    border: 0;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #72829e;
    letter-spacing: 0.08em;
    margin-bottom: 16px;

    &:not(:nth-of-type(1)) {
      margin-top: 70px;
    }
  }

  .text-input,
  .submit-button {
    margin-bottom: 18px;
  }

  .submit-button {
    display: block;
    width: 100%;
  }

  .errorlist {
    margin-bottom: 28px;
    line-height: 20px;
  }

  .messages {
    p {
      text-align: center;
    }
  }

  .checkbox-label {
    color: #727a87;
    font-size: 11px;
    margin-left: 8px;
  }

  @media (max-width: 600px) {
    legend {
      font-size: 11px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export default FormContent;
