import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownDivider, DropdownHeader, DropdownLink, helpCircleOutline } from 'src/components';
import { DEVOPS_BRAND_NAME } from 'src/declarations/constants';
import { Urls } from 'src/declarations/urls';
import SideMenuIcon from '../side-menu-icon/SideMenuIcon';

interface HelpDropdownProps {
  onDropDownOpen: (value: boolean) => void;
}

export const HelpDropdown: React.FC<HelpDropdownProps> = ({ onDropDownOpen }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    onDropDownOpen(isDropdownOpen);
  }, [onDropDownOpen, isDropdownOpen]);

  return (
    <HelpDropdownContainer>
      <Dropdown
        isOpen={isDropdownOpen}
        onToggle={toggleDropdown}
        toggleElement={<SideMenuIcon icon={helpCircleOutline} className={isDropdownOpen ? 'hover' : ''} highlightBackground="dark" />}
      >
        <DropdownHeader>Framework Docs</DropdownHeader>
        <DropdownLink to={Urls.FrameworkDocs} target="_blank">
          Framework
        </DropdownLink>
        <DropdownLink to={Urls.NativeDocs} target="_blank">
          Native
        </DropdownLink>
        {/*
          <DropdownLink to={Urls.DeployDocs} target="_blank">
            Deploy
          </DropdownLink>
          <DropdownLink to={Urls.PackageDocs} target="_blank">
            Package
          </DropdownLink>
          */}
        <DropdownDivider />
        <DropdownHeader>{DEVOPS_BRAND_NAME} Help</DropdownHeader>
        <DropdownLink to={Urls.DocsHome} target="_blank">
          {DEVOPS_BRAND_NAME} Docs
        </DropdownLink>
        <DropdownLink to={Urls.ProForum} target="_blank">
          {DEVOPS_BRAND_NAME} Forum
        </DropdownLink>
        <DropdownLink to={Urls.Zendesk} target="_blank">
          {DEVOPS_BRAND_NAME} Support Portal
        </DropdownLink>
        <DropdownDivider />
        <DropdownHeader>Legal</DropdownHeader>
        <DropdownLink to={Urls.TermsOfService} target="_blank">
          Terms of use
        </DropdownLink>
        <DropdownLink to={Urls.Privacy} target="_blank">
          Privacy policy
        </DropdownLink>
      </Dropdown>
    </HelpDropdownContainer>
  );
};

export default HelpDropdown;

const HelpDropdownContainer = styled.div`
  .dash-dropdown.open {
    top: unset;
    bottom: -6px;
    left: 36px;
    width: 230px;

    .dash-dropdown-caret {
      top: unset;
      bottom: 19px;
      left: -9px;
      transform: rotate(-90deg);
    }
  }
`;
