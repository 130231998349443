import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styled';
import { DropdownHeader } from './DropdownHeader';

interface DropdownProps {
  toggleElement: JSX.Element;
  isOpen: boolean;
  onToggle: (event?: unknown) => void;
  pullRight?: boolean;
  noCaret?: boolean;
  className?: string;
  color?: 'light' | 'dark';
  animated?: boolean;
  children?: React.ReactNode;
}

export class Dropdown extends React.PureComponent<DropdownProps, {}> {
  constructor(props: DropdownProps) {
    super(props);
    this.handleExternalDropdownOpen = this.handleExternalDropdownOpen.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.hideDropdown);
    window.addEventListener('dropdown_opened', this.handleExternalDropdownOpen);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.hideDropdown);
    window.removeEventListener('dropdown_opened', this.handleExternalDropdownOpen);
  }

  handleExternalDropdownOpen(e: CustomEvent) {
    if (e.detail !== this && this.props.isOpen) {
      this.props.onToggle();
    }
  }

  hideDropdown() {
    if (!this.props.isOpen) return;
    this.props.onToggle();
  }

  toggleDropdown(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.props.isOpen) {
      const event = new CustomEvent('dropdown_opened', { detail: this });
      window.dispatchEvent(event);
    }
    this.props.onToggle();
  }

  render() {
    const { className, isOpen, toggleElement, color } = this.props;

    return (
      <DropdownContainer className={className} pullRight={this.props.pullRight} animated={this.props.animated}>
        <div data-testid="dash-dropdown-toggle" className="toggle" onClick={this.toggleDropdown}>
          {toggleElement}
        </div>
        {isOpen && (
          <ul
            data-testid="dash-dropdown"
            className={`dash-dropdown ${isOpen ? ' open ' : ''} theme-${color || 'light'}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ zIndex: 2 }}
          >
            {this.props.children}
            {!this.props.noCaret && <img src={`/img/menu-carrot-${color || 'light'}.png`} className="dash-dropdown-caret" />}
          </ul>
        )}
      </DropdownContainer>
    );
  }
}

export const DropdownContainer = styled.div<{ pullRight?: boolean; animated?: boolean }>`
  position: relative;

  .toggle {
    cursor: pointer;
  }

  ul {
    overflow: visible;
    position: absolute;
    top: -15000px;
    right: ${(props: { pullRight?: boolean }) => (props.pullRight ? '0px' : '')};
    ${(props) => (typeof props.animated !== 'boolean' || props.animated ? 'transform: translateY(-10px)' : '')};
    box-shadow: ${(props) => props.theme.shadows[2]};
    border: 1px solid ${(props) => props.theme.colors.indigo[30]};
    opacity: 0;
    border-radius: 6px;
    transition: transform 0.15s cubic-bezier(0.36, 0.66, 0.04, 1), opacity 0.15s, top 0.25s step-end;
    list-style: none;
    margin: 0;
    padding: 0;

    &.theme-light {
      background-color: ${(props) => props.theme.colors.white};
      ${DropdownHeader} {
        color: ${(props) => props.theme.colors.carbon[60]};
        font-size: 12px;
        &:hover {
          color: ${(props) => props.theme.colors.carbon[60]};
        }
      }
      li {
        color: ${(props) => props.theme.colors.carbon[80]};
        font-weight: 400;
        a {
          color: ${(props) => props.theme.colors.carbon[80]};
          &:hover {
            color: ${(props) => props.theme.colors.blue[100]};
          }
        }
        &:hover {
          color: ${(props) => props.theme.colors.blue[100]};
        }
      }
    }

    &.theme-dark {
      background-color: ${(props) => props.theme.colors.gray[90]};
      ${DropdownHeader} {
        color: ${(props) => props.theme.colors.gray[20]};
        font-size: 12px;
        &:hover {
          color: ${(props) => props.theme.colors.gray[20]};
        }
      }
      li {
        color: ${(props) => props.theme.colors.gray[20]};
        font-weight: 600;
        a {
          color: ${(props) => props.theme.colors.gray[20]};
          &:hover {
            color: ${(props) => props.theme.colors.gray[50]};
          }
        }
        &:hover {
          color: ${(props) => props.theme.colors.gray[50]};
        }
      }
    }

    &.open {
      opacity: 1;
      top: initial;
      ${(props) => (typeof props.animated !== 'boolean' || props.animated ? 'transform: translateY(0)' : '')};
      transition: transform 0.15s cubic-bezier(0.36, 0.66, 0.04, 1), opacity 0.15s;
    }

    .dash-dropdown-caret {
      width: 14px;
      height: 5px;
      position: absolute;
      top: -5px;
      left: 8px;
    }
  }
`;
DropdownContainer.defaultProps = { theme };

export default Dropdown;
