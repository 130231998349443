type UNIXTimestampMS = number;
type ISO8601Date = string;
type FilterString = 'EN' | string;
type LocaleString = 'admin;manager' | string;
type Display = 'right' | 'left' | 'popup' | 'in-app';
type DisplayPosition =
  | 'top'
  | 'top-left'
  | 'top-right'
  | 'right'
  | 'right-bottom'
  | 'right-top'
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right'
  | 'left'
  | 'left-bottom'
  | 'left-top';
type ButtonPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
type Icon = 'bell_full' | 'bell_lines' | 'flame' | 'flame_alt' | 'alert_bubble' | 'alert_circle' | 'bullhorn' | 'thumbtack';
type NotificationPrompt = 'popup' | 'sidebar';
/**
 * See {@link https://www.getbeamer.com/docs/#parameters} for details.
 */
// eslint-disable-next-line sort-keys
export interface BeamerConfig {
  product_id?: string; // Required
  user_firstname?: string;
  user_lastname?: string;
  user_email?: string;
  user_id?: string;
  selector?: string;
  display?: Display;
  display_position?: DisplayPosition;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  embed?: boolean; // Pro Only
  button?: boolean;
  button_position?: ButtonPosition;
  icon?: Icon;
  bounce?: boolean;
  notification_prompt?: NotificationPrompt;
  notification_prompt_delay?: number;
  language?: LocaleString;
  filter?: FilterString;
  force_filter?: FilterString;
  filter_by_url?: boolean;
  mobile?: boolean;
  lazy?: boolean;
  alert?: boolean;
  force_button?: boolean;
  counter?: boolean;
  first_visit_unread?: number;
  standalone?: boolean;
  post_request?: boolean; // Enterprise Only
  delay?: number;
  callback?: (new_post_count: number) => void;
  onclick?: (post_url: string, openInNewWindow: boolean) => boolean | void;
  onopen?: () => boolean | void;
  onclose?: () => boolean | void;
  onerror?: () => void;
  multi_user?: boolean;
  nps_delay?: number;
  user_created_at?: ISO8601Date | UNIXTimestampMS;

  [custom_user_attribute: string]: unknown;
}

interface BeamerController {
  init: () => void;
  show: () => void;
  hide: () => void;
  update: (config: BeamerConfig) => void;
  destroy: () => void;
}

export class BeamerConfig {
  constructor(config: BeamerConfig) {
    return {
      display: 'right',
      display_position: undefined,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      embed: false,
      button: true,
      button_position: 'bottom-right',
      icon: 'bell_full',
      bounce: true,
      notification_prompt: undefined,
      notification_prompt_delay: 0,
      language: 'EN',
      filter: undefined,
      force_filter: undefined,
      filter_by_url: false,
      mobile: true,
      lazy: false,
      alert: true,
      force_button: false,
      counter: true,
      first_visit_unread: 1,
      standalone: false,
      post_request: false,
      delay: 0,
      callback: undefined,
      onclick: undefined,
      onopen: undefined,
      onclose: undefined,
      onerror: undefined,
      multi_user: false,
      nps_delay: 0,
      user_created_at: undefined,
      ...config,
    };
  }
}

// Beamer Object
declare var Beamer: BeamerController;

type UserConfig = Pick<BeamerConfig, 'user_id' | 'user_firstname' | 'user_lastname' | 'user_email'>;

export const BEAMER_PRODUCT_ID = 'mUvQtqly31065';

export const initializeBeamer = (config: BeamerConfig & Required<UserConfig>) => {
  if (typeof Beamer === 'undefined') {
    return;
  }
  Beamer.update(config);
};

let beamer_started = false;
export const startBeamer = () => {
  if (beamer_started) {
    return;
  }

  beamer_started = true;
  if (typeof Beamer !== 'undefined') {
    Beamer.init();
  }
};
