import { useParams } from 'react-router-dom';
import { useBootstrapContext } from 'src/features/_dashboard/pages/_dashboard/BootstrapContext';
import { useGetDataForCurrentOrgSlugQuery } from 'src/graphql/generated-hooks';

export function useCurrentOrgSlug() {
  const { slug, id: appId } = useParams<{ slug: string; id: string }>();
  const { data: orgSlug } = useGetDataForCurrentOrgSlugQuery(
    { appId: appId! },
    { enabled: !!appId, select: (data) => data.app?.organization?.slug }
  );
  const { defaultOrg } = useBootstrapContext();

  if (slug) {
    return slug;
  }

  if (appId) {
    return orgSlug;
  }

  return defaultOrg?.slug;
}
