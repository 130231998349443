import React from 'react';
import AbstractButton from './AbstractButton';
import { AbstractButtonProps } from './ButtonTypes';

export type ButtonProps = AbstractButtonProps<'button'>;

const Button: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  return <AbstractButton {...props} />;
};

Button.displayName = 'Button';

export default Button;
