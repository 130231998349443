import { ComponentType, ElementType, createElement } from 'react';

type As<T extends ElementType> = { as?: T };
type OmitAs<T> = Omit<T, keyof As<ElementType>>;

type Override<BaseType, OverridingType> = Omit<BaseType, keyof OverridingType> & OverridingType;

/**
 * Extract the props from some component. It could be the type of some complex component, e.g. PropsOf<typeof Flex>, or
 * it could be a string name of a DOM element, e.g. PropsOf<'div'>.
 */
type PropsOf<T extends ElementType> = T extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[T]
  : T extends ComponentType<infer P>
  ? P
  : never;

/**
 * The props of a generic component, which contain:
 *
 * 1) An "as" prop whose value is a string name of a DOM element, or a complex component.
 * 2) All of the props of the component passed to "as"
 * 3) Any additional props required for the component.
 *
 * For example, GenericProps<typeof Flex, { foo: string }> is the same as PropsOf<typeof Flex> & { foo: string, as: Flex }
 */
export type GenericProps<T extends ElementType, AddedProps> = Override<OmitAs<PropsOf<T>>, OmitAs<AddedProps>> & As<T>;

/**
 * A generic component whose rendered element is specified in "as". Basically the same as "as" from styled-components.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- couldn't figure out how to get rid of this 'any'
export const GenericComponent = <T extends ElementType, U = any>({ as = 'div' as T, ...props }: GenericProps<T, U>) =>
  createElement(as, props);
