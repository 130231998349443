import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Avatar, Dropdown, DropdownDivider, DropdownItem, DropdownLink, ItemInfo, personCircleOutline } from 'src/components';
import { Urls } from 'src/declarations/urls';
import { User } from 'src/interfaces';
import { useBootstrapContext } from '../../pages/_dashboard/BootstrapContext';
import SideMenuIcon from '../side-menu-icon/SideMenuIcon';

interface AccountDropdownProps {
  user: User;
  openFeedbackModal: () => void;
  onDropDownOpen: (value: boolean) => void;
}

export const AccountDropdown: React.FC<AccountDropdownProps> = ({ user, openFeedbackModal, onDropDownOpen }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const { canSwitchOrgs } = useBootstrapContext();

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      onDropDownOpen(isDropdownOpen);
    }
  }, [isDropdownOpen, onDropDownOpen]);

  return (
    <DropdownContainer>
      <Dropdown isOpen={isDropdownOpen} onToggle={toggleDropdown} toggleElement={<SideMenuIcon icon={personCircleOutline} />}>
        <DropdownItem className="user-row">
          <ItemInfo
            size="small"
            title={user.name}
            subTitle={user.email}
            icon={<Avatar userName={user.name} userPicture={user.picture} size={32} />}
          />
        </DropdownItem>
        <DropdownDivider />
        <DropdownLink to={'/settings/account'}>Personal Settings</DropdownLink>
        {canSwitchOrgs && <DropdownLink to={'/orgs'}>Organizations</DropdownLink>}
        <DropdownItem
          onClick={() => {
            toggleDropdown();
            openFeedbackModal();
          }}
        >
          Give feedback
        </DropdownItem>
        <DropdownLink to={Urls.Support} target="_blank">
          Help & Support
        </DropdownLink>
        <DropdownDivider />
        <DropdownLink to={'/logout'}>Sign Out</DropdownLink>
      </Dropdown>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  .dash-dropdown.open {
    top: unset;
    bottom: -10px;
    left: 40px;
    width: 230px;

    .dash-dropdown-caret {
      top: unset;
      bottom: 19px;
      left: -9px;
      transform: rotate(-90deg);
    }
  }
`;

export default AccountDropdown;
