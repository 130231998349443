import styled from 'styled-components';
import { IonIcon, IonIconProps } from 'src/components';

const SideMenuIcon = styled(IonIcon)<IonIconProps>`
  cursor: pointer;
  ion-icon {
    color: ${(props) => props.theme.colors.indigo[20]};
  }
`;
SideMenuIcon.displayName = 'SideMenuIcon';

SideMenuIcon.defaultProps = {
  size: 20,
};

export default SideMenuIcon;
