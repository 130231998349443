import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import { Tooltip } from 'src/components';
import {
  AccountSwitcherDropdown,
  MenuList,
  SearchContainer,
  SearchLogo,
  SidebarBottomMenu,
  SidebarLogo,
} from 'src/features/_dashboard/components';
import { StateStore } from 'src/interfaces';
import { DuckSelectors } from 'src/redux/reducks';
import { startBeamer } from 'src/utils';
import { useBootstrapContext } from '../_dashboard/BootstrapContext';

interface SidebarProps extends ConnectedProps<typeof connector> {}

export const Sidebar = ({ ...props }: SidebarProps): JSX.Element => {
  const { canSwitchOrgs, defaultRoute, isLoading } = useBootstrapContext();
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    const disableBeamer = import.meta.env.VITE_DISABLE_BEAMER;
    if (disableBeamer != 'true') {
      startBeamer();
    }
  }, []);

  if (isLoading) {
    return <></>;
  }

  const toggleSearch = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (showSearch) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
    event.stopPropagation();
  };

  return (
    <SidebarContainer data-testid="sidebar">
      <SearchArea>
        <SearchContainer showSearch={showSearch} setShowSearch={setShowSearch} />
      </SearchArea>
      <SidebarTop>
        <MenuList>
          <SidebarLogo defaultRoute={defaultRoute} />
          {canSwitchOrgs ? <AccountSwitcherDropdown /> : null}
          <Tooltip
            text={
              showSearch ? (
                <div>
                  Exit search &nbsp;<Emphasize>ESC</Emphasize>
                </div>
              ) : (
                <div>
                  Search apps &nbsp;<Emphasize>/</Emphasize>
                </div>
              )
            }
            spacing={6}
            onAction={toggleSearch}
            position="right"
          >
            <SearchLogo isOpen={showSearch} />
          </Tooltip>
        </MenuList>
      </SidebarTop>
      <SidebarBottom>
        <SidebarBottomMenu />
      </SidebarBottom>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  position: relative;
  width: 100%;
  background-color: #1f2631;
`;

const SearchArea = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  z-index: -1;
  pointer-events: none;
`;

const SidebarTop = styled.div`
  flex-grow: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
`;

const SidebarBottom = styled.div`
  flex-shrink: 1;
  display: flex;
  justify-content: flex-end;
  align-self: center;
`;

const Emphasize = styled.span`
  background: #525d6d;
  padding: 2px 4px 2px 4px;
  border-radius: 2px;
`;

const mapStateToProps = (state: StateStore) => {
  const userGeneralPermissions = DuckSelectors.getUserGeneralPermissions(state)!;

  return {
    userGeneralPermissions,
  };
};

const connector = connect(mapStateToProps, {});
export default connector(Sidebar);
