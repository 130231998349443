// @ts-ignore
import cookie from 'set-cookie';

export const deleteCookie = (name: string) => {
  cookie(name, '', {
    path: '/',
    domain: getCookieDomain(),
    expires: new Date(),
  });
};

function getCookieDomain() {
  const labels = window.location.hostname.split('.');
  return labels.length === 1 ? undefined : '.' + labels.slice(-2).join('.');
}
