import React from 'react';
import { Fragment } from 'react';

export const grammarJoin = (tokens: JSX.Element[], delimiter = ', ', finalDelimiter = ' and '): React.ReactNode[] => {
  return tokens.reduce((joinedValue, token, index) => {
    if (index > 0) {
      joinedValue.push(index + 1 < tokens.length ? delimiter : finalDelimiter);
    }

    joinedValue.push(token);

    return joinedValue;
  }, [] as React.ReactNode[]);
};

export const joinChildren = (children: JSX.Element[], seperator: JSX.Element) => {
  return children.map((e, i) => {
    return (
      <Fragment key={i}>
        {i < children.length - 1 ? (
          <>
            {e}
            {seperator}
          </>
        ) : (
          e
        )}
      </Fragment>
    );
  });
};
