import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { theme } from 'src/styled';

export interface LoadingSpinnerProps extends SpaceProps {
  className?: string;
  size?: 'small' | 'medium' | 'large';
  color?: string;
}

const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = ({
  size = 'large',
  className,
  color = theme.colors.blue[100],
  ...rest
}) => {
  return (
    <LoadingSpinnerContainer data-testid="loading-spinner" className={`spinner ${size} ${className}`} color={color} {...rest}>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </LoadingSpinnerContainer>
  );
};

export default LoadingSpinner;

const FadeInSimpleAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const SKBounceDelayAnimation = keyframes`
  0%,
  70%,
  100% {
    transform: scale(.7);
    opacity: .5;
  }

  35% {
    transform: scale(1);
    opacity: 1;
  }
`;

const skBounceDelyaAnimationRule = css`
  ${SKBounceDelayAnimation} .64s infinite ease-in-out;
`;

export const LoadingSpinnerContainer = styled.div<LoadingSpinnerProps>`
  z-index: 6;
  animation: 5s ${FadeInSimpleAnimation};
  animation-delay: 1s;
  display: flex;
  align-items: center;
  ${space}

  > div {
    background-color: ${(props) => props.color};
    border-radius: 100%;
    display: inline-block;
    animation: ${skBounceDelyaAnimationRule};
  }

  &.large > div {
    width: 8px;
    height: 8px;
    margin: 0 2px;
  }

  &.medium > div {
    width: 6px;
    height: 6px;
    margin: 0 1px;
  }

  &.small > div {
    width: 4px;
    height: 4px;
    margin: 0 1px;
  }

  .bounce1 {
    animation-delay: -0.14s;
  }

  .bounce2 {
    animation-delay: -0.06s;
  }
`;
