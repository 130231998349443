import * as amplitude from '@amplitude/analytics-browser';
import React, { useState } from 'react';
import { Location } from 'react-router-dom';
import styled from 'styled-components';
import { Button, ModalBody, ModalFooter, ModalHeader, RightButton } from 'src/components';
import { DEVOPS_BRAND_NAME } from 'src/declarations/constants';

interface SendFeedbackModalProps {
  location: Location;
  closeModals: () => void;
}

export const UserFeedbackModal: React.FC<SendFeedbackModalProps> = ({ location, closeModals }) => {
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [mayContact, setMayContact] = useState(true);

  function handleSendFeedback() {
    if (feedbackMessage) {
      amplitude.track(`Feedback`, {
        source: location.pathname,
        message: feedbackMessage,
        contact: mayContact,
      });
      closeModals();
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const target = event.target;
    const name = target.name;

    if (name === 'feedbackMessage') {
      setFeedbackMessage(target.value);
    }

    if (name === 'mayContact' && target instanceof HTMLInputElement && target instanceof HTMLInputElement && target.type === 'checkbox') {
      setMayContact(target.checked);
    }
  }

  function handleModalClose() {
    closeModals();
  }

  return (
    <UserFeedbackModalContainer>
      <ModalHeader title={'Give feedback'} subTitle={`What do you think of Ionic ${DEVOPS_BRAND_NAME}?`} />
      <ModalBody>
        <UserFeedbackModalTextArea name="feedbackMessage" placeholder={'Tell us more'} onChange={handleInputChange} />
        <UserFeedbackModalLabel>
          <input
            style={{ marginRight: '10px', marginTop: '10px' }}
            name="mayContact"
            type="checkbox"
            checked={mayContact}
            onChange={handleInputChange}
          />
          Ionic {DEVOPS_BRAND_NAME} may contact me about my feedback.
        </UserFeedbackModalLabel>
      </ModalBody>
      <ModalFooter>
        <RightButton>
          <Button size="medium" kind="tertiary" onClick={handleModalClose}>
            Cancel
          </Button>
        </RightButton>
        <RightButton>
          <Button size="medium" kind="primary" onClick={handleSendFeedback}>
            Send
          </Button>
        </RightButton>
      </ModalFooter>
    </UserFeedbackModalContainer>
  );
};

const UserFeedbackModalContainer = styled.div``;

const UserFeedbackModalTextArea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 6px 12px;
  border: 1px solid #e1e5ed;
  border-radius: 4px;

  &::-webkit-input-placeholder {
    color: #bdc3cd;
  }
  &:focus {
    outline: none;
    border-color: #488aff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

const UserFeedbackModalLabel = styled.label`
  font-weight: normal;
`;
