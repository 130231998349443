import { UsersStore } from 'src/interfaces/StateStore';
import * as ActionTypes from 'src/redux/actions';

/**
 * Reducer for user API related actions (for session/current user actions see auth reducer).
 */
const userReducer = (state: UsersStore = {}, action: any): UsersStore => {
  switch (action.type) {
    // Handle loading a single user action
    case ActionTypes.LOAD_USER_REQUEST:
      return { ...state, isLoadingUser: true, loadUserError: undefined, user: undefined };
    case ActionTypes.LOAD_USER_SUCCESS:
      return {
        ...state,
        isLoadingUser: false,
        loadUserError: undefined,
        user: action.response.entities.users[action.response.result],
      };
    case ActionTypes.LOAD_USER_FAILURE:
      return { ...state, isLoadingUser: false, loadUserError: action.error };
    default:
      return state;
  }
};

export default userReducer;
