const spriteFolder = '/sprites';
const svgFolder = '/svg';
const imgFolder = '/img';

export interface IconSet {
  [id: string]: IconType;
}

export interface IconType {
  size: number;
  file: string;
  position: number;
  hoverPosition?: number;
  hoverFile?: string;
}

export const MenuOrgPersonal: IconSet = {
  personal: {
    size: 24,
    file: `${spriteFolder}/menu-org-personal-icon.png`,
    position: 1,
  },
  org: {
    size: 24,
    file: `${spriteFolder}/menu-org-personal-icon.png`,
    position: 2,
  },
};

export const Toolbar = {
  help: {
    size: 16,
    file: `${spriteFolder}/toolbar-icons.png`,
    position: 2,
    hoverPosition: 2,
  },
  docs: {
    size: 16,
    file: `${spriteFolder}/toolbar-icons.png`,
    position: 4,
    hoverPosition: 4,
  },
  search: {
    size: 16,
    file: `${spriteFolder}/toolbar-icons.png`,
    position: 6,
    hoverPosition: 6,
  },
  back: {
    size: 16,
    file: `${spriteFolder}/toolbar-icons.png`,
    position: 7,
    hoverPosition: 8,
  },
};

export const ConnectIcons = {
  connect: {
    'flex-shrink': 1,
    size: 32,
    file: `${imgFolder}/git-connect-steps-icons.png`,
    'margin-right': 16,
    position: 1,
    hoverPosition: 1,
  },
  connected: {
    'flex-shrink': 1,
    size: 32,
    file: `${imgFolder}/git-connect-steps-icons.png`,
    'margin-right': 16,
    position: 2,
    hoverPosition: 2,
  },
  branch: {
    'flex-shrink': 1,
    size: 32,
    file: `${imgFolder}/git-connect-steps-icons.png`,
    'margin-right': 16,
    position: 3,
    hoverPosition: 3,
  },
};

export const SmallIcons = {
  externalLink: {
    size: 16,
    file: `${svgFolder}/external-link.svg`,
    hoverFile: `${svgFolder}/external-link-hover.svg`,
    position: 0,
  },
  externalLinkInLine: {
    size: 16,
    file: `${svgFolder}/external-link.svg`,
    position: 0,
  },
};

export const MediumIcons = {
  externalLink: {
    size: 18,
    file: `${svgFolder}/external-link.svg`,
    hoverFile: `${svgFolder}/external-link-hover.svg`,
    position: 0,
  },
  externalLinkInline: {
    size: 18,
    file: `${svgFolder}/external-link.svg`,
    position: 0,
  },
};

export const GitOriginIcons = {
  ionic: {
    size: 16,
    file: `${spriteFolder}/git-config-icons.png`,
    position: 1,
  },
  bitbucket_cloud: {
    size: 16,
    file: `${spriteFolder}/git-config-icons.png`,
    position: 2,
  },
  bitbucket_server: {
    size: 16,
    file: `${spriteFolder}/git-config-icons.png`,
    position: 2,
  },
  github: {
    size: 16,
    file: `${spriteFolder}/git-config-icons.png`,
    position: 3,
  },
  github_enterprise: {
    size: 16,
    file: `${spriteFolder}/git-config-icons.png`,
    position: 3,
  },
  gitlab: {
    size: 16,
    file: `${spriteFolder}/git-config-icons.png`,
    position: 4,
  },
  // todo: idk what this means or where it is -sz
  // the following is needed because there is logic that pulls the icon based on url and not explicitly
  gitlab_enterprise: {
    size: 16,
    file: `${spriteFolder}/git-config-icons.png`,
    position: 4,
  },
  azure_devops: {
    size: 16,
    file: `${spriteFolder}/git-config-icons.png`,
    position: 5,
  },
};

export const StatusIcons = {
  created: {
    size: 16,
    file: `${imgFolder}/created.gif`,
    position: 1,
  },
  pending: {
    size: 16,
    file: `${imgFolder}/pending.gif`,
    position: 1,
  },
  building: {
    size: 16,
    file: `${spriteFolder}/build-status-icons.png`,
    position: 3,
  },
  running: {
    size: 16,
    file: `${imgFolder}/in-progress.gif`,
    position: 1,
  },
  success: {
    size: 16,
    file: `${spriteFolder}/build-status-icons.png`,
    position: 4,
  },
  successFill: {
    size: 16,
    file: `${spriteFolder}/build-status-icons.png`,
    position: 3,
  },
  failed: {
    size: 16,
    file: `${spriteFolder}/build-status-icons.png`,
    position: 2,
  },
  failedFill: {
    size: 16,
    file: `${spriteFolder}/build-status-icons.png`,
    position: 1,
  },
  canceled: {
    size: 16,
    file: `${spriteFolder}/build-status-icons.png`,
    position: 5,
  },
};

export const BreadcrumbOrgIcons = {
  org: {
    size: 14,
    file: `${spriteFolder}/breadcrumb-icons.png`,
    position: 1,
    hoverPosition: 2,
  },
  personal: {
    size: 14,
    file: `${spriteFolder}/breadcrumb-icons.png`,
    position: 3,
    hoverPosition: 4,
  },
};

export const WelcomeIcons = {
  deploy: {
    size: 80,
    file: `${spriteFolder}/welcome-icons.png`,
    position: 2,
  },
  automations: {
    size: 80,
    file: `${spriteFolder}/welcome-icons.png`,
    position: 8,
  },
  build: {
    size: 80,
    file: `${spriteFolder}/welcome-icons.png`,
    position: 7,
  },
  environments: {
    size: 80,
    file: `${spriteFolder}/welcome-icons.png`,
    position: 10,
  },
};

export const DeploymentIcons: IconSet = {
  appleStore: {
    size: 12,
    file: `${spriteFolder}/deploy-target-sprite.png`,
    position: 1,
  },
  testFlight: {
    size: 12,
    file: `${spriteFolder}/deploy-target-sprite.png`,
    position: 2,
  },
  googleStore: {
    size: 12,
    file: `${spriteFolder}/deploy-target-sprite.png`,
    position: 3,
  },
  channel: {
    size: 12,
    file: `${spriteFolder}/deploy-target-sprite.png`,
    position: 4,
  },
  deploymentSubmitted: {
    size: 12,
    file: `${spriteFolder}/deploy-target-sprite.png`,
    position: 5,
  },
  deploymentDone: {
    size: 12,
    file: `${spriteFolder}/deploy-target-sprite.png`,
    position: 6,
  },
  deploymentInProgress: {
    size: 12,
    file: `${spriteFolder}/deploy-target-sprite.png`,
    position: 7,
  },
  deploymentFailed: {
    size: 12,
    file: `${spriteFolder}/deploy-target-sprite.png`,
    position: 8,
  },
  appleStoreGray: {
    size: 12,
    file: `${spriteFolder}/deploy-target-sprite.png`,
    position: 9,
  },
  testFlightGray: {
    size: 12,
    file: `${spriteFolder}/deploy-target-sprite.png`,
    position: 10,
  },
  googleStoreGray: {
    size: 12,
    file: `${spriteFolder}/deploy-target-sprite.png`,
    position: 11,
  },
  channelGray: {
    size: 12,
    file: `${spriteFolder}/deploy-target-sprite.png`,
    position: 12,
  },
};

export const ProductIcons: IconSet = {
  appflow: {
    size: 24,
    file: `${imgFolder}/appflow-icon.svg`,
    position: 1,
  },
  cloudcli: {
    size: 60,
    file: `${imgFolder}/cloud-cli-icon.png`,
    position: 1,
  },
  nativeBuilds: {
    size: 60,
    file: `${imgFolder}/native-builds-header-icon.png`,
    position: 1,
  },
  publishApp: {
    size: 60,
    file: `${imgFolder}/app-store-pub-header-icon.png`,
    position: 1,
  },
};

export const AppflowProductIcons: IconSet = {
  appStorePublish: {
    size: 60,
    file: `${imgFolder}/solutions/app-store-publish-icon.png`,
    position: 1,
  },
  appStorePublishSmall: {
    size: 24,
    file: `${imgFolder}/solutions/app-store-publish-icon.png`,
    position: 1,
  },
  automations: {
    size: 60,
    file: `${imgFolder}/solutions/automations.png`,
    position: 1,
  },
  automationsSmall: {
    size: 24,
    file: `${imgFolder}/solutions/automations.png`,
    position: 1,
  },
  liveUpdates: {
    size: 60,
    file: `${imgFolder}/solutions/live-updates-icon.png`,
    position: 1,
  },
  liveUpdatesSmall: {
    size: 24,
    file: `${imgFolder}/solutions/live-updates-icon.png`,
    position: 1,
  },
  nativeBuilds: {
    size: 60,
    file: `${imgFolder}/solutions/native-builds-icon.png`,
    position: 1,
  },
  nativeBuildsSmall: {
    size: 24,
    file: `${imgFolder}/solutions/native-builds-icon.png`,
    position: 1,
  },
};

export const NativePluginIcons: IconSet = {
  authConnect: {
    size: 60,
    file: `${imgFolder}/auth-connect-icon.png`,
    position: 1,
  },
  authConnectSmall: {
    size: 24,
    file: `${imgFolder}/auth-connect-icon-small.png`,
    position: 1,
  },
  identityVault: {
    size: 60,
    file: `${imgFolder}/iv-test-lg.png`,
    position: 1,
  },
  identityVaultSmall: {
    size: 24,
    file: `${imgFolder}/iv-test-sm.png`,
    position: 1,
  },
  secureStorage: {
    size: 60,
    file: `${imgFolder}/secure-storage-icon.png`,
    position: 1,
  },
  secureStorageSmall: {
    size: 24,
    file: `${imgFolder}/secure-storage-icon-small.png`,
    position: 1,
  },
  ionicPayments: {
    size: 60,
    file: `${imgFolder}/ionic-payments-icon.png`,
    position: 1,
  },
  portals: {
    size: 60,
    file: `${imgFolder}/portals-dash-icon.png`,
    position: 1,
  },
  liveUpdates: {
    size: 60,
    file: `${imgFolder}/live-updates-icon.png`,
    position: 1,
  },
  zebra: {
    size: 60,
    file: `${imgFolder}/zebra-icon.png`,
    position: 1,
  },
  intune: {
    size: 60,
    file: `${imgFolder}/intune-icon.png`,
    position: 1,
  },
};
