import { Dispatch } from 'redux';
import { fork } from 'redux-saga/effects';
import * as BaseDuck from './modules/BaseDuck';
import { apiRequests } from './modules/api';
import { auth } from './modules/auth';
import { genericBuilds } from './modules/generic-builds';

function actionsWithDispatch<T extends BaseDuck.ActionCalls<any>>(actionList: T): T {
  actionList = Object.assign({}, actionList);
  for (const key in actionList) {
    const actionCreator = actionList[key];
    const dispatchAction = (payload: any) => (dispatch: Dispatch) => {
      const action = actionCreator(payload);
      dispatch(action);
      return action;
    };
    const wrappedAction = Object.assign(dispatchAction, { type: actionList[key].type });
    actionList[key] = wrappedAction as any;
  }
  return actionList;
}

export const rawActions = {
  ...apiRequests.actions,
  ...auth.actions,
  ...genericBuilds.actions,
};

/**
 * Note these are only to be used in connected components. Use the
 * rawActions above inside other sagas with put to trigger actions from inside another saga.
 */
export const DuckActions = actionsWithDispatch(rawActions);

export const DuckSelectors = {
  ...apiRequests.selectors,
  ...auth.selectors,
  ...genericBuilds.selectors,
};

export const DuckReducers = {
  auth: auth.reducer.bind(auth),
  api: apiRequests.reducer.bind(apiRequests),
  genericBuilds: genericBuilds.reducer.bind(genericBuilds),
};

export function* mainSaga() {
  yield fork([apiRequests, apiRequests.mainSaga]);
  yield fork([auth, auth.mainSaga]);
  yield fork([genericBuilds, genericBuilds.mainSaga]);
}
