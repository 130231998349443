import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface SidebarLogoProps {
  className?: string;
  defaultRoute: string;
}

export const SidebarLogo = ({ className, defaultRoute }: SidebarLogoProps): JSX.Element => {
  return (
    <Link to={defaultRoute} className={className} data-testid="header-logo-link">
      <img src="/img/appflow-logo.png" />
    </Link>
  );
};

SidebarLogo.displayName = 'SidebarLogo';

export default styled(SidebarLogo)`
  height: 24px;
  width: 24px;
  opacity: 0.9;
  transition: opacity 0.15s ease-in;

  &:hover {
    opacity: 1;
  }

  img {
    height: 24px;
    width: 24px;
  }
`;
