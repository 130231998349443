import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Location, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, FormColumn, FormContent, FormFooter, FormHeader, Input } from 'src/components';
import { USE_SITE_AUTH } from 'src/declarations/constants';
import { Urls } from 'src/declarations/urls';
import { APIError, StateStore } from 'src/interfaces';
import { DuckActions, DuckSelectors } from 'src/redux/reducks';
import { mapApiErrors } from 'src/utils';

declare var grecaptcha: {
  ready: (handler: () => void) => void;
  execute: (key: string, options: { action: string }) => Promise<string>;
};
const RECAPTCHA_SITE_KEY = '6LfbcKAUAAAAACUDq_t59TP8MawQXB37DVEeSOjZ';

interface ResetPasswordProps {
  location: Location;
  finishPasswordResetError: APIError | undefined;
  startPasswordResetError: APIError | undefined;
  isResettingPassword: boolean;
  startPasswordReset: (payload: { email: string; recaptcha: string }) => void;
  finishPasswordReset: (payload: { token: string; password: string; recaptcha: string }) => void;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({
  location,
  finishPasswordResetError,
  startPasswordResetError,
  isResettingPassword,
  startPasswordReset,
  finishPasswordReset,
}) => {
  const [email, setEmail] = useState('');
  const [updateToken, setUpdateToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [didSubmitReset, setDidSubmitReset] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const token = search.get('token');
    if (token) {
      setUpdateToken(token);
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  }, []);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (updateToken) {
      if (newPassword === confirmNewPassword) {
        grecaptcha.ready(() => {
          grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'password_update' }).then((recaptcha: string) => {
            finishPasswordReset({ password: newPassword, token: updateToken, recaptcha });
          });
        });
        setPasswordMatchError(false);
      } else {
        setPasswordMatchError(true);
      }
    } else {
      grecaptcha.ready(() => {
        grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'password_reset' }).then((recaptcha: string) => {
          startPasswordReset({ email, recaptcha });
        });
      });
      setDidSubmitReset(true);
    }
  }

  function redirect() {
    const url = new URL(Urls.ResetPassword);
    if (updateToken) {
      url.searchParams.set('token', updateToken);
    }

    window.location.assign(url.toString());
  }

  if (USE_SITE_AUTH) {
    redirect();
    return null;
  }

  let ready = false;

  // trying to match the wc3 email validation spec so it matches the css
  if (email.length > 5 && email.indexOf('@') && ['@', '.'].indexOf(email[email.length - 1]) === -1) {
    ready = true;
  }

  let errors = mapApiErrors(finishPasswordResetError);

  const contentForm = () => {
    if (updateToken) {
      return (
        <form method="post" onSubmit={handleSubmit}>
          <div className="messages">
            Passwords must be 8 or more characters in length
            <br />
          </div>

          <Input
            type="password"
            name="newPassword"
            autoComplete="new-password"
            id="id_pass"
            tabIndex={1}
            value={newPassword}
            disabled={isResettingPassword}
            onChange={(e) => setNewPassword(e)}
            error={errors.get('password')}
            required={true}
            label="New Password"
            className="text-input"
          />

          <Input
            type="password"
            name="confirmNewPassword"
            autoComplete="new-password"
            id="id_conf_pass"
            tabIndex={1}
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e)}
            required={true}
            label="Confirm New Password"
            className="text-input"
          />

          <Button size="medium" kind="primary" width="full" type="submit" tabIndex={3} disabled={isResettingPassword}>
            Save Password
          </Button>

          <div className="text-center help">
            <Link to={'/login'}>
              <i className="ion-md-arrow-back" />
              &nbsp; Back to Login
            </Link>
          </div>
        </form>
      );
    } else {
      return (
        <form method="post" onSubmit={handleSubmit}>
          <Input
            type="email"
            name="email"
            autoComplete="email"
            id="id_email"
            tabIndex={1}
            value={email}
            disabled={isResettingPassword}
            onChange={(e) => setEmail(e)}
            required={true}
            label="Email"
            className="text-input"
          />

          <Button size="medium" width="full" kind="primary" type="submit" tabIndex={3} disabled={isResettingPassword || !ready}>
            Send Reset Email
          </Button>

          <div className="text-center help">
            <Link to={'/login'}>
              <i className="ion-md-arrow-back" />
              &nbsp; Back to Login
            </Link>
          </div>
        </form>
      );
    }
  };

  return (
    <ResetForm>
      <FormContent>
        <FormHeader title="Reset password" />

        {passwordMatchError ? <p className="error text-danger">New passwords do not match.</p> : ''}
        {startPasswordResetError ? (
          <p className="error text-danger">Unable to issue reset request: {startPasswordResetError.message}</p>
        ) : (
          ''
        )}
        {finishPasswordResetError ? <p className="error text-danger">Unable to update password: {finishPasswordResetError.message}</p> : ''}
        {didSubmitReset && !startPasswordResetError ? <h3>Password Reset Email Sent</h3> : ''}
        {contentForm()}
      </FormContent>

      <FormFooter>
        Don't have an account?
        <Link to={'/signup'}>
          <Button size="small" kind="secondary">
            Sign up
          </Button>
        </Link>
      </FormFooter>
    </ResetForm>
  );
};

const ResetForm = styled(FormColumn)`
  max-width: 300px;
  min-height: 540px;
`;
ResetForm.displayName = 'ResetForm';

const ResetPasswordLoader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const finishPasswordResetError = useSelector((state: StateStore) =>
    DuckSelectors.getApiError(state, DuckActions.finishPasswordReset.type)
  );
  const startPasswordResetError = useSelector((state: StateStore) => DuckSelectors.getApiError(state, DuckActions.startPasswordReset.type));
  const isResettingPassword = useSelector((state: StateStore) =>
    DuckSelectors.getApiRequestIsActive(state, DuckActions.finishPasswordReset.type)
  );

  const handleStartPasswordReset = (payload: { email: string; recaptcha: string }) => {
    dispatch(DuckActions.startPasswordReset(payload));
  };

  const handleFinishPasswordReset = (payload: { token: string; password: string; recaptcha: string }) => {
    dispatch(DuckActions.finishPasswordReset({ ...payload, navigate }));
  };

  return (
    <ResetPassword
      location={location}
      finishPasswordResetError={finishPasswordResetError}
      startPasswordResetError={startPasswordResetError}
      isResettingPassword={isResettingPassword}
      startPasswordReset={handleStartPasswordReset}
      finishPasswordReset={handleFinishPasswordReset}
    />
  );
};

export default ResetPasswordLoader;
