import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DropdownItem } from './DropdownItem';

export interface DropdownLinkProps {
  to: string;
  target?: string;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  color?: 'danger';
  children?: React.ReactNode;
}

export const DropdownLink: React.FC<DropdownLinkProps> = ({ to, target, children, className, color, onClick }) => {
  return (
    <DropdownLinkContainer className={className}>
      {!to.startsWith('http') && (
        <Link
          to={to}
          target={target}
          onClick={(e) => {
            onClick?.(e);
          }}
          className={(color && `theme-${color}`) || ''}
          data-testid="drop-down-link"
        >
          {children}
        </Link>
      )}
      {to.startsWith('http') && (
        <a
          href={to}
          target={target}
          onClick={(e) => {
            onClick?.(e);
          }}
          className={(color && `theme-${color}`) || ''}
          data-testid="drop-down-link"
        >
          {children}
        </a>
      )}
    </DropdownLinkContainer>
  );
};

const DropdownLinkContainer = styled(DropdownItem)`
  padding: 0;
  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 8px 18px 8px 18px;
    transition: color 0.15s ease-in;
    div {
      transition: color 0.15s ease-in;
    }
    &:hover {
      div.title-text {
        color: ${(props) => props.theme.colors.blue[100]};
      }
      div.sub-text {
        color: ${(props) => props.theme.colors.blue[70]};
      }
    }
  }

  &:first-child {
    padding: 0;
    a {
      padding-top: 20px;
    }
  }

  &:last-of-type {
    padding: 0;
    a {
      padding-bottom: 20px;
    }
  }

  a.theme-danger {
    color: ${(props) => props.theme.colors.red[100]} !important;
  }
  a.theme-danger:hover {
    color: ${(props) => props.theme.colors.red[80]} !important;
  }
`;
DropdownLinkContainer.displayName = 'DropdownLinkContainer';

export default DropdownLink;
