import { JobState } from '../graphql/generated';

export const DASHBOARD_BRAND_NAME: string = 'Hub';
export const DEVOPS_BRAND_NAME: string = 'Appflow';

// ROOT API URL
export const API_ROOT = import.meta.env.VITE_API_ROOT || 'http://localhost:7000';
export const USE_SITE_AUTH = API_ROOT !== 'http://localhost:7000';

// Testing
export const IS_MOCKING = import.meta.env.VITE_MOCKS === 'true';
export const IS_TEST = import.meta.env.VITE_TEST === 'true';

// Amplitude
export const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY || '';

// Sentry
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const CI_COMMIT_SHA = import.meta.env.VITE_CI_COMMIT_SHA;
export const CI_ENVIRONMENT_SLUG = import.meta.env.VITE_CI_ENVIRONMENT_SLUG;

// Stripe Key
export const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '';

// Launch Darkly Client Side ID: set to staging in the .env by default
export const LAUNCH_DARKLY_CLIENT_SIDE_ID = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID || '';

const InProgressStates: Array<string> = [JobState.Created, JobState.Running, JobState.Building, JobState.Pending];

interface IJobs {
  isJobInProgress: (state: JobState) => boolean;
}

export const Jobs: IJobs = {
  isJobInProgress: (state: JobState): boolean => {
    return InProgressStates.includes(state);
  },
};

export enum HubSpotEvent {
  login = '000006636951',
  signup = '000006637193',
}

export const HubspotFormsIds = {
  extend_trial: 'a9b2c628-5faa-4530-b6f3-5c8441dc51a9',
  request_native_key: 'fecc95fa-0db9-4e14-9d1c-0c56fc87f105',
  request_appflow_enterprise_access: 'a6d856c5-39f4-4725-a78f-c356d8d64ac5',
  request_auth_connect_access: '7a9af48e-87b4-4a17-8ea7-a6d3f078c752',
  request_auth_connect_demo: '7a9af48e-87b4-4a17-8ea7-a6d3f078c752',
  request_cloud_cli_access: '6a7fb951-38bd-437c-977f-4bd78e449928',
  request_identity_vault_access: 'ddec9088-5f0f-4b42-a99c-709d036eed07',
  request_identity_vault_demo: 'ddec9088-5f0f-4b42-a99c-709d036eed07',
  request_secure_storage_access: '9a3ef494-3892-45a7-88a8-32efdba84c7a',
  request_secure_store_demo: '9a3ef494-3892-45a7-88a8-32efdba84c7a',
  request_ionic_payments_access: 'eb2648de-7e89-4783-a9af-26287f5206e2',
  request_trial: '9fe2503a-13bb-49da-aa4d-a5dce612ad02',
  request_extend_or_purchase: '8874d837-4c4e-42b2-8d56-b95a699a7eae',
  contact_sales_for_native: '1d517034-0dcd-4e0d-bbf3-554e8c919701',
  contact_sales_for_advisory: '79207bfe-5ad1-4bcf-9f02-efbd2ace702f',
  contact_sales_for_studio: '086f208b-9948-443b-9f89-5feb05414c5b',
  enterprise_git_providers: 'fddb1807-7056-4186-b59c-752d313eb0f1',
  live_update_limit_increase: '5d62e821-e546-4a79-9b2e-81872dd5bb97',
  start_native_trial_from_wizard: 'f1be143e-dde8-4b10-b5ff-1522a0bdd712',
  start_native_trial_part_1: '5681ee76-8e07-4c96-a794-1620e4c9f24a',
  start_native_trial_part_2: '7b331554-393e-4c9d-bb92-abe143c5744c',
  register_for_portals_key: '925cf274-0894-43ba-8230-af32f6b0cbd7',
  signup_new_native_types: '6202d962-37af-4db9-a144-e7571ac0accf',
};

export const EnterpriseTrialConst = {
  trial_length: 30,
};
