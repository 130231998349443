import { JSX as IoniconsJSX } from 'ionicons';
import { defineCustomElement } from 'ionicons/components/ion-icon.js';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'src/styled';
import IconBackground from './IconBackground';

// eslint-disable-next-line no-restricted-imports
export * from 'ionicons/icons';

export interface IonIconProps extends Omit<IoniconsJSX.IonIcon, 'size'> {
  className?: string;
  /** Set to `light` or `dark` for preset colors. */
  highlightBackground?: string;
  href?: string;
  isExternal?: boolean;
  onClick?: (e?: unknown) => void;
  size?: 'small' | 'large' | number;
  rotate?: number;
  topAdjust?: number;
}

const IonIcon: React.FC<IonIconProps> = (props) => {
  const { className, color, highlightBackground, href, isExternal, onClick, size = 18, rotate, topAdjust, ...rest } = props;

  useEffect(() => {
    defineCustomElement();
  }, []);

  const sizeString = typeof size === 'string' ? size : undefined;
  const sizeNumber = typeof size === 'number' ? size : undefined;
  const icon = <ion-icon size={sizeString} {...rest} />;

  const iconElement = (
    <>
      {highlightBackground ? (
        <div style={{ position: 'relative' }} onClick={onClick}>
          <IconBackground background={highlightBackground}>{icon}</IconBackground>
        </div>
      ) : (
        icon
      )}
    </>
  );

  const linkedIcon = href ? (
    <Link to={isExternal ? { pathname: href } : href} onClick={(e) => e.stopPropagation()} target={isExternal ? '_blank' : '_self'}>
      {iconElement}
    </Link>
  ) : (
    iconElement
  );

  return (
    <IonIconContainer
      color={color}
      className={className}
      topAdjust={topAdjust}
      size={sizeNumber}
      rotate={rotate}
      hover={!!onClick || !!href}
    >
      {linkedIcon}
    </IonIconContainer>
  );
};

export default IonIcon;

interface IonIconContainerProps {
  hover?: boolean;
  size?: number;
  rotate?: number;
  topAdjust?: number;
  color?: string;
}

const IonIconContainer = styled.div<IonIconContainerProps>`
  ${(props) => `
    ion-icon {
        display: block;
        color: ${props.color ? props.color : props.theme.colors.indigo[80]};
        ${props.size ? `font-size: ${props.size}px;` : ''}
        ${props.rotate ? `transform: rotate(${props.rotate}deg);` : ''}
        ${
          props.topAdjust
            ? `
          position: relative;
          top: ${props.topAdjust}px;
        `
            : ''
        }
      }
    }

    ${
      props.hover
        ? `
      &:hover ion-icon {
        color: ${props.color ? props.color : props.theme.colors.indigo[80]};
        transition: color 0.15s ease-in;
      }
    `
        : ''
    }
    `}

  a {
    color: unset;
  }

  ${`
    display: flex;
    align-items: center;
  `}
`;
IonIconContainer.displayName = 'IonIconContainer';
IonIconContainer.defaultProps = { theme };
