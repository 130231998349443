import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Tooltip from 'src/components/tooltip/Tooltip';
import IconBackground from './IconBackground';
import { IconType } from './IconSets';

interface IconProps {
  icon?: IconType;
  size?: number;
  highlightBackground?: 'light' | 'dark';
  hoverIcon?: IconType['file'];
  href?: string;
  className?: string;
  title?: string;
  padding?: number;
  tooltip?: string;
  onClick?: (e?: unknown) => void;
  forceNormalPosition?: boolean;
  forceHoverPosition?: boolean;
}

export const Icon: React.FC<PropsWithChildren<IconProps>> = ({
  icon,
  children,
  size,
  className,
  title,
  highlightBackground,
  href,
  padding,
  onClick,
  tooltip,
  forceNormalPosition,
  forceHoverPosition,
}) => {
  let iconStyled;

  if (children) {
    iconStyled = children;
  } else {
    let iconToUse: IconType;
    icon = icon as IconType;
    if (forceNormalPosition) {
      iconToUse = { ...icon, hoverPosition: undefined };
    } else if (forceHoverPosition) {
      const { hoverPosition } = icon;
      iconToUse = { ...icon, position: hoverPosition!, hoverPosition: undefined };
    } else {
      iconToUse = icon;
    }
    iconStyled = (
      <IconStyled
        title={title}
        className={`icon ${className} ${!!onClick ? 'clickable' : ''}`}
        {...iconToUse}
        size={size || icon.size}
        style={{ padding: `${padding}px` }}
        onClick={onClick}
      />
    );
  }

  const linkedIcon = href ? (
    <Link to={href} onClick={(e) => e.stopPropagation()}>
      {iconStyled}
    </Link>
  ) : (
    iconStyled
  );

  const iconElement = (
    <>
      {highlightBackground ? (
        <div style={{ position: 'relative' }} onClick={onClick}>
          <IconBackground background={highlightBackground}>{linkedIcon}</IconBackground>
        </div>
      ) : (
        linkedIcon
      )}
    </>
  );

  if (tooltip) {
    return (
      <Tooltip position="bottom" text={tooltip} spacing={8}>
        {iconElement}
      </Tooltip>
    );
  }

  return iconElement;
};

const IconStyled = styled.i<IconType>`
  display: inline-flex;
  background: url('${(props) => props.file}') no-repeat;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  background-size: ${(props) => props.size}px;
  background-position: center -${(props) => (props.position - 1) * props.size}px;

  &.clickable {
    cursor: pointer;
  }

  ${(props) => {
    if (props.hoverPosition || props.hoverFile) {
      return `
        &:after {
          content: "";
          display: block;
          position: relative;
          top: 0; left: 0; bottom: 0; right: 0;
          width: ${props.size}px;
          height: ${props.size}px;
          background: url('${props.file}') no-repeat;
          background-size: ${props.size}px;
          ${props?.hoverPosition && `background-position: center -${(props.hoverPosition - 1) * props.size}px;`}
          ${props?.hoverFile && `background: url(${props.hoverFile}) no-repeat`}
          transition: opacity .15s ease-in;
          opacity: 0;
        }
        &:hover:after, &.hover:after {
          opacity: 1;
        }
      `;
    }
    return '';
  }}
`;

interface IconContainerProps {
  position: 'left' | 'right';
}

export const IconContainer = styled.span<IconContainerProps>`
  margin: ${(props) => (props.position === 'left' ? '0 8px 0 0' : '0 0 0 8px')};
`;

IconContainer.displayName = 'IconContainer';

export default Icon;
