import styled from 'styled-components';

interface IconBackgroundProps {
  background: string;
}

const IconBackground = styled.div<IconBackgroundProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  &::after {
    content: '';
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    padding: 8px;
    background-size: 40%;
    background-position: center;
    background-repeat: no-repeat;
    // Circle at center in the background-image doesn't work well on safari, so
    // we'll just apply a border-radius so it'll work everywhere
    border-radius: 50%;
    ${(props) => {
      let color: string;
      if (props.background === 'light') {
        color = props.theme.colors.indigo[10];
      } else if (props.background === 'dark') {
        color = props.theme.colors.indigo[80];
      } else {
        color = props.background;
      }
      return `
        background-image: radial-gradient(
          circle at center,
          ${color} 0%,
          ${color} 50%,
          ${color} 50%,
          transparent 50%,
          transparent 50%
        );
      `;
    }}
    transition: background-size 0.15s ease;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &.active {
    &::after {
      background-size: 170%;
      opacity: 1;
    }
    i:before {
      opacity: 0;
    }
    i:after {
      opacity: 1;
    }
  }
`;
IconBackground.displayName = 'IconBackground';

export default IconBackground;
