import React, { useCallback } from 'react';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

export interface TooltipProps {
  className?: string;
  text: string | JSX.Element;
  position: 'top' | 'right' | 'bottom' | 'left';
  clickable?: Boolean;
  isDisabled?: Boolean;
  spacing?: number;
  offset?: number;
  hide?: Boolean;
  arrow?: Boolean;
  showOnRender?: Boolean;
  onAction?: (event: React.MouseEvent<HTMLDivElement>) => void;
  width?: string;
  height?: string;
  pointerEnterTimeout?: number;
  pointerLeaveTimeout?: number;
  onPointerOver?: () => void;
  onPointerLeft?: () => void;
  children?: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({ onAction, onPointerOver, onPointerLeft, ...props }) => {
  const tooltipContainerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipArrowRef = useRef<HTMLDivElement>(null);
  const pointerEnterTimeout = useRef<number>();
  const pointerLeaveTimeout = useRef<number>();
  const isArrowVisible = useRef(false);

  useEffect(() => {
    if (props.showOnRender) {
      setTimeout(showTooltip, 800);
    }
  });

  useEffect(() => {
    if (props.isDisabled) {
      onPointerLeave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isDisabled]);

  const toggleArrowVisible = () => {
    isArrowVisible.current = !isArrowVisible.current;
  };

  const showTooltip = () => {
    if (!tooltipRef.current || !tooltipContainerRef.current) {
      return;
    }
    const tooltipContainerRect = tooltipContainerRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const tooltipWidth = tooltipRef.current.style.transform === 'scale3d(1, 1, 1)' ? tooltipRect.width : tooltipRect.width / 0.75;
    const tooltipHeight = tooltipRef.current.style.transform === 'scale3d(1, 1, 1)' ? tooltipRect.height : tooltipRect.height / 0.75;
    // Hard-coded the min-width of the mainLayoutContainer to ensure arrow stays attached to tooltip body.
    const boundingWidth = window.innerWidth > 1024 ? window.innerWidth : 1024;
    switch (props.position) {
      case 'bottom':
        tooltipRef.current.style.left =
          Math.min(
            Math.max(10, tooltipContainerRect.left + 0.5 * tooltipContainerRect.width - 0.5 * tooltipWidth),
            boundingWidth - tooltipWidth - 10
          ) +
          (props.offset || 0) +
          'px';
        tooltipRef.current.style.top = tooltipContainerRect.bottom + (props.spacing || 0) + 'px';
        break;
      case 'right':
        tooltipRef.current.style.top =
          Math.min(
            Math.max(10, tooltipContainerRect.top + 0.5 * tooltipContainerRect.height - 0.5 * tooltipHeight),
            window.innerHeight - tooltipHeight - 10
          ) +
          (props.offset || 0) +
          'px';
        tooltipRef.current.style.left = tooltipContainerRect.right + (props.spacing || 0) + 'px';
        break;
      case 'top':
        tooltipRef.current.style.left =
          Math.min(
            Math.max(10, tooltipContainerRect.left + 0.5 * tooltipContainerRect.width - 0.5 * tooltipWidth),
            boundingWidth - tooltipWidth - 10
          ) +
          (props.offset || 0) +
          'px';
        tooltipRef.current.style.top = tooltipContainerRect.top - tooltipHeight - (props.spacing || 0) + 'px';
        break;
      case 'left':
        tooltipRef.current.style.top =
          Math.min(
            Math.max(10, tooltipContainerRect.top + 0.5 * tooltipContainerRect.height - 0.5 * tooltipHeight),
            window.innerHeight - tooltipHeight - 10
          ) +
          (props.offset || 0) +
          'px';
        tooltipRef.current.style.left = tooltipContainerRect.left - tooltipWidth - (props.spacing || 0) + 'px';
        break;
    }

    if (!props.isDisabled) {
      tooltipRef.current.style.opacity = '1';
      tooltipRef.current.style.transform = 'scale3d(1, 1, 1)';
    }

    if (tooltipArrowRef.current) {
      const tooltipArrowRect = tooltipArrowRef.current.getBoundingClientRect();
      const tooltipArrowWidth =
        tooltipArrowRect.width +
        parseInt(window.getComputedStyle(tooltipArrowRef.current).getPropertyValue('margin-left')) +
        parseInt(window.getComputedStyle(tooltipArrowRef.current).getPropertyValue('margin-right'));
      /*
        The length of the hypotenuse of the square forming the arrow is needed for positioning
        the tooltip element relative to the arrow. Trig functions were used instead of Pythagorean
        to avoid exponentiation. Sine(Theta) is equal to the opposite side of a triangle divided
        by the hypotenuse of a triangle that is equal to the radius of the unit circle.
        The rectangle forming the arrow is rotated by 45 degrees which equates to a theta value
        of Pi / 4 radians. The equation is rearranged to solve for the unit hypotenuse and then
        multiplied by the width of the square to get the hypotenuse of the square. This value is
        then halved to get the width of the arrow after the square is rotated and one of the halves
        made invisible.
        */
      const tooltipArrowWidthVisible = Math.floor(
        parseInt(window.getComputedStyle(tooltipArrowRef.current).getPropertyValue('width')) / (2 * Math.sin(Math.PI / 4))
      );
      const isOutsideWindowRight =
        tooltipContainerRect.left + 0.5 * tooltipContainerRect.width - 0.5 * tooltipWidth > window.innerWidth - tooltipWidth - 10;
      const isOutsideWindowLeft = tooltipContainerRect.left + 0.5 * tooltipContainerRect.width - 0.5 * tooltipWidth < tooltipWidth + 10;

      switch (props.position) {
        case 'bottom':
          tooltipRef.current.style.top = tooltipContainerRect.bottom + (props.spacing || 0) + tooltipArrowWidthVisible + 'px';
          // Moving pointer from tooltip container to tooltip element retriggers onPointerEnter.
          // So guarding logic was added to preserve the tooltip arrow's position on retrigger.
          if (!isArrowVisible.current) {
            if (isOutsideWindowRight || isOutsideWindowLeft) {
              tooltipArrowRef.current.style.left =
                tooltipContainerRect.left -
                parseInt(tooltipRef.current.style.left) +
                0.5 * tooltipContainerRect.width -
                0.5 * tooltipArrowWidth +
                'px';
            } else {
              tooltipArrowRef.current.style.left = 0.5 * tooltipWidth - 0.5 * tooltipArrowWidth + 'px';
            }
            tooltipArrowRef.current.style.top = -0.5 * tooltipArrowWidth + 1 + 'px';
            tooltipArrowRef.current.style.transform = 'rotate(225deg)';
            toggleArrowVisible();
          }
          break;
        case 'right':
          tooltipRef.current.style.left = tooltipContainerRect.right + (props.spacing || 0) + tooltipArrowWidthVisible + 'px';
          if (!isArrowVisible.current) {
            tooltipArrowRef.current.style.left = -0.5 * tooltipArrowWidth + 2 + 'px';
            tooltipArrowRef.current.style.top = 0.5 * tooltipHeight - 0.5 * tooltipArrowWidth + 'px';
            tooltipArrowRef.current.style.transform = 'rotate(135deg)';
            toggleArrowVisible();
          }
          break;
        case 'top':
          tooltipRef.current.style.top = tooltipContainerRect.top - tooltipHeight - (props.spacing || 0) - tooltipArrowWidthVisible + 'px';
          if (!isArrowVisible.current) {
            if (isOutsideWindowRight || isOutsideWindowLeft) {
              tooltipArrowRef.current.style.left =
                tooltipContainerRect.left -
                parseInt(tooltipRef.current.style.left) +
                0.5 * tooltipContainerRect.width -
                0.5 * tooltipArrowWidth +
                'px';
            } else {
              tooltipArrowRef.current.style.left = 0.5 * tooltipWidth - 0.5 * tooltipArrowWidth + 'px';
            }
            tooltipArrowRef.current.style.top = tooltipHeight - 0.5 * tooltipArrowWidth - 1 + 'px';
            tooltipArrowRef.current.style.transform = 'rotate(45deg)';
            toggleArrowVisible();
          }
          break;
        case 'left':
          tooltipRef.current.style.left = tooltipContainerRect.left - tooltipWidth - (props.spacing || 0) - tooltipArrowWidthVisible + 'px';
          if (!isArrowVisible.current) {
            tooltipArrowRef.current.style.left = tooltipWidth - 0.5 * tooltipArrowWidth - 2 + 'px';
            tooltipArrowRef.current.style.top = 0.5 * tooltipHeight - 0.5 * tooltipArrowWidth + 'px';
            tooltipArrowRef.current.style.transform = 'rotate(315deg)';
            toggleArrowVisible();
          }
          break;
      }
    }
  };

  const hideTooltip = () => {
    if (tooltipRef.current) {
      tooltipRef.current.style.transition = 'opacity 0.075s ease, transform: 0.075s ease;';
      tooltipRef.current.style.opacity = '0';
      tooltipRef.current.style.transform = 'scale3d(0.75, 0.75, 1)';
      setTimeout(() => {
        if (tooltipRef.current) {
          tooltipRef.current.style.left = '-100px';
          tooltipRef.current.style.top = '-100px';
          tooltipRef.current.style.transition = 'opacity 0.15s ease, transform: 0.15s ease;';
        }
      }, 75);
    }
  };

  const onPointerEnter = () => {
    onPointerOver?.();
    if (props.showOnRender || props.hide) {
      return;
    }
    clearTimeout(pointerLeaveTimeout.current);
    pointerEnterTimeout.current = window.setTimeout(showTooltip, !!props.pointerEnterTimeout ? props.pointerEnterTimeout : 250);
  };

  const onPointerLeave = useCallback(() => {
    onPointerLeft?.();
    if (props.showOnRender) {
      return;
    }

    clearTimeout(pointerEnterTimeout.current);
    pointerLeaveTimeout.current = window.setTimeout(hideTooltip, !!props.pointerLeaveTimeout ? props.pointerLeaveTimeout : 50);
  }, [onPointerLeft, props.showOnRender, props.pointerLeaveTimeout]);

  // apply onPointerLeave handler directly to DOM element due to https://github.com/facebook/react/issues/18753
  useEffect(() => {
    // due to the nature of hooks and refs, tooltipContainerRef.current will always be set when this hook runs
    tooltipContainerRef.current?.addEventListener('pointerleave', onPointerLeave);
    const el = tooltipContainerRef.current;

    return () => {
      el?.removeEventListener('pointerleave', onPointerLeave);
    };
  }, [onPointerLeave]);

  return (
    <span
      className={props.className}
      style={{ width: !!props.width ? props.width : 'unset', height: !!props.height ? props.height : 'unset' }}
    >
      <TooltipContainer
        ref={tooltipContainerRef}
        onPointerEnter={onPointerEnter}
        onClick={(event) => {
          onPointerLeave();
          onAction?.(event);
        }}
        {...props}
      >
        {props.children}
        {!props.hide && (
          <TooltipElement ref={tooltipRef} className={props.className}>
            {props.arrow ? <TooltipArrow ref={tooltipArrowRef} className={props.className} /> : null}
            {props.text}
          </TooltipElement>
        )}
      </TooltipContainer>
    </span>
  );
};

const TooltipContainer = styled.div`
  display: inline-flex;
  ${(props: TooltipProps) => (props.clickable ? 'cursor: pointer' : '')};
  max-width: 100%;

  &.usage-tooltip {
    width: 100%;
    height: 100%;
  }
`;

TooltipContainer.displayName = 'TooltipContainer';

const TooltipArrow = styled.div`
  position: absolute;
  top: -36px;
  right: -15px;
  width: 15px;
  height: 15px;
  border-radius: 0 0 2px 0;
  margin: 32px;
  transform: rotate(225deg);
  background: linear-gradient(-45deg, #343d4c 50%, transparent 50%);
  box-shadow: none;

  &.usage-tooltip {
    border-bottom: 1px solid #dee3ea;
    border-right: 1px solid #dee3ea;
    background: linear-gradient(-45deg, #ffffff 50%, transparent 50%);
  }
`;

const TooltipElement = styled.div`
  position: fixed;
  opacity: 0;
  transition: opacity 0.15s ease, transform 0.15s ease;
  top: -100px;
  left: -100px;
  white-space: nowrap;
  transform: scale3d(0.75, 0.75, 1);
  font-size: 10px;
  padding: 4px 7px 4px 7px;
  font-family: Eina, Helvetica Neue, Helvetica, sans-serif;
  letter-spacing: 0;
  font-weight: 600;
  z-index: 100;

  &.usage-tooltip {
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(2, 8, 20, 0.08), 0px 2px 4px rgba(2, 8, 20, 0.1);
    border: 1px solid #dee3ea;
    border-radius: 6px;
  }

  :not(.usage-tooltip) {
    background: #343d4c;
    color: #ecf0f5;
    box-shadow: 0 0 1px rgba(0, 20, 56, 0.14), 0 1px 2px rgba(0, 20, 56, 0.08);
    border-radius: 6px;
  }
`;

export default Tooltip;
