import React, { PropsWithChildren } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { PartialLoadingSplash } from 'src/components';
import ErrorHandler from 'src/components/error-handler/ErrorHandler';
import { MainLayout } from 'src/features/_dashboard/components';
import { Login, Logout } from 'src/features/user-login/pages';
import { useBootstrapContext } from './BootstrapContext';

// lazy loaded components
const AppWizard = React.lazy(() => import('src/features/app-create/pages/create-wizard-app/CreateWizardApp'));
const AppBuilder = React.lazy(() => import('src/features/app-create/pages/app-builder/AppBuilder'));
const AppImport = React.lazy(() => import('src/features/app-create/pages/app-import/AppImport'));
const AppDetail = React.lazy(() => import('src/features/app/pages/app-detail/AppDetail'));
const PreviewAnonymous = React.lazy(() => import('src/features/web-preview/pages/web-preview/WebPreview'));
const PreviewScreen = React.lazy(() => import('src/features/web-preview/pages/preview-screen/PreviewScreen'));
const PlanSetup = React.lazy(() => import('src/features/subscriptions/pages/plan-setup/PlanSetup'));
const AccountTypeSetup = React.lazy(() => import('src/features/subscriptions/pages/account-type-setup/AccountTypeSetup'));
const ImpersonateUser = React.lazy(() => import('src/features/_dashboard/pages/impersonate-user/ImpersonateUser'));
const AcceptInvite = React.lazy(() => import('src/features/org-members/pages/accept-invite/AcceptInvite'));
const ResetPassword = React.lazy(() => import('src/features/user-login/pages/reset-password/ResetPassword'));
const EnterpriseForm = React.lazy(() => import('src/features/subscriptions/pages/enterprise-form/EnterpriseForm'));
const OrgContainer = React.lazy(() => import('src/features/_dashboard/pages/org/OrgContainer'));
const SettingsRoutes = React.lazy(() => import('src/features/user-settings/pages/user-settings/UserSettings'));
const DefaultPage = React.lazy(() => import('src/features/_dashboard/pages/default-page/DefaultPage'));
const Signup = React.lazy(() => import('src/features/user-login/pages/signup/Signup'));
const OrgsPage = React.lazy(() => import('src/features/_dashboard/pages/orgs/OrgsPage'));
const CreatePersonalAccessToken = React.lazy(
  () => import('src/features/user-settings/pages/create-personal-access-token/CreatePersonalAccessToken')
);

interface Props {
  isAuthed: boolean;
  pathOverride?: string;
}

const MainLayoutWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ErrorHandler>
      <MainLayout>
        <React.Suspense fallback={<PartialLoadingSplash />}>{children}</React.Suspense>
      </MainLayout>
    </ErrorHandler>
  );
};

export const MainRoutes: React.FC<Props> = ({ pathOverride, isAuthed }) => {
  const { defaultOrg, canSwitchOrgs, isLoading } = useBootstrapContext();

  const authedUI = () => {
    if (isLoading) {
      return <PartialLoadingSplash />;
    }

    return (
      <React.Suspense fallback={<PartialLoadingSplash />}>
        <Routes>
          <Route
            path="/accept-invite/:token"
            element={
              <MainLayoutWrapper>
                <AcceptInvite />
              </MainLayoutWrapper>
            }
          />

          <Route path="/app/:appId/preview/:buildId?" element={<PreviewScreen />} />
          <Route
            path="/app/:id/*"
            element={
              <MainLayoutWrapper>
                <AppDetail />
              </MainLayoutWrapper>
            }
          />

          <Route path="/contact-us" element={<EnterpriseForm />} />

          {/* Marketing site directs to this route to avoid having appflow sidebar */}
          <Route path="/create-app/:hash" element={<AppWizard />} />

          <Route
            path="/impersonate"
            element={
              <MainLayoutWrapper>
                <ImpersonateUser />
              </MainLayoutWrapper>
            }
          />

          <Route
            path="/org/:slug/build-app"
            element={
              <MainLayoutWrapper>
                <AppBuilder />
              </MainLayoutWrapper>
            }
          />
          <Route
            path="/org/:slug/build-app/:hash"
            element={
              <MainLayoutWrapper>
                <AppWizard />
              </MainLayoutWrapper>
            }
          />
          <Route path="/org/:slug/create-app/:hash" element={<AppWizard />} />
          <Route
            path="/org/:slug/import-app"
            element={
              <MainLayoutWrapper>
                <AppImport />
              </MainLayoutWrapper>
            }
          />
          <Route path="/org/:slug/plan-setup/:plan" element={<PlanSetupRedirect />} />
          <Route path="/org/:slug/plan-setup/:plan/:term" element={<PlanSetup />} />
          <Route path="/org/:slug/account-setup" element={<AccountTypeSetup />} />
          <Route
            path="/org/:slug/*"
            element={
              <MainLayoutWrapper>
                <OrgContainer />
              </MainLayoutWrapper>
            }
          />
          {canSwitchOrgs && (
            <Route
              path="/orgs"
              element={
                <MainLayoutWrapper>
                  <OrgsPage />
                </MainLayoutWrapper>
              }
            />
          )}

          <Route path="/plan-setup/:plan" element={<PlanSetupRedirect />} />
          {/* Needed for redirect in auth flow */}
          <Route path="/plan-setup/:plan/:term" element={<PlanSetup />} />

          <Route path="/portals" element={<Navigate to={`/org/${defaultOrg.slug}/portals-key`} />} />

          <Route path="/preview/:appId/:previewHash?" element={<PreviewAnonymous />} />

          <Route
            path="/settings/personal-access-tokens/create"
            element={
              <MainLayoutWrapper>
                <CreatePersonalAccessToken />
              </MainLayoutWrapper>
            }
          />
          <Route
            path="/settings/*"
            element={
              <MainLayoutWrapper>
                <SettingsRoutes />
              </MainLayoutWrapper>
            }
          />

          <Route path="/dash" element={<DefaultPage />} />
          <Route path="/logout" element={<Logout />} />

          <Route path="/reset-password" element={<Navigate to="/settings/account" />} />
          <Route path="*" element={<Navigate to={pathOverride || '/dash'} />} />
        </Routes>
      </React.Suspense>
    );
  };

  const unAuthedUI = () => {
    return (
      <React.Suspense fallback={<PartialLoadingSplash />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/preview/:appId/:previewHash" element={<PreviewAnonymous />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </React.Suspense>
    );
  };

  return isAuthed ? authedUI() : unAuthedUI();
};

const PlanSetupRedirect = () => {
  const { slug, plan } = useParams<{ slug: string; plan: string }>();

  if (slug) {
    return <Navigate to={`/org/${slug}/plan-setup/${plan}/monthly`} />;
  }

  return <Navigate to={`/plan-setup/${plan}/monthly`} />;
};
