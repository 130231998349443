import React, { useEffect } from 'react';
import { Customer } from 'src/interfaces';
import { useGetCurrentCustomer } from 'src/queries/billing';
import usePreviousValue from 'src/utils/usePreviousValue';

interface AccountCheckProps {
  customer?: Customer;
}

export const AccountCheck = ({ customer }: AccountCheckProps): JSX.Element => {
  const previousCustomer = usePreviousValue<Customer | undefined>(customer);

  useEffect(() => {
    if (customer) {
      const lastCustomerId = previousCustomer ? previousCustomer.id : '';
      if (customer.id !== lastCustomerId && window.profitwell) {
        window.profitwell('user_id', customer.id);
        window.profitwell('cq_get');
      }
    }
  }, [customer, previousCustomer]);

  return <></>;
};

const AccountCheckLoader = (): JSX.Element => {
  const { data: customer } = useGetCurrentCustomer();

  return <AccountCheck customer={customer} />;
};

export default AccountCheckLoader;
