import classNames from 'classnames';
import React, { ElementType } from 'react';
import { css } from 'styled-components';
import IonIcon from 'src/components/icon/IonIcon';
import LoadingSpinner from 'src/components/loading-spinner/LoadingSpinner';
import { theme } from 'src/styled';
import { GenericComponent } from '../generic';
import { AbstractButtonProps } from './ButtonTypes';

const buttonStyles = css`
  --border-size: 2px;
  border-radius: 4px;
  font-family: ${theme.fonts.text};
  font-style: normal;
  font-weight: ${theme.fontWeights.semibold};
  line-height: ${theme.lineHeights.title};
  border: none;
  padding: 0 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: pre;
  cursor: pointer;

  &.icon-trailing .icon {
    margin-left: 6px;
  }

  &.icon-leading .icon {
    margin-right: 6px;
  }

  .icon.no-tooltip ion-icon {
    pointer-events: none;
  }

  &.large {
    height: 20px;
    font-size: ${theme.fontSizes[16]};
  }

  &.medium {
    height: 16px;
    font-size: ${theme.fontSizes[14]};

    &:not(.icon-only) {
      min-width: 80px;
    }
  }

  &.small {
    height: 16px;
    font-size: ${theme.fontSizes[13]};
  }

  &.fullwidth {
    width: 100%;
  }

  &.primary {
    &:focus,
    &.focus,
    &:focus-visible {
      box-shadow: 0 0 0 var(--border-size) ${theme.colors.blue[30]};
    }
  }

  &.secondary,
  &.secondary-alt {
    background: transparent;
    color: ${theme.colors.blue[90]};

    &:focus,
    &.focus {
      box-shadow: 0 0 0 var(--border-size) ${theme.colors.blue[30]};
    }
  }

  &.tertiary,
  &.tertiary-alt {
    background: ${theme.colors.white};
    color: ${theme.colors.indigo[80]};

    &:focus,
    &.focus {
      box-shadow: 0 0 0 var(--border-size) ${theme.colors.indigo[30]};
    }
  }

  &.destructive {
    color: ${theme.colors.red[90]};

    &:focus,
    &.focus {
      box-shadow: 0 0 0 var(--border-size) ${theme.colors.red[20]};
    }
  }

  &.destructive-light,
  &.destructive-alt {
    background: ${theme.colors.white};
    color: ${theme.colors.red[90]};

    &:focus,
    &.focus {
      box-shadow: 0 0 0 var(--border-size) ${theme.colors.red[20]};
    }
  }

  &.success {
    color: #007d22;

    &:focus,
    &.focus {
      box-shadow: 0 0 0 var(--border-size) ${theme.colors.green[20]};
    }
  }

  &.success-light,
  &.success-alt {
    background: ${theme.colors.white};
    color: #007d22;

    &:focus,
    &.focus {
      box-shadow: 0 0 0 var(--border-size) ${theme.colors.green[20]};
    }
  }

  &.warning {
    color: ${theme.colors.yellow[60]};

    &:focus,
    &.focus {
      box-shadow: 0 0 0 var(--border-size) ${theme.colors.yellow[20]};
    }
  }

  &.neutral {
    color: ${theme.colors.indigo[90]};

    &:focus,
    &.focus {
      box-shadow: 0 0 0 var(--border-size) ${theme.colors.indigo[20]};
    }
  }

  &.ghost {
    background: transparent;
    color: ${theme.colors.blue[90]};

    &:focus,
    &.focus {
      box-shadow: 0 0 0 var(--border-size) ${theme.colors.blue[30]};
    }
  }

  &.disabled,
  &.loading {
    cursor: not-allowed;
  }

  a& {
    &.large {
      &.icon-trailing .icon {
        margin-left: 8px;
      }

      &.icon-leading .icon {
        margin-right: 8px;
      }
    }

    &.icon-trailing .icon {
      margin-left: 4px;
    }

    &.icon-leading .icon {
      margin-right: 4px;
    }

    &.primary,
    &.secondary {
      color: ${theme.colors.blue[90]};

      &:active,
      &.active {
        color: #0046c1;
      }

      &:hover,
      &.hover {
        color: ${theme.colors.blue[100]};
      }

      &.disabled {
        color: ${theme.colors.carbon[20]};
      }
    }

    &.tertiary {
      color: ${theme.colors.indigo[80]};

      &:active,
      &.active {
        color: ${theme.colors.indigo[100]};
      }

      &:hover,
      &.hover {
        color: ${theme.colors.indigo[90]};
      }

      &.disabled {
        color: ${theme.colors.carbon[20]};
      }
    }

    &.destructive,
    &.destructive-light {
      color: ${theme.colors.red[90]};

      &:active,
      &.active {
        color: #b3000e;
      }

      &:hover,
      &.hover {
        color: ${theme.colors.red[100]};
      }

      &.disabled {
        color: ${theme.colors.carbon[20]};
      }
    }

    &.success {
      color: #007d22;

      &:active,
      &.active {
        color: ${theme.colors.green[100]};
      }

      &:hover,
      &.hover {
        color: #008725;
      }

      &.disabled {
        color: ${theme.colors.carbon[20]};
      }
    }

    &.warning {
      color: ${theme.colors.yellow[60]};

      &:active,
      &.active {
        color: ${theme.colors.yellow[70]};
      }

      &:hover,
      &.hover {
        color: ${theme.colors.yellow[50]};
      }

      &.disabled {
        color: ${theme.colors.carbon[20]};
      }
    }

    &.neutral {
      color: ${theme.colors.indigo[80]};

      &:active,
      &.active {
        color: ${theme.colors.indigo[100]};
      }

      &:hover,
      &.hover {
        color: ${theme.colors.indigo[90]};
      }

      &.disabled {
        color: ${theme.colors.carbon[20]};
      }
    }

    &.disabled {
      color: ${theme.colors.carbon[20]};
    }
  }

  button& {
    --border-size: 3px;
    border-radius: 8px;
    padding: 0 16px;

    &.icon-trailing .icon {
      margin-left: 6px;
    }

    &.icon-leading .icon {
      margin-right: 6px;
    }

    &.large {
      height: 48px;
    }

    &.medium {
      height: 40px;
    }

    &.small {
      height: 32px;
      padding: 0 12px;
    }

    &.icon-only {
      &.large {
        width: 48px;
      }

      &.medium {
        width: 40px;
      }

      &.small {
        width: 32px;
      }
    }

    &.primary {
      background: ${theme.colors.blue[90]};
      color: ${theme.colors.white};

      &:hover,
      &.hover {
        background: ${theme.colors.blue[80]};
      }

      &:active,
      &.active {
        background: ${theme.colors.blue[100]};
      }
    }

    &.secondary {
      border: 1px solid ${theme.colors.blue[90]};

      &:hover,
      &.hover {
        background: ${theme.colors.blue[10]};
      }

      &:active,
      &.active {
        background: ${theme.colors.blue[30]};
      }

      &.disabled {
        border-color: transparent;
      }
    }

    &.secondary-alt {
      border: none;
      background: ${theme.colors.blue[0]};

      &:hover,
      &.hover {
        background: ${theme.colors.blue[10]};
      }
    }

    &.disabled {
      &.primary,
      &.secondary,
      &.secondary-alt,
      &.tertiary,
      &.tertiary-alt,
      &.destructive,
      &.destructive-alt,
      &.destructive-light,
      &.success,
      &.success-alt,
      &.success-light,
      &.warning,
      &.neutral,
      &.ghost {
        &,
        &:hover,
        &.hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
          color: ${theme.colors.carbon[20]};
          box-shadow: none;

          &:not(.ghost) {
            background: ${theme.colors.indigo[20]};
          }
        }
      }
    }

    &.ghost {
      padding: 0 8px;
      &:hover,
      &.hover {
        background: ${theme.colors.blue[10]};
      }

      &:active,
      &.active {
        background: ${theme.colors.blue[30]};
      }

      &.disabled {
        background: ${theme.colors.white};
      }
    }

    &.destructive {
      background: ${theme.colors.red[90]};
      color: ${theme.colors.white};

      &:hover,
      &.hover {
        background: ${theme.colors.red[70]};
      }

      &:active,
      &.active {
        background: ${theme.colors.red[100]};
      }
    }

    &.destructive-light {
      border: 1px solid ${theme.colors.red[90]};

      &:hover,
      &.hover {
        background: ${theme.colors.red[0]};
      }

      &:active,
      &.active {
        background: ${theme.colors.red[20]};
      }

      &.disabled {
        border-color: transparent;
      }
    }

    &.destructive-alt {
      border: none;
      background: ${theme.colors.red[0]};

      &:hover,
      &.hover {
        background: ${theme.colors.red[10]};
      }
    }

    &.success {
      background: #007d22;
      color: ${theme.colors.white};

      &:hover,
      &.hover {
        background: #008725;
      }

      &:active,
      &.active {
        background: ${theme.colors.green[100]};
      }
    }

    &.success-light {
      border: 1px solid ${theme.colors.green[90]};

      &:hover,
      &.hover {
        background: ${theme.colors.green[0]};
      }

      &:active,
      &.active {
        background: ${theme.colors.green[20]};
      }

      &.disabled {
        border-color: transparent;
      }
    }

    &.success-alt {
      border: none;
      background: ${theme.colors.green[0]};

      &:hover,
      &.hover {
        background: ${theme.colors.green[10]};
      }
    }

    &.warning {
      background: ${theme.colors.yellow[60]};
      color: #5f4100;

      &:hover,
      &.hover {
        background: ${theme.colors.yellow[50]};
      }

      &:active,
      &.active {
        background: ${theme.colors.yellow[70]};
      }
    }

    &.neutral {
      background: ${theme.colors.indigo[80]};
      color: ${theme.colors.white};

      &:hover,
      &.hover {
        background: ${theme.colors.indigo[90]};
      }

      &:active,
      &.active {
        background: ${theme.colors.indigo[90]};
      }
    }

    &.disabled {
      background: ${theme.colors.indigo[20]};
    }

    &.tertiary {
      border: 1px solid ${theme.colors.indigo[40]};

      &:hover,
      &.hover {
        background: ${theme.colors.indigo[10]};
      }

      &:active,
      &.active {
        background: ${theme.colors.indigo[20]};
      }

      &.disabled {
        border-color: transparent;
      }
    }

    &.tertiary-alt {
      border: none;
      background: ${theme.colors.indigo[0]};

      &:hover,
      &.hover {
        background: ${theme.colors.indigo[10]};
      }
    }
  }
`;

const AbstractButton = <T extends ElementType>({
  as: BaseComponent = 'button' as T,
  className = '',
  size = 'medium',
  kind = 'primary',
  width = 'auto',
  disabled,
  innerRef,
  icon,
  iconPosition,
  focus = false,
  hover = false,
  active = false,
  loading = false,
  onClick,
  preventDefault = false,
  children,
  ...rest
}: AbstractButtonProps<T>) => {
  const iconSize = (() => {
    switch (size) {
      case 'small':
        return 16;
      case 'medium':
        return 16;
      case 'large':
        return 20;
    }
  })();

  const iconColor = (() => {
    if (disabled) {
      return theme.colors.carbon[20];
    }
    switch (kind) {
      case 'primary':
        return BaseComponent === 'button' ? theme.colors.white : theme.colors.blue[90];
      case 'secondary':
      case 'secondary-alt':
        return theme.colors.blue[90];
      case 'tertiary':
      case 'tertiary-alt':
        return BaseComponent === 'button' ? theme.colors.indigo[80] : theme.colors.indigo[90];
      case 'destructive':
        return BaseComponent === 'button' ? theme.colors.white : theme.colors.red[90];
      case 'destructive-alt':
      case 'destructive-light':
        return theme.colors.red[90];
      case 'ghost':
        return theme.colors.blue[90];
      case 'success':
        return BaseComponent === 'button' ? theme.colors.white : theme.colors.green[90];
      case 'success-alt':
      case 'success-light':
        return '#009128';
      case 'warning':
        return '#5f4100';
      case 'neutral':
        return BaseComponent === 'button' ? theme.colors.white : theme.colors.indigo[80];
    }
  })();

  let iconContent: JSX.Element | undefined;
  if (icon) {
    iconContent = <IonIcon size={iconSize} color={iconColor} icon={icon} className="icon no-tooltip" />;
  }

  let content: JSX.Element | undefined;
  if (children) {
    // respect the iconPosition prop
    content = (
      <>
        {iconContent && iconPosition === 'leading' && iconContent}
        {children}
        {iconContent && iconPosition === 'trailing' && iconContent}
      </>
    );
  } else {
    content = iconContent;
  }

  return (
    <GenericComponent
      as={BaseComponent}
      ref={innerRef}
      css={buttonStyles}
      className={`${className} ${size} ${kind} ${icon ? `icon-${iconPosition}` : ''} ${classNames({
        fullwidth: width === 'full',
        focus,
        hover,
        active,
        disabled,
        loading,
        'icon-only': icon && !children,
      })}`}
      disabled={disabled}
      onClick={(e: React.MouseEvent<React.ElementRef<T>>) => {
        preventDefault && e.preventDefault();

        if (disabled || loading) {
          return;
        }

        onClick?.(e);

        // Clear focus after the button is clicked
        if (e.currentTarget instanceof HTMLButtonElement || e.currentTarget instanceof HTMLAnchorElement) {
          e.currentTarget.blur();
        }
      }}
      {...rest}
    >
      {loading ? <LoadingSpinner size={size} color={iconColor} /> : content}
    </GenericComponent>
  );
};

AbstractButton.displayName = 'AbstractButton';

export default AbstractButton;
