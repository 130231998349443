import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import { Avatar, Dropdown, DropdownDivider, DropdownHeader, DropdownLink, Icon, ItemInfo, MenuOrgPersonal } from 'src/components';
import { Organization } from 'src/graphql/generated';
import { useGetDataForAccountSwitcherDropdownQuery } from 'src/graphql/generated-hooks';
import { StateStore, User } from 'src/interfaces';
import { useCurrentOrgSlug } from 'src/queries/currentOrgSlug';
import { DuckSelectors } from 'src/redux/reducks';
import { joinChildren } from 'src/utils';
import { BootstrapContextLoaded, useBootstrapContext } from '../../pages/_dashboard/BootstrapContext';

export interface AccountSwitcherDropdownProps {
  user: User;
  currentOrg: Pick<Organization, 'id' | 'avatar'>;
  orgs: BootstrapContextLoaded['orgs'];
}

export const AccountSwitcherDropdown = ({ currentOrg, user, orgs }: AccountSwitcherDropdownProps): JSX.Element => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleOrgDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const _prioritizeCurrentOrg = (o1: (typeof orgs)[number], o2: (typeof orgs)[number]) => {
    if (o1.id === currentOrg.id) {
      return -1;
    }
    if (o2.id === currentOrg.id) {
      return 1;
    }
    return 0;
  };

  const top3Orgs = [...orgs].sort(_prioritizeCurrentOrg).slice(0, 3);
  const showAllOrgsLink = orgs.length > 3;
  const hasOrgs = orgs.length > 0;

  const DropdownSidebarIcon = (() => {
    if (currentOrg && currentOrg.avatar) {
      return <Avatar className={`account-toggle ${isDropdownOpen ? 'open' : ''}`} userPicture={currentOrg.avatar} size={28} />;
    }
    if (currentOrg) {
      return <Icon icon={MenuOrgPersonal.org} size={28} />;
    }
    return (
      <Avatar className={`account-toggle ${isDropdownOpen ? 'open' : ''}`} userName={user.name} userPicture={user.picture} size={28} />
    );
  })();

  const Top3OrgsSection = hasOrgs && (
    <>
      <DropdownHeader>Organizations</DropdownHeader>
      {top3Orgs.map((o) => {
        const isSelected = currentOrg && currentOrg.id === o.id;
        return (
          <DropdownLink key={o.id} to={`/org/${o.slug}`} className={`${isSelected ? 'selected' : ''}`} onClick={toggleOrgDropdown}>
            <ItemInfo
              size="small"
              title={o.name}
              subTitle={`${o.memberTotal} ${o.memberTotal === 0 || o.memberTotal > 1 ? 'members' : 'member'}`}
              icon={o.avatar ? <Avatar userPicture={o.avatar} size={28} /> : <Icon icon={MenuOrgPersonal.org} size={28} />}
            >
              {isSelected && (
                <div className="checkmark">
                  <img src="img/blue-checkmark.png" />
                </div>
              )}
            </ItemInfo>
          </DropdownLink>
        );
      })}
    </>
  );

  const AllOrgsSection = showAllOrgsLink && (
    <DropdownLink className="view-all" to="/orgs" onClick={toggleOrgDropdown}>
      View all organizations
    </DropdownLink>
  );

  const sections = [Top3OrgsSection, AllOrgsSection].filter((s): s is JSX.Element => !!s);

  return (
    <AccountSwitcherDropdownContainer data-testid="org-switcher">
      <Dropdown isOpen={isDropdownOpen} onToggle={toggleOrgDropdown} toggleElement={DropdownSidebarIcon}>
        {joinChildren(sections, <DropdownDivider />)}
      </Dropdown>
    </AccountSwitcherDropdownContainer>
  );
};

interface AccountSwitcherDropdownLoaderProps extends ConnectedProps<typeof connector> {}

const AccountSwitcherDropdownLoader = ({ user }: AccountSwitcherDropdownLoaderProps) => {
  const { isLoading, orgs } = useBootstrapContext();
  const currentOrgSlug = useCurrentOrgSlug();
  const { data: org } = useGetDataForAccountSwitcherDropdownQuery(
    { slug: currentOrgSlug! },
    { enabled: !!currentOrgSlug, select: (data) => data.organization }
  );

  if (isLoading || !user || !org) {
    return <></>;
  }

  return <AccountSwitcherDropdown currentOrg={org} user={user} orgs={orgs} />;
};

const AccountSwitcherDropdownContainer = styled.div`
  .dash-dropdown {
    min-width: 230px;
    left: 40px;

    i.icon {
      border-radius: 24px;
    }

    .selected {
      a {
        div.title-text {
          color: ${(props) => props.theme.colors.blue[100]};
        }
        div.sub-text {
          color: ${(props) => props.theme.colors.blue[70]};
        }
      }

      .checkmark {
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  .dash-dropdown.open {
    top: -21px;

    .dash-dropdown-caret {
      top: 31px;
      left: -9px;
      transform: rotate(-90deg);
    }
  }

  i.account-toggle,
  div.account-toggle {
    transition: box-shadow 0.15s ease, opacity 0.15s ease;
    border-radius: 24px;
    cursor: pointer;
    opacity: 0.9;
    &:hover,
    &.open {
      box-shadow: 0 0 0 4px #3e4c62;
      opacity: 1;
    }
  }
`;

const mapStateToProps = (state: StateStore) => {
  const user = DuckSelectors.getUser(state);
  return {
    user,
  };
};

const connector = connect(mapStateToProps, {});

export default connector(AccountSwitcherDropdownLoader);
