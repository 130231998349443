import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Sidebar from 'src/features/_dashboard/pages/sidebar/Sidebar';
import AccountCheck from '../account-check/AccountCheck';
import NotificationBar from '../notification-bar/NotificationBar';

const MainLayout: React.FC<PropsWithChildren> = ({ children }) => {
  let pageContentRef = useRef<HTMLDivElement>(null);

  const handleResetScrollTop = useCallback(() => {
    if (pageContentRef.current) {
      pageContentRef.current.scrollTop = 0;
    }
  }, [pageContentRef]);

  useEffect(() => {
    window.addEventListener('resetScrollTop', handleResetScrollTop);

    return () => {
      window.removeEventListener('resetScrollTop', handleResetScrollTop);
    };
  }, [handleResetScrollTop]);

  return (
    <>
      <NotificationBar />
      <MainLayoutContainer>
        <SideNav>
          <Sidebar />
        </SideNav>
        <MainArea>
          <PageContent ref={pageContentRef}>{children}</PageContent>
          <AccountCheck />
        </MainArea>
      </MainLayoutContainer>
    </>
  );
};

const MainLayoutContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 1024px;
  min-height: 0;
`;
MainLayoutContainer.displayName = 'MainLayoutContainer';

const SideNav = styled.div`
  width: 64px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  height: 100%;
  flex-shrink: 0;
`;
SideNav.displayName = 'SideNav';

const MainArea = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;
MainArea.displayName = 'MainArea';

const PageContent = styled.div`
  overflow: auto;
  flex: 1;
  position: relative;
`;
PageContent.displayName = 'PageContent';

export default MainLayout;
