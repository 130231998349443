import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppLoadingSplash } from 'src/components';
import { USE_SITE_AUTH } from 'src/declarations/constants';
import { LocalhostLogin, SsoError } from 'src/features/user-login/components';
import { StateStore } from 'src/interfaces';
import { useLogin } from 'src/queries/login';
import { DuckActions, DuckSelectors } from 'src/redux/reducks';
import { setDocumentTitle } from 'src/utils';
import { performAuthorizationRequest } from 'src/utils/auth';
import useQueryString from 'src/utils/useQueryString';

const LoginLoader: React.FunctionComponent = () => {
  const ssoError = useSelector((state: StateStore) => DuckSelectors.getApiError(state, DuckActions.receiveOAuth2Auth.type));
  const bootParams = useSelector((state: StateStore) => DuckSelectors.getBootParams(state));
  const { login, error: authError, isLoading: isLoggingIn } = useLogin();

  const qs = useQueryString();
  const identityProvider = qs.get('idp') || '';

  const queryClient = useQueryClient();

  useEffect(() => {
    setDocumentTitle('Login');

    // Remove all query cache in case the user just logged out
    // Only affects local login
    queryClient.removeQueries();
    // Including queryClient can cause a race condition where we log out after logging in
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (ssoError) {
    return <SsoError error={ssoError.message} frameworkSsoLoginUrl="/login" />;
  }

  if (USE_SITE_AUTH) {
    performAuthorizationRequest(identityProvider, bootParams);
    return <AppLoadingSplash />;
  }

  return <LocalhostLogin error={authError && authError.message} isLoggingIn={isLoggingIn} onLocalhostLogin={login} />;
};

export default LoginLoader;
