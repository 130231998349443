import { useDispatch, useSelector } from 'react-redux';
import { StateStore } from 'src/interfaces';
import { DuckActions, DuckSelectors } from 'src/redux/reducks';
import { LoginPayload, SignupPayload } from 'src/redux/reducks/modules/auth';

export function useLogin() {
  const dispatch = useDispatch();

  return {
    /** Performs local email + password login. */
    login: (creds: LoginPayload) => dispatch(DuckActions.login(creds)),
    error: useSelector((state: StateStore) => DuckSelectors.getApiError(state, DuckActions.login.type)),
    isLoading: useSelector((state: StateStore) => DuckSelectors.getApiRequestIsActive(state, DuckActions.login.type)),
  };
}

export function useSignup() {
  const dispatch = useDispatch();

  return {
    /** Performs local email + password signup. */
    signup: (creds: SignupPayload) => dispatch(DuckActions.signup(creds)),
    error: useSelector((state: StateStore) => DuckSelectors.getApiError(state, DuckActions.signup.type)),
    isLoading: useSelector((state: StateStore) => DuckSelectors.getApiRequestIsActive(state, DuckActions.signup.type)),
  };
}

export function useLogout() {
  const dispatch = useDispatch();

  return () => dispatch(DuckActions.logout({}));
}
