import {
  AuthorizationServer,
  Client,
  authorizationCodeGrantRequest,
  calculatePKCECodeChallenge,
  generateRandomCodeVerifier,
  isOAuth2Error,
  processAuthorizationCodeOAuth2Response,
  validateAuthResponse,
} from '@panva/oauth4webapi';
import { API_ROOT } from 'src/declarations/constants';
import { Urls } from 'src/declarations/urls';
import { AuthError, AuthRequest, AuthResult } from 'src/interfaces/Auth';
import { BootParams } from 'src/interfaces/BootParams';

const as: AuthorizationServer = { issuer: 'https://ionic.io', token_endpoint: `${API_ROOT}/oauth/token` };
const client: Client = { client_id: 'dash', token_endpoint_auth_method: 'none' };

const AUTHORIZATION_REQUEST_KEY = 'authorization_request';

function setAuthorizationRequest(request: AuthRequest): Promise<void> {
  window.localStorage.setItem(AUTHORIZATION_REQUEST_KEY, JSON.stringify(request));
  return Promise.resolve();
}

function getAuthorizationRequest(): Promise<AuthRequest | null> {
  const value = window.localStorage.getItem(AUTHORIZATION_REQUEST_KEY);
  return Promise.resolve(value ? (JSON.parse(value) as AuthRequest) : null);
}

function removeAuthorizationRequest(): Promise<void> {
  window.localStorage.removeItem(AUTHORIZATION_REQUEST_KEY);
  return Promise.resolve();
}

export async function performAuthorizationRequest(identityProvider: string, bootParams?: BootParams) {
  const codeVerifier = generateRandomCodeVerifier();
  const codeChallenge = await calculatePKCECodeChallenge(codeVerifier);
  const redirectUri = new URL('/login', window.location.href).href;

  const params = new URLSearchParams();
  params.set('client_id', 'dash');
  params.set('code_challenge', codeChallenge);
  params.set('code_challenge_method', 'S256');
  params.set('redirect_uri', redirectUri);
  params.set('response_type', 'code');

  await setAuthorizationRequest({ codeVerifier, redirectUri, bootParams });

  const ALLOWED_PROVIDERS = [
    'http://localhost:3000',
    'http://localhost:5000',
    'https://ionic.io',
    'https://ionicframework.com',
    'https://staging.ionic.io',
    'https://staging.ionicframework.com',
  ];

  const authorizationEndpoint = ALLOWED_PROVIDERS.includes(identityProvider)
    ? new URL('/oauth/authorize', identityProvider).href
    : Urls.AuthorizationEndpoint;

  window.location.href = `${authorizationEndpoint}?${params.toString()}`;
}

export async function completeAuthorizationRequest(): Promise<{ err?: AuthError; result?: AuthResult }> {
  const request = await getAuthorizationRequest();
  if (request === null) {
    return {};
  }
  await removeAuthorizationRequest();

  const parameters = validateAuthResponse(as, client, new URL(window.location.href));
  if (isOAuth2Error(parameters)) {
    return { err: parameters };
  }
  if (parameters.get('code') === null) {
    return {};
  }

  const { redirectUri, codeVerifier } = request;
  const response = await authorizationCodeGrantRequest(as, client, parameters, redirectUri, codeVerifier);
  const result = await processAuthorizationCodeOAuth2Response(as, client, response);
  if (isOAuth2Error(result)) {
    return { err: result };
  }

  return { result: { accessToken: result.access_token, bootParams: request.bootParams } };
}
