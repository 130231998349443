import styled from 'styled-components';

export const DropdownDivider = styled.li`
  height: 1px;
  padding: 0px !important;
  margin: 8px 0;
  overflow: hidden;
  background-color: #e8ebf0;
`;
DropdownDivider.displayName = 'DropdownDivider';
