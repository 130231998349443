import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

export interface PortalModalProps {
  size?: 'small' | 'medium' | 'large';
  children: React.ReactNode;
}

const PortalModal: React.FC<PortalModalProps> = ({ size = 'small', children }) => {
  let existingModalElement = document.getElementById('portal-modal');
  let newModalElement: HTMLElement = document.createElement('div');
  newModalElement.setAttribute('style', 'position: relative; z-index: 1005;');
  const mount = document.body;

  if (!existingModalElement) {
    newModalElement = document.createElement('div');
  }

  // If the root modal element cannot be found,
  // such as when unit tests are running,
  // then we can create one on the fly
  useEffect(() => {
    if (!existingModalElement) {
      mount!.appendChild(newModalElement);
    }

    return () => {
      if (!existingModalElement) {
        mount!.removeChild(newModalElement);
      }
    };
  }, [existingModalElement, newModalElement, mount]);

  return createPortal(
    <StyledPortal>
      <StyledModal size={size}>{children}</StyledModal>
    </StyledPortal>,
    existingModalElement ? existingModalElement : newModalElement
  );
};
PortalModal.displayName = 'Portal';

const StyledPortal = styled.div`
  background-color: rgba(15, 30, 55, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledModal = styled.div<{ size: 'small' | 'medium' | 'large' }>`
  background: ${(props) => props.theme.colors.white};
  border-radius: ${(props) =>
    props.size === 'large' ? props.theme.radii[24] : props.size === 'medium' ? props.theme.radii[24] : props.theme.radii[8]};
  max-height: calc(100vh - 20px);
  max-width: ${(props) =>
    props.size === 'large' ? '960px' : props.size === 'medium' ? '500px' : props.size === 'small' ? '412px' : '412px'};
  position: relative;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
`;

export default PortalModal;
