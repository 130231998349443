import React, { createContext, ReactNode, useContext, useState } from 'react';
import { App, Webhook } from 'src/graphql/generated';

interface WebhookContext {
  addWebhook: (id: App['id'], webhook: Webhook) => void;
  removeWebhook: (id: App['id']) => void;
  getWebhook: (id: App['id']) => Webhook | undefined;
}

const webhookContext = createContext<WebhookContext>({
  addWebhook: () => {
    /* no-op */
  },
  removeWebhook: () => {
    /* no-op */
  },
  getWebhook: () => undefined,
});

const WebhookProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [webhooks, setWebhooks] = useState<Map<App['id'], Webhook>>(new Map());

  const addWebhook = (id: App['id'], webhook: Webhook) => {
    const _webhooks = new Map(webhooks);
    _webhooks.set(id, webhook);
    setWebhooks(_webhooks);
  };

  const removeWebhook = (id: App['id']) => {
    const _webhooks = new Map(webhooks);
    _webhooks.delete(id);
    setWebhooks(_webhooks);
  };

  const getWebhook = (id: App['id']) => {
    return webhooks.get(id);
  };

  const context = {
    addWebhook,
    removeWebhook,
    getWebhook,
  };

  return <webhookContext.Provider value={context}>{children}</webhookContext.Provider>;
};

function useWebhookContext() {
  return useContext(webhookContext);
}

export { WebhookProvider, useWebhookContext };
