import { GraphQLClient, Variables } from 'graphql-request';
import { API_ROOT } from 'src/declarations/constants';
import { useAuthState } from 'src/redux/selectors/useAuthState';

const endpoint = `${API_ROOT}/graphql`;
const client = new GraphQLClient(endpoint);

const graphQlClient = (token?: string) => {
  client.setHeader('authorization', `Bearer ${token}`);
  return client;
};

const useGraphQlClient = () => {
  const authState = useAuthState();
  return graphQlClient(authState.credentials?.token);
};

export const useFetcher = <TData, TVariables extends Variables>(query: string): (() => Promise<TData>) => {
  const client = useGraphQlClient();
  return async (variables?: TVariables) => {
    return client.request<TData>(query, variables).catch((err) => {
      throw err?.response?.errors;
    });
  };
};

export interface GraphqlClientError {
  message: string;
}

export default useGraphQlClient;
