import React, { PropsWithChildren } from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { IonIcon, alertCircle, checkmarkCircle, chevronDown, helpCircle, warning } from 'src/components/icon';
import { Text } from 'src/components/text';
import { theme } from 'src/styled';

export enum NotificationKind {
  ERROR,
  WARNING,
  INFO,
  SUCCESS,
}

const BackgroundColor = (props: NotificationProps): string => {
  switch (props.kind) {
    case NotificationKind.ERROR:
      return theme.colors.red[0];
    case NotificationKind.WARNING:
      return theme.colors.yellow[0];
    case NotificationKind.INFO:
      return theme.colors.blue[0];
    case NotificationKind.SUCCESS:
      return theme.colors.green[0];
    default:
      return theme.colors.white;
  }
};

const Background = styled(Text)<NotificationProps>`
  padding: 18px 16px;
  width: 100%;
  border-radius: ${(props) => props.theme.radii[8]};
  background-color: ${BackgroundColor};
  color: ${(props) => props.theme.colors.carbon[90]};
  display: flex;
  align-items: center;
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

const Content = styled.div`
  padding-left: 16px;
  width: 100%;
  flex-grow: 1;
  display: grid;
`;

const Preview = styled.div<{ isCollapsed: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-area: 1 / 1;
  opacity: 1;
  z-index: 0;

  ${(props) =>
    !props.isCollapsed
      ? `
        transition: opacity 0.4s;
        opacity: 0;
    `
      : ''}
`;

const IconSize = 18;

const Icon: React.FC<NotificationProps> = (props: NotificationProps): JSX.Element | null => {
  switch (props.kind) {
    case NotificationKind.ERROR:
      return <IonIcon icon={alertCircle} color={theme.colors.red[90]} size={IconSize} />;
    case NotificationKind.WARNING:
      return <IonIcon icon={warning} color={theme.colors.yellow[100]} size={IconSize} />;
    case NotificationKind.INFO:
      return <IonIcon icon={helpCircle} color={theme.colors.blue[90]} size={IconSize} />;
    case NotificationKind.SUCCESS:
      return <IonIcon icon={checkmarkCircle} color={theme.colors.green[100]} size={IconSize} />;
    default:
      return null;
  }
};

const Collapser = styled.button<{ isCollapsed: boolean }>`
  padding: 5px;
  margin: -5px;
  margin-left: 17px;
  background: none;
  border: none;
  height: 24px;
  width: 24px;
  transition: transform 0.5s, padding 0.5s;
  transform: rotate(0deg);

  ${(props) =>
    props.isCollapsed
      ? `
        transform: rotate(180deg);
        padding-top: 7px;
    `
      : ''}

  &:hover {
    cursor: pointer;
    color: black;
  }
`;

interface NotificationProps {
  kind: NotificationKind;
  collapsible: boolean;
}

const Notification: React.FC<PropsWithChildren<NotificationProps>> = (props) => {
  const { collapsible } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [needsCollapser, setNeedsCollapser] = useState(collapsible);

  const measureOverflow = useCallback((node: any) => {
    if (node !== null) {
      setNeedsCollapser(node.scrollWidth > node.clientWidth);
    }
  }, []);

  return (
    <Background {...props}>
      <Icon {...props} />
      <Content>
        {collapsible && (
          <Preview isCollapsed={isCollapsed} ref={measureOverflow}>
            {props.children}
          </Preview>
        )}
        <StyledCollapse collapsible={collapsible} expanded={!isCollapsed}>
          <div>
            <div>{props.children}</div>
          </div>
        </StyledCollapse>
      </Content>
      {collapsible && needsCollapser && (
        <Collapser isCollapsed={isCollapsed} onClick={(e) => setIsCollapsed(!isCollapsed)}>
          <ion-icon icon={chevronDown} color={theme.colors.indigo[80]} size={String(IconSize + 2)} />
        </Collapser>
      )}
    </Background>
  );
};

const StyledCollapse = styled.div<{ collapsible: boolean; expanded: boolean }>`
  @keyframes slidein {
    0% {
    }
    100% {
      opacity: 1;
    }
  }

  grid-area: 1 / 1;
  z-index: 1;

  ${(props) =>
    props.collapsible &&
    `
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.4s;
  `}

  ${(props) =>
    props.expanded &&
    `
    max-height: 1000px;
    opacity: 1;
    transition: max-height 1s ease-in-out, opacity 0.4s;
  `}
`;

export default Notification;
