import { camelize, camelizeKeys } from 'humps';
import { API_ROOT } from 'src/declarations/constants';
import { APIError, APIErrorDetail } from 'src/interfaces/APIError';
import { FieldErrors } from 'src/interfaces/FieldError';
import { PagerInfo } from 'src/interfaces/PagerInfo';

declare global {
  export type HTTPVerb = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';

  export interface ApiFetchOptions<T = unknown> {
    endpoint: string;
    root?: string;
    method?: HTTPVerb;
    pagerInfo?: PagerInfo;
    body?: T;
    skipAuth?: boolean;
    skipCamelize?: boolean;
  }

  export interface ApiError<T = APIErrorDetail> {
    link?: string;
    message: string;
    type: string;
    details?: T[];
  }

  export interface ApiMeta {
    total?: number;
    status: number;
    requestId?: string;
    version?: string;
    nextPageToken?: string;
    prevPageToken?: string;
  }

  export interface ApiSuccessResponse<T> {
    data: T;
    meta: ApiMeta;
  }

  export interface ApiErrorResponse {
    error: ApiError;
    meta: ApiMeta;
  }

  export type ApiResponse<T> = ApiSuccessResponse<T> | ApiErrorResponse;
}

export class TokenRequiredError extends Error {}

export class ApiStatusError extends Error {
  constructor(readonly status: number, readonly error?: ApiError, message?: string) {
    super(message);
  }
}

export type ApiErrorMap = Map<string, Map<string, string>>;

export const isApiErrorResponse = (response: ApiResponse<unknown>): response is ApiErrorResponse => {
  const obj = response as ApiErrorResponse;
  return obj && typeof obj === 'object' && !!obj.error;
};

export const mapApiErrors = (error?: ApiError | APIError | null) =>
  new Map<string, string>(error && Array.isArray(error.details) ? error.details.map((e) => [e.parameter, e.errors.join(' ')]) : null);

/**
 * This method accepts an APIError object and a map for api errors to human readable form based messages.
 * This will most likely be called from React Components.
 */
export const apiErrorsToFieldErrors = (error: APIError, errorMap: ApiErrorMap) => {
  const fieldErrors = new FieldErrors();

  if (error && error.message && !Array.isArray(error.details)) {
    fieldErrors.add(error.message);
    return fieldErrors;
  }

  if (error && Array.isArray(error.details)) {
    error.details.forEach((detailError) => {
      const detailMessageMap = errorMap.get(detailError.parameter) ?? new Map();

      detailError.errors.forEach((detailErrorItem) => {
        // TODO: report to sentry when a detail error item is not present in a map
        const errorMessage = detailMessageMap.get(detailErrorItem) ?? detailErrorItem;
        fieldErrors.add(errorMessage, camelize(detailError.parameter));
      });
    });
  }
  return fieldErrors;
};

const getUrlWithParams = (url: string, body?: unknown) => {
  if (!body || typeof body !== 'object') {
    return url;
  }
  const props = Object.entries(body)
    .filter(([k, v]) => v !== undefined && v !== '')
    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
    .join('&');
  if (!props) {
    return url;
  } else if (url.indexOf('?') > 0) {
    return `${url}&${props}`;
  } else {
    return `${url}?${props}`;
  }
};

const getPagedUrl = (url: string, pagerInfo?: PagerInfo) => {
  if (!pagerInfo) return url;
  if (url.indexOf('?') > 0) {
    return `${url}&page=${pagerInfo.page}&page_size=${pagerInfo.pageSize}&meta_fields=total`;
  } else {
    return `${url}?page=${pagerInfo.page}&page_size=${pagerInfo.pageSize}&meta_fields=total`;
  }
};

export const getPagerInfo = <T>(response: ApiSuccessResponse<T>, pagerInfo: PagerInfo): PagerInfo => {
  const total = response.meta.total;
  const totalPages = total ? Math.ceil(total / pagerInfo.pageSize) : undefined;
  return { ...pagerInfo, total, totalPages };
};

export const getRequest = (
  endpoint: string,
  root: string,
  method: HTTPVerb,
  headers: Headers,
  pagerInfo?: PagerInfo,
  body?: unknown
): Request => {
  const url = new URL(endpoint, root);
  const requestOptions = { method, headers };
  if (method === 'GET') {
    let urlWithParams = new URL(getPagedUrl(url.href, pagerInfo));
    urlWithParams = new URL(getUrlWithParams(urlWithParams.href, body));
    return new Request(urlWithParams.href, requestOptions);
  }
  return body ? new Request(url.href, { ...requestOptions, body: JSON.stringify(body) }) : new Request(url.href, requestOptions);
};

export const makeHeaders = (skipAuth: boolean, token?: string): Headers => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (!skipAuth) {
    if (!token) {
      throw new TokenRequiredError('Tried to make Auth header but token was not provided.');
    }
    headers.append('Authorization', `Bearer ${token}`);
  }
  return headers;
};

const makeApiErrorResponse = (status: number, errorMessage?: string): ApiErrorResponse => {
  return {
    error: {
      message: errorMessage || 'Unknown error',
      type: `${status}`,
    },
    meta: {
      status: status,
    },
  };
};

export const makeResponse = async <T>(response: Response): Promise<ApiResponse<T>> => {
  const status = response.status;
  if (!response.ok) {
    try {
      return response.json();
    } catch (e) {
      return makeApiErrorResponse(response.status, await response.text());
    }
  }
  return status === 204 ? { meta: { status } } : await response.json();
};

export const callApi = async <T, U = unknown>(options: ApiFetchOptions<U>, token?: string): Promise<ApiResponse<T>> => {
  const { endpoint, root = API_ROOT, method = 'GET', pagerInfo, skipAuth = false, body } = options;
  const headers = makeHeaders(skipAuth, token);
  const request = getRequest(endpoint, root, method, headers, pagerInfo, body);
  const response = await fetch(request);
  const resp = await makeResponse<T>(response);
  return options.skipCamelize ? resp : (camelizeKeys(resp) as unknown as ApiResponse<T>);
};

export const callApiThrowError = async <T, U = unknown>(options: ApiFetchOptions<U>, token?: string): Promise<ApiResponse<T>> => {
  const response = await callApi<T>(options, token);

  if (isApiErrorResponse(response)) {
    throw response.error;
  }

  return response;
};
