import React, { PropsWithChildren } from 'react';
import ErrorSplash from '../error-splash/ErrorSplash';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorHandler extends React.Component<PropsWithChildren, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error) {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <ErrorSplash />;
    } else {
      return <>{this.props.children}</>;
    }
  }
}

export default ErrorHandler;
