import { call, put, Effect, fork } from 'redux-saga/effects';
import { StateStore } from 'src/interfaces';
import { callApi, isApiErrorResponse } from 'src/utils';
import * as BaseDuck from './BaseDuck';
import { apiRequests } from './api';

export abstract class SagaDuck<StoreKey extends keyof StateStore, Actions extends BaseDuck.IActions>
  implements BaseDuck.Duck<StoreKey, Actions>
{
  protected mainSelect: (state: StateStore) => StateStore[StoreKey];
  actions = {} as BaseDuck.ActionCalls<Actions>;

  selectors = {};

  reducer(state: StateStore[StoreKey], action: BaseDuck.Action<any, any>) {
    return state || this.initialState;
  }

  protected getAuthToken: (state: StateStore) => string | undefined;

  constructor(readonly initialState: StateStore[StoreKey], readonly storeKey: StoreKey) {
    this.mainSelect = (state: StateStore) => state[storeKey];
    this.getAuthToken = (state: StateStore) => {
      return state.auth.credentials?.token;
    };
    this.sagaCallApi = this.sagaCallApi.bind(this);
  }

  *mainSaga(): IterableIterator<any> {}

  *sagaCallApi(actionType: string, options: ApiFetchOptions & { quiet?: boolean }, token?: string) {
    yield put(apiRequests.actions.storeApiRequestMetadata({ type: 'REQUEST', originalAction: actionType, quiet: options.quiet }));
    try {
      // @ts-ignore
      const result = yield call(callApi, options, token);
      if (isApiErrorResponse(result)) {
        yield put(
          apiRequests.actions.storeApiRequestMetadata({
            type: 'ERROR',
            originalAction: actionType,
            error: result.error,
          })
        );
      } else {
        yield put(
          apiRequests.actions.storeApiRequestMetadata({
            type: 'SUCCESS',
            originalAction: actionType,
          })
        );
      }
      return result;
    } catch (e) {
      yield put(
        apiRequests.actions.storeApiRequestMetadata({
          type: 'ERROR',
          originalAction: actionType,
          error: {
            type: e.name || 'Unknown Request Error',
            message: e.message || 'Unknown error during request',
          },
        })
      );
      throw e;
    }
  }

  /**
   * Helper that takes a saga effect object creates a listener function and forks it. Saves a bit of boiler plate by
   * allowing not to explicitly write a listner function for the pattern
   * ```typescript
   * // without forkAndListen
   * *mainSaga() {
   *  yield fork(this.listener);
   * }
   *
   * *listener() {
   *   yield takeLeading(this.actions.myAction.type, this.handler.bind(this));
   * }
   *
   * *handler(action: MyAction) {
   *   // Do stuff
   * }
   *
   * // with forkAndListen
   * *mainSaga() {
   *  yield this.forkAndListen(takeLeading(this.actions.myAction.type, this.handler.bind(this)));
   * }
   *
   * *handler(action: MyAction) {
   *   // Do stuff
   * }
   * ```
   * @param sagaEffect The saga effect i.e. takeEvery, takeLeading, etc.
   */
  protected forkAndListen(sagaEffect: Effect) {
    function* forkedListener() {
      yield sagaEffect;
    }
    return fork(forkedListener);
  }
}
