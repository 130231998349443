import { Heading, Paragraph } from '@ionic-internal/ionic-ds';
import React from 'react';
import styled from 'styled-components';
import { FormColumn, FormContent } from 'src/components/forms';
import CompatModal from '../portal-modal/CompatModal';
import PortalModal from '../portal-modal/PortalModal';

interface HubSpotModalProps {
  formId: string;
  title: string;
  subtitle?: string;
  successTitle?: string;
  successSubtitle?: string;
  successButtonText?: string;
  onFormSubmit?: () => void;
  closeModal: () => void;
}

export const HubSpotModal: React.FC<HubSpotModalProps> = ({
  formId,
  title,
  subtitle,
  successTitle = 'We received your request!',
  successSubtitle = 'One of our representatives will be in touch with you shortly.',
  successButtonText = 'Back to dashboard',
  onFormSubmit,
  closeModal,
}) => {
  const [didSubmit, setDidSubmit] = React.useState<boolean>(false);

  const formSubmitted = () => {
    setDidSubmit(true);
    onFormSubmit && onFormSubmit();
  };

  React.useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: '3776657',
        formId,
        css: '',
        target: '#hubspotForm',
        onFormSubmit: () => formSubmitted(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PortalModal>
      <CompatModal fullscreen={false} canClose={true} size="lg" close={closeModal}>
        <ModalColumn>
          <FormContent uncenter={true}>
            <HubspotForm className={`${didSubmit ? 'hide' : 'show'}`}>
              <ModalHeader>
                <Heading level={3}>{title}</Heading>
                <Paragraph level={2} lineHeight="body">
                  {subtitle}
                </Paragraph>
              </ModalHeader>
              <div id="hubspotForm" />
            </HubspotForm>

            <HubspotSuccess className={`${!didSubmit ? 'hide' : 'show'}`}>
              <ModalHeader>
                <Heading level={3}>{successTitle}</Heading>
                <Paragraph level={2} lineHeight="body">
                  {successSubtitle}
                </Paragraph>
              </ModalHeader>
              <span onClick={() => closeModal()} className="try-out">
                {successButtonText} <i className="ion-md-arrow-forward" />
              </span>
            </HubspotSuccess>
          </FormContent>
        </ModalColumn>
      </CompatModal>
    </PortalModal>
  );
};

const ModalColumn = styled(FormColumn)`
  max-width: 420px;
`;

const ModalHeader = styled.div`
  text-align: center;
  margin-bottom: 32px;
  max-width: 50vw;
  margin-left: auto;
  margin-right: auto;
`;

const Close = styled.div`
  opacity: 1;
  outline: none;
  top: 25px;
  left: 30px;
  text-align: center;
  position: absolute;
  border-radius: 50%;

  @media (max-width: 600px) {
    top: 16px;
    left: 16px;
  }

  & > div {
    height: 32px;
    width: 32px;
  }

  span {
    font-size: 11px;
    color: rgb(133, 144, 165);
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  .ion-md-close {
    &::before,
    &::after {
      content: '';
      background: url('/img/back-icon.png') no-repeat 0 0;
      background-size: 32px;
      width: 32px;
      height: 32px;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      opacity: 1;
      cursor: pointer;
    }

    &::after {
      opacity: 0;
      background-position: 0 -32px;
      transition: 0.3s opacity;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
`;
Close.displayName = 'Close';

export const HubspotForm = styled.div`
  padding-bottom: 44px;

  &.hide {
    position: absolute;
    top: -9999px;
  }
  &.show {
    position: static;
    top: 0;
  }

  .hs-form-required {
    display: none;
  }

  form {
    fieldset.form-columns-1 .input {
      margin-right: 0;
    }

    fieldset.form-columns-1 .hs-input {
      width: 100%;
    }

    fieldset.form-columns-2 .input {
      margin-right: 12px;
    }

    &.stacked .field {
      margin-bottom: 4px;
    }
  }

  select.hs-input {
    height: 44px;
    width: 100%;
  }

  textarea.hs-input {
    padding: 12px;
    min-height: 192px;
  }

  &,
  & fieldset {
    max-width: 100% !important;
  }

  .hs-form-field {
    margin-top: 14px;
  }

  .hs-richtext,
  .hs-field-desc {
    font-size: ${(props) => props.theme.fontSizes[13]};
    color: ${(props) => props.theme.colors.carbon[80]};
    line-height: 1.4;
  }

  legend.hs-field-desc {
    border-bottom: 0;
    margin-bottom: ${(props) => props.theme.space[8]};
    text-transform: none;
  }

  label {
    margin-bottom: 3px;
    display: block;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #3b434d;
    font-weight: 600;
    text-align: left;
  }

  .hs-error-msgs label {
    font-size: 12px;
    font-weight: 500;
    color: #f45454;
  }

  .hs-form-required {
    display: inline;
    color: #f45454;
    margin-left: 4px;
  }

  .hs-richtext {
    margin-top: 8px;
  }

  .hs-input,
  input.hs-input,
  select.hs-input {
    transition: border 0.3s;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid #c3cddf;
    line-height: 1.39286;
    border-radius: 4px;
    padding: 11px 15px;
    font-size: 15px;
    color: #505863;
    height: 44px;

    &:focus {
      outline: none;
      border-color: #629eff;
    }
  }

  input.hs-input[type='text'],
  input.hs-input[type='email'],
  input.hs-input[type='tel'] {
    width: 100%;
  }

  input.hs-input[type='radio'],
  input.hs-input[type='checkbox'] {
    height: auto;
    margin-right: 8px;
  }

  .hs-form-booleancheckbox-display {
    display: flex;
  }

  select.hs-input {
    height: 44px;
  }

  form fieldset.form-columns-2 .input {
    margin: 0;
  }

  fieldset.form-columns-2 .hs-form-field {
    padding: 0 10px;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  .inputs-list,
  .hs-error-msgs {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .inputs-list.multi-container {
    overflow: hidden;

    li:nth-last-child(n + 6):first-child,
    li:nth-last-child(n + 6):first-child ~ li {
      width: 50%;
      float: left;
      padding-right: 11px;
    }
    li:nth-last-child(n + 6):first-child ~ li:nth-child(even) {
      padding-right: 0;
      padding-left: 11px;
    }
  }

  .hs-form-checkbox-display {
    margin-top: 4px;
    display: flex;
  }

  .hs-form-checkbox-display .hs-input[type='checkbox'] {
    margin-right: 10px;
  }

  .hs-form-checkbox-display span {
    font-size: 15px;
    color: #505863;
  }

  .hs-error-msgs {
    padding: 5px 0 0;
    font-size: 11px;
    color: #f45454;
  }

  .hs_submit {
    margin-top: 10px;
  }

  .hs_submit input.hs-button {
    transition: all 0.3s ease;
    margin: 24px 0 0;
    float: none;
    font-size: 16px;
    font-weight: 700;
    padding: 12px 20px 16px;
    vertical-align: middle;
    color: white;
    background: #3880ff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    line-height: 18px;
    letter-spacing: -0.01em;
    display: block;
    width: 100%;
    border: 0;

    &:hover {
      box-shadow: 0 7px 14px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.08);
      background: #4d8dfd;
      color: #fff;
      outline: none;
    }
  }

  @media (max-width: 400px) {
    fieldset.form-columns-2 .hs-form-field {
      width: 100%;
      float: none;
      padding: 0;

      & + .hs-form-field {
        margin-top: 24px;
      }
    }
    .inputs-list.multi-container {
      li:nth-last-child(n + 6):first-child,
      li:nth-last-child(n + 6):first-child ~ li {
        width: 100%;
        float: none;
        padding-right: 0;
      }
      li:nth-last-child(n + 6):first-child ~ li:nth-child(even) {
        padding-left: 0;
      }
    }
    .hs_submit input.hs-button {
      width: 100%;
    }
  }
`;
HubspotForm.displayName = 'HubspotForm';

const HubspotSuccess = styled.div`
  &.hide {
    display: none;
  }
  &.show {
    display: block;
  }

  text-align: center;
  max-width: 348px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;

  .try-out {
    transition: transform 0.2s;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #3880ff;
    letter-spacing: -0.03em;

    &:hover i {
      transform: translateX(2px);
    }
  }
`;
HubspotSuccess.displayName = 'HubspotSuccess';
