import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import EmptyAppflowIcon from '../../assets/img/empty-appflow.png';
import { Urls } from '../../declarations/urls';
import { theme } from '../../styled';
import { Button } from '../button';
import { Text } from '../text';

export interface ErrorSplashProps {
  ctaText?: string;
  title?: string;
  helpText?: string | JSX.Element;
  onClick?: () => void;
}

export const ErrorSplash: React.FC<PropsWithChildren<ErrorSplashProps>> = ({ ctaText, title, helpText, children, onClick }) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      window.location.href = '/';
    }
  };

  return (
    <ErrorFoundContainer>
      <AppflowIcon />
      <ErrorHeading color={theme.colors.carbon[90]} fontWeight="semibold" size="20" letterSpacing="tight" block>
        {title || 'An unexpected error occurred.'}
      </ErrorHeading>
      <HelpText color={theme.colors.carbon[80]} fontWeight="regular" lineHeight="body" block>
        {helpText || (
          <>
            Please try again or <a href={Urls.Zendesk}>contact support</a> with steps to reproduce this issue.
          </>
        )}
      </HelpText>
      <>{children}</>
      <Button kind="primary" size="medium" onClick={handleOnClick}>
        {ctaText || 'Return to home'}
      </Button>
    </ErrorFoundContainer>
  );
};

const AppflowIcon = styled.div`
  position: relative;
  margin: 0 auto;
  width: 150px;
  height: 158px;
  background-image: url(${EmptyAppflowIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const ErrorFoundContainer = styled.div`
  position: absolute;
  left: 0;
  top: calc((100% - 472px) / 2);
  width: 100%;
  height: 472px;
  text-align: center;
`;

const ErrorHeading = styled(Text)`
  margin-block-end: ${(props) => props.theme.space[8]};
`;

const HelpText = styled(Text)`
  margin-block-end: ${(props) => props.theme.space[24]};
`;

export default ErrorSplash;
