import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useLogout } from 'src/queries/login';

export default function Logout() {
  const queryClient = useQueryClient();
  const logout = useLogout();

  useEffect(() => {
    queryClient.removeQueries();
    logout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
}
