import React from 'react';
import styled from 'styled-components';
import IonicBlackIcon from 'src/assets/img/ionic-black.svg?react';
import { NotificationKind, Notification, Button } from 'src/components';

export interface SsoErrorProps {
  error: string;
  frameworkSsoLoginUrl: string;
}

export const SsoError: React.FC<SsoErrorProps> = ({ error, frameworkSsoLoginUrl }) => {
  return (
    <SsoErrorContainer>
      <header className="sso-header">
        <a className="sso-header__logo" href="/">
          <IonicBlackIcon />
        </a>
      </header>
      <div className="sso-form">
        <h2>Log in to Ionic</h2>
        <Notification kind={NotificationKind.ERROR} collapsible={false}>
          SSO Login failed.
          <br />
          {error}
        </Notification>
        <a href={frameworkSsoLoginUrl}>
          <Button width="full" color="blue" size="large" kind="primary" type="submit" tabIndex={1}>
            Login
          </Button>
        </a>
      </div>
    </SsoErrorContainer>
  );
};

const SsoErrorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .sso-header {
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    &__logo {
      color: #b2becd;
      display: inline-block;

      &,
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .sso-form {
    width: 400px;
    margin-top: 144px;
    padding: 0;

    > h2 {
      color: #333;
      text-align: center;
      font-size: 30px;
      font-family: ${(props) => props.theme.fonts.text};
      font-weight: ${(props) => props.theme.fontWeights.bold};
      letter-spacing: ${(props) => props.theme.letterSpacings.tight};
      margin-bottom: 24px;
      line-height: 1.1;
    }

    > a {
      display: block;
      margin-top: 24px;
    }
  }
`;
