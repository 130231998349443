import mergeWith from 'lodash-es/mergeWith';
import { combineReducers } from 'redux';
import { EntitiesStore } from 'src/interfaces/StateStore';
import { DuckReducers, rawActions } from 'src/redux/reducks';
import users from './users';

// Updates an entity cache in response to any action with response.entities.
const entities = (state: EntitiesStore = {}, action: any): EntitiesStore => {
  if (action.response && action.response.entities) {
    if (action.response.result === undefined) {
      const entitySchemaKeys = Object.keys(action.response.entities);

      if (entitySchemaKeys.length === 1) {
        const entitySchemaKey = entitySchemaKeys[0];
        const entity = action.response.entities[entitySchemaKey][action.response.result]['0'];

        if (entity) {
          return {
            ...state,
            [entitySchemaKey]: {
              ...state[entitySchemaKey as keyof EntitiesStore],
              [entity.id]: entity,
            },
          };
        }
      }
    }

    return mergeWith({}, state, action.response.entities, (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        // We don't want to merge arrays, we want to replace them completely
        return srcValue;
      }
    });
  }

  switch (action.type) {
    case rawActions.logout.type:
      return {};
  }

  return state;
};

export default combineReducers({
  users,
  entities,
  ...DuckReducers,
});
