import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, FormColumn, FormContent, FormFooter, FormHeader, Input } from 'src/components';
import { USE_SITE_AUTH } from 'src/declarations/constants';
import { Urls } from 'src/declarations/urls';
import { APIErrorDetail, StateStore } from 'src/interfaces';
import { useSignup } from 'src/queries/login';
import { DuckSelectors } from 'src/redux/reducks';
import { SignupPayload } from 'src/redux/reducks/modules/auth';
import { mapApiErrors, ProductPlan, setDocumentTitle } from 'src/utils';

export interface SignupProps {
  signup: (creds: SignupPayload) => void;
  source?: string;
  plan?: ProductPlan;
  authError?: ApiError<APIErrorDetail>;
  isSigningUp: boolean;
}

export const Signup: React.FC<SignupProps> = ({ signup, source, plan, authError, isSigningUp }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  setDocumentTitle('Signup');

  function handleSignup(e: React.FormEvent) {
    e.preventDefault();
    signup({ email, username, password, name });
  }

  function redirect() {
    const planSku = plan?.sku;

    const url = new URL(Urls.Signup);
    if (planSku) {
      url.searchParams.set('plan', planSku);
    }
    if (source) {
      url.searchParams.set('source', source);
    }

    window.location.assign(url.toString());
  }

  if (USE_SITE_AUTH) {
    redirect();
    return null;
  }

  const errors = mapApiErrors(authError);

  return (
    <SignupForm>
      <FormContent>
        <FormHeader title="Create a free Ionic account" subtitle="Manage and deploy Ionic apps with your account" />

        <div className="errorlist">
          {authError ? <div className="error text-danger">Unable to create account</div> : ''}
          {authError && authError.message && <div className="error text-danger">{authError.message}</div>}
        </div>

        <form id="signup-form" onSubmit={handleSignup}>
          <Input
            type="text"
            name="name"
            autoComplete="name"
            id="id_name"
            label="Name"
            autoFocus
            error={errors.get('name')}
            tabIndex={1}
            value={name}
            disabled={isSigningUp}
            onChange={(value) => setName(value)}
            required={true}
            className="text-input"
          />
          <Input
            type="text"
            name="email"
            autoComplete="email"
            id="id_email"
            label="Email"
            tabIndex={2}
            error={errors.get('email')}
            value={email}
            disabled={isSigningUp}
            onChange={(value) => setEmail(value)}
            required={true}
            className="text-input"
          />
          <Input
            type="text"
            name="username"
            autoComplete="username"
            id="id_username"
            label="Username"
            tabIndex={2}
            error={errors.get('username')}
            value={username}
            disabled={isSigningUp}
            onChange={(value) => setUsername(value)}
            required={true}
            className="text-input"
          />
          <Input
            type="password"
            name="password"
            autoComplete="new-password"
            id="id_password"
            label="Password"
            tabIndex={2}
            value={password}
            disabled={isSigningUp}
            error={errors.get('password')}
            onChange={(value) => setPassword(value)}
            required={true}
            className="text-input"
          />

          <Button size="large" kind="primary" type="submit" width="full">
            Create account
          </Button>

          <div className="agree">
            By signing up you agree to our{' '}
            <a target="_blank" href={Urls.TermsOfService}>
              Terms of Service
            </a>{' '}
            and{' '}
            <a href={Urls.Privacy} target="_blank">
              Privacy Policy
            </a>
          </div>
        </form>
      </FormContent>

      <FormFooter>
        <span>Already have an account?</span>
        <Link to={'/login'}>
          <Button size="small" kind="secondary">
            Log in
          </Button>
        </Link>
      </FormFooter>
    </SignupForm>
  );
};

const SignupForm = styled(FormColumn)`
  min-height: 740px;

  &.short {
    min-height: 640px;
  }

  .agree {
    text-align: center;
    font-size: 11px;
    color: #a0a9b7;
  }

  .checkbox-label {
    color: #727a87;
    font-size: 11px;
    margin-left: 8px;
  }

  .login {
    flex: 0 0 68px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3b424d;
    font-size: 13px;

    button {
      margin-left: 10px;
    }
  }

  .submit-button {
    margin-top: 24px;
  }

  .plan-setup-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      img.pro-logo {
        height: 24px;
        width: 24px;
      }
    }

    .right {
      display: flex;
    }
  }
`;
SignupForm.displayName = 'SignupForm';

// TODO: refactor main Signup component to be a function and merge with SignupLoader
const SignupLoader = (props: Omit<SignupProps, 'signup' | 'authError' | 'isSigningUp'>) => {
  const { signup, error, isLoading } = useSignup();

  return <Signup {...props} signup={signup} authError={error} isSigningUp={isLoading} />;
};

const mapStateToProps = (state: StateStore) => {
  const bootParams = DuckSelectors.getBootParams(state);
  const plan = bootParams?.plan;
  const source = bootParams?.source;

  return {
    plan,
    source,
  };
};

const connector = connect(mapStateToProps);
export default connector(SignupLoader);
