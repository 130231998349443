import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from 'src/components/loading-spinner/LoadingSpinner';

export const AppLoadingSplash = () => {
  return (
    <AppLoadingSplashContainer>
      <LoadingSpinner />
    </AppLoadingSplashContainer>
  );
};

const AppLoadingSplashContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;

  .spinner {
    position: absolute;
    left: calc(50% - (36px / 2));
    top: calc(50% - (24px / 2));
  }
`;
AppLoadingSplashContainer.displayName = 'AppLoadingSplashContainer';

export default AppLoadingSplash;
