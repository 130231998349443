import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { theme } from 'src/styled';
import { Flex } from '../layouts';

export interface ItemInfoProps {
  actionSection?: JSX.Element;
  fullWidth?: boolean;
  size?: 'single' | 'small' | 'regular' | 'large';
  title?: JSX.Element | string;
  subTitle?: JSX.Element | string;
  icon?: JSX.Element | string | null;
  className?: string;
}
export const ItemInfo: React.FC<PropsWithChildren<ItemInfoProps>> = ({
  size = 'regular',
  title,
  subTitle,
  icon,
  className,
  children,
  fullWidth,
  actionSection,
}) => {
  const classes = `${className || ''} dash-size-${size}`;
  return (
    <ItemInfoContainer fullWidth={fullWidth} className={classes}>
      {icon && <ItemInfoIcon>{typeof icon === 'string' ? <img className="avatar" src={icon} /> : icon}</ItemInfoIcon>}
      <ItemInfoContent>
        <ItemInfoTitle className="title-text">{title}</ItemInfoTitle>
        <ItemInfoSubTitle className="sub-text">{subTitle}</ItemInfoSubTitle>
      </ItemInfoContent>
      {children}
      {actionSection && <ActionSection>{actionSection}</ActionSection>}
    </ItemInfoContainer>
  );
};
ItemInfo.displayName = 'ItemInfo';

export const ItemInfoIcon = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  align-self: center;
  img.avatar {
    border-radius: 10px;
  }
`;
ItemInfoIcon.displayName = 'ItemInfoIcon';

export const ItemInfoTitle = styled.div`
  color: ${(props) => props.theme.colors.carbon[90]};
  font-weight: 600;
`;
ItemInfoTitle.displayName = 'ItemInfoTitle';
ItemInfoTitle.defaultProps = { theme };

export const ItemInfoSubTitle = styled.div`
  color: ${(props) => props.theme.colors.carbon[60]};
`;
ItemInfoSubTitle.displayName = 'ItemInfoSubTitle';
ItemInfoSubTitle.defaultProps = { theme };

export const ItemInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  line-height: normal;
  min-width: 0;
  margin-top: 2px;
  align-items: center;

  ${(props: { fullWidth?: boolean }) => (props.fullWidth ? 'width: 100%;' : '')} &.dash-size-single {
    ${ItemInfoIcon} {
      margin-right: 8px;
      img.avatar {
        width: 16px;
        height: 16px;
        border-radius: 10px;
      }
    }
    ${ItemInfoTitle} {
      font-size: 13px;
    }
    ${ItemInfoSubTitle} {
      font-size: 0;
    }
  }

  &.dash-size-small {
    ${ItemInfoIcon} {
      margin-right: 8px;
      img.avatar {
        width: 28px;
        height: 28px;
        border-radius: 10px;
      }
    }
    ${ItemInfoTitle} {
      font-size: 13px;
    }
    ${ItemInfoSubTitle} {
      font-size: 12px;
    }
  }

  &.dash-size-regular {
    ${ItemInfoIcon} {
      margin-right: 16px;
      img.avatar {
        width: 32px;
        height: 32px;
        border-radius: 10px;
      }
    }
    ${ItemInfoTitle} {
      font-size: 14px;
    }
    ${ItemInfoSubTitle} {
      font-size: 12px;
    }
  }

  &.dash-size-large {
    ${ItemInfoIcon} {
      margin-right: 20px;
      img.avatar {
        width: 48px;
        height: 48px;
        border-radius: 10px;
      }
    }
    ${ItemInfoTitle} {
      font-size: 20px;
    }
    ${ItemInfoSubTitle} {
      font-size: 14px;
    }
  }
`;
ItemInfoContainer.displayName = 'ItemInfoContainer';
ItemInfoContainer.defaultProps = { theme };

export const ItemInfoContent = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  padding-right: 8px;
  align-self: center;
`;
ItemInfoContent.displayName = 'ItemInfoContent';

export const ActionSection = styled(Flex)`
  flex: 1;
  align-self: ${(props) => props.alignSelf || 'center'};
  margin-left: auto;
  justify-content: flex-end;
`;
ActionSection.displayName = 'ActionSection';

export const ItemInfoActionItem = styled.div`
  display: flex;
  margin-left: 24px;
  align-self: center;
  a {
    display: flex;
  }
`;
ItemInfoActionItem.displayName = 'ItemInfoActionItem';

export default ItemInfo;
