import { call, put, takeLeading } from '@redux-saga/core/effects';
import { select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { JobState, PackageBuildDetailFieldsFragment, Platform } from 'src/graphql/generated';
import { Artifact, BuildState, GenericBuild, PagerInfo, GenericBuildStore, GenericBuildData } from 'src/interfaces';
import utils from 'src/redux/reducks/utils';
import { isApiErrorResponse } from 'src/utils';
import * as BaseDuck from './BaseDuck';
import { SagaDuck } from './SagaDuck';

export interface LoadGenericBuildsPayload {
  appId: string;
  platform_type?: Platform;
  automation_id?: number;
  state?: BuildState;
  deployable?: boolean;
  pagerInfo: PagerInfo;
  fromModal?: boolean;
}

export interface GetGenericBuildPayload {
  appId: string;
  jobId: number;
}

export interface CancelGenericBuildPayload {
  appId: string;
  jobId: number;
  reloadGenericBuilds?: boolean;
  reloadGenericBuild?: boolean;
  automationId?: number;
}

interface GenericBuildPagination {
  pagerInfo: PagerInfo;
  total?: number;
}

export const JobFinalStates = [JobState.Failed, JobState.Success, JobState.Canceled];

export const BuildFinalStates = [BuildState.Failed, BuildState.Success, BuildState.Canceled];

export function successRunningDeploysExtract(build?: GenericBuildData) {
  const defaultResult = {
    successfulDeploymentCredentialId: undefined,
    runningDeployment: false,
  };
  if (!build) {
    return defaultResult;
  }

  const deployedBuild = build.data.deployments.edges.filter((x) => x?.node?.status === JobState.Success).pop()?.node?.build as
    | PackageBuildDetailFieldsFragment
    | undefined;

  if (deployedBuild?.distributionCredential?.id) {
    return {
      successfulDeploymentCredentialId: deployedBuild.distributionCredential.id,
      runningDeployment: false,
    };
  }

  if (build.data.deployments.edges.some((d) => d?.node?.status && !JobFinalStates.includes(d.node.status))) {
    return {
      successfulDeploymentCredentialId: undefined,
      runningDeployment: true,
    };
  }

  return defaultResult;
}

export type LoadGenericBuilds = BaseDuck.Action<'LOAD_GENERIC_BUILDS', LoadGenericBuildsPayload>;
export type ReceiveGenericBuilds = BaseDuck.Action<'RECEIVE_GENERIC_BUILDS', GenericBuild[]>;
export type ReceiveGenericBuildsPagination = BaseDuck.Action<'RECEIVE_GENERIC_BUILDS_PAGINATION', GenericBuildPagination>;
export type ReceiveGenericBuildsModal = BaseDuck.Action<'RECEIVE_GENERIC_BUILDS_MODAL', GenericBuild[]>;
export type ReceiveGenericBuildsPaginationModal = BaseDuck.Action<'RECEIVE_GENERIC_BUILDS_PAGINATION_MODAL', GenericBuildPagination>;
export type GetGenericBuild = BaseDuck.Action<'GET_GENERIC_BUILD', GetGenericBuildPayload>;
export type ReceiveGenericBuild = BaseDuck.Action<'RECEIVE_GENERIC_BUILD', GenericBuild>;
export type DownloadArtifact = BaseDuck.Action<'DOWNLOAD_ARTIFACT', Artifact>;
export type ClearGenericBuildsStore = BaseDuck.Action<'CLEAR_GENERIC_BUILDS_STORE', undefined>;
export type CancelGenericBuild = BaseDuck.Action<'CANCEL_GENERIC_BUILD', CancelGenericBuildPayload>;

export type GenericBuildsActions = {
  loadGenericBuilds: LoadGenericBuilds;
  getGenericBuild: GetGenericBuild;
  receiveGenericBuilds: ReceiveGenericBuilds;
  receiveGenericBuild: ReceiveGenericBuild;
  receiveGenericBuildsPagination: ReceiveGenericBuildsPagination;
  receiveGenericBuildsModal: ReceiveGenericBuildsModal;
  receiveGenericBuildsPaginationModal: ReceiveGenericBuildsPaginationModal;
  downloadArtifact: DownloadArtifact;
  clearGenericBuildsStore: ClearGenericBuildsStore;
  cancelGenericBuild: CancelGenericBuild;
};

export type AllActions = BaseDuck.AllActions<GenericBuildsActions>;

export class GenericBuildsDuck extends SagaDuck<'genericBuilds', GenericBuildsActions> {
  actions = {
    loadGenericBuilds: utils.actionMaker<LoadGenericBuilds>('LOAD_GENERIC_BUILDS'),
    getGenericBuild: utils.actionMaker<GetGenericBuild>('GET_GENERIC_BUILD'),
    receiveGenericBuilds: utils.actionMaker<ReceiveGenericBuilds>('RECEIVE_GENERIC_BUILDS'),
    receiveGenericBuild: utils.actionMaker<ReceiveGenericBuild>('RECEIVE_GENERIC_BUILD'),
    receiveGenericBuildsPagination: utils.actionMaker<ReceiveGenericBuildsPagination>('RECEIVE_GENERIC_BUILDS_PAGINATION'),
    receiveGenericBuildsModal: utils.actionMaker<ReceiveGenericBuildsModal>('RECEIVE_GENERIC_BUILDS_MODAL'),
    receiveGenericBuildsPaginationModal: utils.actionMaker<ReceiveGenericBuildsPaginationModal>('RECEIVE_GENERIC_BUILDS_PAGINATION_MODAL'),
    downloadArtifact: utils.actionMaker<DownloadArtifact>('DOWNLOAD_ARTIFACT'),
    clearGenericBuildsStore: utils.actionMaker<ClearGenericBuildsStore>('CLEAR_GENERIC_BUILDS_STORE'),
    cancelGenericBuild: utils.actionMaker<CancelGenericBuild>('CANCEL_GENERIC_BUILD'),
  };

  *mainSaga() {
    yield this.forkAndListen(takeLeading(this.actions.loadGenericBuilds.type, this.loadGenericBuilds.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.getGenericBuild.type, this.getGenericBuild.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.downloadArtifact.type, this.downloadArtifact.bind(this)));
    yield this.forkAndListen(takeLeading(this.actions.cancelGenericBuild.type, this.cancelGenericBuild.bind(this)));
  }

  *loadGenericBuilds(action: LoadGenericBuilds) {
    const { appId, pagerInfo, fromModal, ...requestPayload } = action.payload;
    const resp: ApiResponse<any> = yield call(
      this.sagaCallApi,
      action.type,
      {
        endpoint: `/apps/${appId}/builds`,
        method: 'GET',
        body: requestPayload,
        pagerInfo: pagerInfo,
      },
      yield select(this.getAuthToken)
    );
    if (isApiErrorResponse(resp)) {
      // handles error codes from api
      return;
    }
    if (fromModal) {
      yield put(this.actions.receiveGenericBuildsModal(resp.data));
      yield put(this.actions.receiveGenericBuildsPaginationModal({ pagerInfo: pagerInfo, total: resp.meta.total }));
    } else {
      yield put(this.actions.receiveGenericBuilds(resp.data));
      yield put(this.actions.receiveGenericBuildsPagination({ pagerInfo: pagerInfo, total: resp.meta.total }));
    }
    return resp.data;
  }

  *getGenericBuild(action: GetGenericBuild) {
    const { appId, jobId } = action.payload;
    const resp: ApiResponse<any> = yield call(
      this.sagaCallApi,
      action.type,
      {
        endpoint: `/apps/${appId}/builds/${jobId}`,
        method: 'GET',
      },
      yield select(this.getAuthToken)
    );
    if (isApiErrorResponse(resp)) {
      // handles error codes from api
      return;
    }
    yield put(this.actions.receiveGenericBuild(resp.data));
    return resp.data;
  }

  *downloadArtifact(action: DownloadArtifact) {
    const resp: ApiResponse<any> = yield call(
      this.sagaCallApi,
      action.type,
      {
        endpoint: action.payload.url,
        method: 'GET',
      },
      yield select(this.getAuthToken)
    );
    if (isApiErrorResponse(resp)) {
      // handles error codes from api
      return;
    }
    window.location = resp.data.url;
  }

  *cancelGenericBuild(action: CancelGenericBuild) {
    const { appId, jobId, reloadGenericBuilds, reloadGenericBuild, automationId } = action.payload;
    const resp: ApiResponse<any> = yield call(
      this.sagaCallApi,
      action.type,
      {
        endpoint: `/apps/${appId}/builds/${jobId}`,
        method: 'DELETE',
      },
      yield select(this.getAuthToken)
    );
    if (isApiErrorResponse(resp)) {
      // handles error codes from api
      return;
    }
    if (reloadGenericBuilds) {
      yield call(
        [this, this.loadGenericBuilds],
        this.actions.loadGenericBuilds({
          appId: action.payload.appId,
          pagerInfo: { page: 1, pageSize: 25 },
        })
      );
    }
    if (reloadGenericBuild) {
      yield call(
        [this, this.getGenericBuild],
        this.actions.getGenericBuild({
          appId: action.payload.appId,
          jobId: jobId,
        })
      );
    }
    if (automationId) {
      yield call(
        [this, this.loadGenericBuilds],
        this.actions.loadGenericBuilds({
          appId: action.payload.appId,
          automation_id: automationId,
          pagerInfo: { page: 1, pageSize: 10 },
          fromModal: true,
        })
      );
    }
    return resp.data;
  }

  getNewPagerInfo(pagerInfo: PagerInfo, total: number = 0) {
    return {
      ...pagerInfo,
      total: total,
      totalPages: Math.ceil(total / pagerInfo.pageSize),
    };
  }

  selectors = {
    getGenericBuildsState: this.mainSelect,
    getGenericBuilds: createSelector(this.mainSelect, (genericBuildsState) => genericBuildsState.genericBuilds),
    getGenericBuild: createSelector(this.mainSelect, (genericBuildsState) => genericBuildsState.genericBuild),
    getGenericBuildsPagerInfo: createSelector(this.mainSelect, (genericBuildsState) => genericBuildsState.genericBuildsPagerInfo),
    getGenericBuildsModal: createSelector(this.mainSelect, (genericBuildsState) => genericBuildsState.genericBuildsModal),
    getGenericBuildsPagerInfoModal: createSelector(this.mainSelect, (genericBuildsState) => genericBuildsState.genericBuildsPagerInfoModal),
  };

  reducer(state: GenericBuildStore = this.initialState, action: AllActions): GenericBuildStore {
    switch (action.type) {
      case this.actions.receiveGenericBuilds.type:
        return { ...state, genericBuilds: action.payload };
      case this.actions.receiveGenericBuildsModal.type:
        return { ...state, genericBuildsModal: action.payload };
      case this.actions.receiveGenericBuild.type:
        return { ...state, genericBuild: action.payload };
      case this.actions.receiveGenericBuildsPagination.type:
        return { ...state, genericBuildsPagerInfo: this.getNewPagerInfo(action.payload.pagerInfo, action.payload.total) };
      case this.actions.receiveGenericBuildsPaginationModal.type:
        return { ...state, genericBuildsPagerInfoModal: this.getNewPagerInfo(action.payload.pagerInfo, action.payload.total) };
      case this.actions.clearGenericBuildsStore.type:
        return {
          ...state,
          genericBuilds: undefined,
          genericBuild: undefined,
          genericBuildsPagerInfo: undefined,
          genericBuildsModal: undefined,
          genericBuildsPagerInfoModal: undefined,
        };
      default:
        return state;
    }
  }
}

export const genericBuilds = new GenericBuildsDuck({}, 'genericBuilds');
