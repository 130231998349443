import styled from 'styled-components';
import { theme } from 'src/styled';

interface DropdownItemProps {
  color?: 'danger' | 'primary';
  onClick?: Function;
}

export const DropdownItem = styled.li<DropdownItemProps>`
  ${(props) => {
    if (props.color === 'danger') {
      return `
        color: ${props.theme!.colors.red[100]} !important;
        :hover {
          color: ${props.theme!.colors.red[80]} !important;
        }
      `;
    } else if (props.color === 'primary') {
      return `
        color: ${props.theme!.colors.blue[100]} !important;
        :hover {
          color: ${props.theme!.colors.gray[40]} !important;
        }
      `;
    }
    return `color: ${props.theme!.colors.gray[100]}`;
  }};
  font-size: ${(props) => props.theme.fontSizes[14]};
  padding: 8px 18px 8px 18px;
  text-overflow: ellipsis;
  overflow: hidden;

  &:first-child {
    padding-top: 20px;
  }
  &:last-of-type {
    padding-bottom: 20px;
  }
  ${(props) => {
    if (props.onClick) {
      return `
        cursor: pointer;
        transition: color .15s ease-in;
      `;
    }
    return '';
  }};
`;
DropdownItem.displayName = 'DropdownItem';
DropdownItem.defaultProps = {
  theme: theme,
};
