import { GitHost } from 'src/utils';

export const KEYS = {
  appflowSubscription: (orgId?: string) => ['customer', orgId, 'appflowSubscription'],
  appflowStripeSubscription: (orgId?: string) => ['customer', orgId, 'appflowStripeSubscription'],
  customer: (orgId?: string) => ['customer', orgId],
  invoices: (orgId?: string) => ['customer', orgId, 'invoices'],
  studioSubscription: (orgId?: string) => ['customer', orgId, 'studioSubscription'],
  studioStripeSubscription: (orgId?: string) => ['customer', orgId, 'studioStripeSubscription'],
  subscriptions: (orgId?: string) => ['customer', orgId, 'subscriptions'],
  automations: (appId: string, automationId?: number) => ['automations', appId, automationId],
  environments: (appId: string) => ['environments', appId],
  nativeConfigs: (appId: string, nativeConfigId?: number) => ['nativeConfigs', appId, nativeConfigId],
  repos: (userId: number | undefined, origin: GitHost, owner: string | undefined, searchQuery: string, page: string) => [
    'repos',
    userId,
    origin,
    owner,
    searchQuery,
    page,
  ],
};
