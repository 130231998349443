import * as NProgress from 'nprogress';
import { select, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { ApiStore, StateStore } from 'src/interfaces/StateStore';
import utils from 'src/redux/reducks/utils';
import * as BaseDuck from './BaseDuck';

NProgress.configure({ showSpinner: false });

export interface ApiRequestInfo {
  /**
   * The name of the original action that triggered the request;
   */
  originalAction: string;
  /**
   * The type of the request REQUEST == new request fired, ERROR == error response from api, SUCCESS == request returned sucessfully
   */
  type: 'REQUEST' | 'ERROR' | 'SUCCESS';
}

export interface ApiSuccessResponse extends ApiRequestInfo {
  type: 'SUCCESS';
}

export interface ApiRequestStarted extends ApiRequestInfo {
  type: 'REQUEST';
  /**
   * Whether the request should trigger the loading/progress indicator.
   */
  quiet?: boolean;
}

export interface ApiErrorResponse extends ApiRequestInfo {
  type: 'ERROR';
  /**
   * An ApiError for the request
   */
  error: ApiError;
}

export type StoreApiRequestMetadata = BaseDuck.Action<
  'STORE_API_REQUEST_METADATA',
  ApiRequestStarted | ApiErrorResponse | ApiSuccessResponse
>;
export type ClearApiMetadata = BaseDuck.Action<'CLEAR_API_METADATA', string>;
export type CallApi = BaseDuck.Action<'SAGA_CALL_API', { options: ApiFetchOptions; token?: string }>;
export type ApiRequestsActions = {
  storeApiRequestMetadata: StoreApiRequestMetadata;
  clearApiMetadata: ClearApiMetadata;
};

export type ActionList = BaseDuck.AllActions<ApiRequestsActions>;

export class ApiRequestsDuck extends BaseDuck.BaseDuck<'api', ApiRequestsActions> {
  actions = {
    storeApiRequestMetadata: utils.actionMaker<StoreApiRequestMetadata>('STORE_API_REQUEST_METADATA'),
    clearApiMetadata: utils.actionMaker<ClearApiMetadata>('CLEAR_API_METADATA'),
  };

  *mainSaga() {
    yield takeEvery(this.actions.storeApiRequestMetadata.type, this.updateActiveRequestCount.bind(this));
  }

  reducer(state: ApiStore = this.initialState, action: ActionList): ApiStore {
    switch (action.type) {
      case this.actions.clearApiMetadata.type: {
        const { errors, successfulRequests } = state;
        errors.delete(action.payload);
        successfulRequests.delete(action.payload);
        const updatedErrors = new Map(errors);
        const updatedSuccessfulRequests = new Set(successfulRequests);
        return { ...state, errors: updatedErrors, successfulRequests: updatedSuccessfulRequests };
      }
      case this.actions.storeApiRequestMetadata.type: {
        const { activeRequests, errors, successfulRequests } = state;
        const { originalAction } = action.payload;
        switch (action.payload.type) {
          case 'REQUEST': {
            const previousActiveRequestCount = activeRequests.size;
            if (!action.payload.quiet) {
              activeRequests.add(originalAction);
            }
            // Always clear the last error for an action. Not sure if that's what we want but can change later.
            errors.delete(originalAction);
            successfulRequests.delete(originalAction);
            const updatedActiveRequests = new Set(activeRequests);
            const updatedErrors = new Map(errors);
            const updatedSuccessRequests = new Set(successfulRequests);
            return {
              activeRequests: updatedActiveRequests,
              errors: updatedErrors,
              previousActiveRequestCount,
              successfulRequests: updatedSuccessRequests,
            };
          }
          case 'ERROR': {
            const { error } = action.payload;
            const previousActiveRequestCount = activeRequests.size;
            activeRequests.delete(originalAction);
            successfulRequests.delete(originalAction);
            errors.set(originalAction, error);
            const updatedActiveRequests = new Set(activeRequests);
            const updatedErrors = new Map(errors);
            const updatedSuccessRequests = new Set(successfulRequests);
            return {
              activeRequests: updatedActiveRequests,
              errors: updatedErrors,
              previousActiveRequestCount,
              successfulRequests: updatedSuccessRequests,
            };
          }
          case 'SUCCESS': {
            const previousActiveRequestCount = activeRequests.size;
            activeRequests.delete(originalAction);
            successfulRequests.add(originalAction);
            errors.delete(originalAction);
            const updatedActiveRequests = new Set(activeRequests);
            const updatedErrors = new Map(errors);
            const updatedSuccessRequests = new Set(successfulRequests);
            return {
              activeRequests: updatedActiveRequests,
              errors: updatedErrors,
              previousActiveRequestCount,
              successfulRequests: updatedSuccessRequests,
            };
          }
        }
        return state;
      }
      default:
        return state;
    }
  }

  selectors = {
    getApiState: this.mainSelect,
    getApiErrors: createSelector(this.mainSelect, (api) => api.errors),
    getActiveApiRequests: createSelector(this.mainSelect, (api) => api.activeRequests),
    getApiRequestIsActive: (state: StateStore, actionType: string) => state.api.activeRequests.has(actionType),
    getApiError: (state: StateStore, actionType: string) => state.api.errors.get(actionType),
  };

  private *updateActiveRequestCount() {
    const store: StateStore = yield select();
    const { activeRequests, previousActiveRequestCount } = store.api;
    if (activeRequests.size === 0) {
      NProgress.done();
    } else if (previousActiveRequestCount === 0 && activeRequests.size > 0) {
      NProgress.start();
    } else if (previousActiveRequestCount > activeRequests.size) {
      NProgress.inc();
    }
  }
}

export const apiRequests = new ApiRequestsDuck(
  {
    activeRequests: new Set(),
    successfulRequests: new Set(),
    errors: new Map(),
    previousActiveRequestCount: 0,
  },
  'api'
);
