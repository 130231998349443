import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { StateStore } from 'src/interfaces/StateStore';
import rootReducer from 'src/redux/reducers';
import { mainSaga } from 'src/redux/reducks';

const saga = createSagaMiddleware();

const configureStore = (preLoadedState?: Partial<StateStore>) => {
  const store = createStore(rootReducer, preLoadedState, composeWithDevTools(applyMiddleware(thunk, saga)));

  saga.run(mainSaga);
  return store;
};

export default configureStore;
