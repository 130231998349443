import { Credentials } from 'src/interfaces/Credentials';
import { deleteCookie } from '.';

interface KeyManager {
  get: () => string | undefined;
  set: (val: string) => void;
  delete: () => void;
}

export interface WelcomeProgress {
  selectedFlow: 'none' | 'first-app' | 'existing-app';
  currentStep: number;
}

class IonicBrowserStorage {
  private overwriteTokenKey = '_ionic_overwrite_token';
  private tokenKey = '_ionic_token';
  private overwriteUserIdKey = '_ionic_overwrite_user_id';
  private userIdKey = '_ionic_user_id';
  private overwriteTimeKey = '_ionic_overwrite_time';
  private recentAppsKey = 'recentApps';
  private welcomeProgressKey = 'welcomeProgress';

  overwriteToken: KeyManager;
  token: KeyManager;
  overwriteUserId: KeyManager;
  userId: KeyManager;
  overwriteTime: KeyManager;
  recentApps: KeyManager;
  welcomeProgress: KeyManager;

  constructor() {
    this.overwriteUserId = this.makeKeyManager(this.overwriteUserIdKey);
    this.userId = this.makeKeyManager(this.userIdKey, true);
    this.overwriteToken = this.makeKeyManager(this.overwriteTokenKey);
    this.token = this.makeKeyManager(this.tokenKey, true);
    this.recentApps = this.makeKeyManager(this.recentAppsKey);
    this.welcomeProgress = this.makeKeyManager(this.welcomeProgressKey);
    this.overwriteTime = this.makeKeyManager(this.overwriteTimeKey);
  }

  logout() {
    window.localStorage.clear();
    deleteCookie(this.tokenKey);
    deleteCookie(this.userIdKey);
  }

  restoreCredentials(): Credentials | undefined {
    const token = this.overwriteToken.get() || this.token.get();
    const userId = this.overwriteUserId.get() || this.userId.get();
    if (userId && token) {
      return { userId, token };
    }
    this.logout();
  }

  getWelcomeProgress(): Partial<WelcomeProgress> {
    return JSON.parse(this.welcomeProgress.get() || '{}');
  }

  updateWelcomeProgress = (welcomeProgress: Partial<WelcomeProgress>) => {
    this.welcomeProgress.set(JSON.stringify(welcomeProgress));
  };

  addAppToRecentList(appId: string) {
    let recentApps: string[] = JSON.parse(this.recentApps.get() || '[]');
    recentApps = recentApps.filter((x) => x !== appId);
    recentApps.push(appId);
    this.recentApps.set(JSON.stringify(recentApps));
  }

  removeAppFromRecentList(appId: string) {
    let recentApps: string[] = JSON.parse(this.recentApps.get() || '[]');
    recentApps = recentApps.filter((x) => x !== appId);
    this.recentApps.set(JSON.stringify(recentApps));
  }

  getRecentApps(): string[] {
    return JSON.parse(this.recentApps.get() || '[]');
  }

  private makeKeyManager(key: string, syncCookie: boolean = false): KeyManager {
    return {
      get: () => localStorage.getItem(key) || undefined,
      set: (val: string) => localStorage.setItem(key, val),
      delete: () => {
        localStorage.removeItem(key);
        if (syncCookie) deleteCookie(key);
      },
    };
  }
}

export const BrowserStorage = new IonicBrowserStorage();
