import React from 'react';
import styled from 'styled-components';
import { Icon, Toolbar } from 'src/components';

interface SearchLogoProps {
  isOpen?: boolean;
  title?: string;
  handleLogoClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const SearchLogo: React.FC<SearchLogoProps> = ({ handleLogoClick, title, isOpen }) => {
  return (
    <a title={title} onClick={handleLogoClick}>
      {isOpen ? (
        <Icon icon={Toolbar.back} className="hover" highlightBackground="dark" />
      ) : (
        <Icon icon={Toolbar.search} highlightBackground="dark" />
      )}
    </a>
  );
};

SearchLogo.displayName = 'SearchLogo';

export default styled(SearchLogo)`
  height: 32px;
  width: 32px;
  margin-top: -4px;
  display: block;
`;
