import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { notificationsOutline, PortalModal, Tooltip } from 'src/components';
import CompatModal from 'src/components/portal-modal/CompatModal';
import { UserFeedbackModal } from 'src/features/_dashboard/pages/user-feedback-modal/UserFeedbackModal';
import { StateStore, User } from 'src/interfaces';
import { DuckSelectors } from 'src/redux/reducks';
import AccountDropdown from '../account-dropdown/AccountDropdown';
import HelpDropdown from '../help-dropdown/HelpDropdown';
import SideMenuIcon from '../side-menu-icon/SideMenuIcon';

interface SidebarBottomMenuProps extends ConnectedProps<typeof connector> {
  user: User;
}

export const SidebarBottomMenu: React.FC<SidebarBottomMenuProps> = ({ user }) => {
  const [helpDropDownOpen, setHelpDropDownOpen] = useState<boolean>(false);
  const [accountDropDownOpen, setAccountDropDownOpen] = useState<boolean>(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
  const location = useLocation();

  const openFeedbackModal = () => {
    setShowFeedbackModal(true);
  };

  const toggleHelpDropDown = (value: boolean) => {
    setHelpDropDownOpen(value);
  };

  const toggleAccountDropDown = (value: boolean) => {
    setAccountDropDownOpen(value);
  };

  return (
    <SidebarBottomMenuContainer>
      <ul className="bottom-menu">
        <li>
          <Tooltip text="Updates" position="right" spacing={6}>
            <SideMenuIcon className="beamerTrigger" icon={notificationsOutline} />
          </Tooltip>
        </li>
        <li>
          <Tooltip text="Help" position="right" spacing={6} hide={helpDropDownOpen}>
            <HelpDropdown onDropDownOpen={toggleHelpDropDown} />
          </Tooltip>
        </li>
        <li>
          <Tooltip text="Account" position="right" spacing={6} hide={accountDropDownOpen}>
            <AccountDropdown user={user} openFeedbackModal={openFeedbackModal} onDropDownOpen={toggleAccountDropDown} />
          </Tooltip>
        </li>
      </ul>
      {showFeedbackModal && (
        <PortalModal size="medium">
          <CompatModal size="md" close={() => setShowFeedbackModal(false)}>
            <UserFeedbackModal location={location} closeModals={() => setShowFeedbackModal(false)} />
          </CompatModal>
        </PortalModal>
      )}
    </SidebarBottomMenuContainer>
  );
};

const SidebarBottomMenuContainer = styled.div`
  ul.bottom-menu {
    padding: 0;
    margin: 0;
    line-height: normal;
    display: flex;
    flex-direction: column;
    list-style: none;
    & > li {
      margin: 4px 0;
      align-self: center;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const mapStateToProps = (state: StateStore) => {
  const user = DuckSelectors.getUser(state);

  return {
    user,
  };
};

const connector = connect(mapStateToProps, {});

export default connector(SidebarBottomMenu);
