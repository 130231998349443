import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useGetAppsForSearchQuery } from 'src/graphql/generated-hooks';
import SearchApps from '../search-apps/SearchApps';

interface App {
  id: string;
  name: string;
  icon: string;
}

interface SearchContainerProps extends SearchContainerLoaderProps {
  searchApps: () => void;
  apps: App[];
  navigate: (path: string) => void;
  query: string;
  setQuery: (query: string) => void;
  showSearch: boolean;
  setShowSearch: (showSearch: boolean) => void;
}

export const SearchContainer: React.FC<SearchContainerProps> = ({
  searchApps,
  apps,
  navigate,
  query,
  setQuery,
  showSearch,
  setShowSearch,
}) => {
  useEffect(() => {
    window.addEventListener('click', closeSearch);
    document.addEventListener('keydown', handleKeyDown);

    function cleanup() {
      window.removeEventListener('click', closeSearch);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return cleanup;
  });

  function closeSearch() {
    if (showSearch) {
      setShowSearch(false);
    }
  }

  function handleNavigateToApp(app: App) {
    navigate(`/app/${app.id}`);
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Divide' && event.target instanceof Element && event.target.tagName !== 'INPUT') {
      setTimeout(() => setShowSearch(true), 200);
    }
  }

  return (
    <SearchContainerWrapper>
      <SearchApps
        searchApps={searchApps}
        apps={apps}
        isOpen={showSearch}
        onCloseSearch={() => setShowSearch(false)}
        onNavigateToApp={handleNavigateToApp}
        query={query}
        setQuery={setQuery}
      />
      {showSearch && <SearchAppsBackground onClick={() => setShowSearch(false)} />}
    </SearchContainerWrapper>
  );
};

const SearchAppsBackground = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  background-color: rgba(0, 8, 32, 0.2);
  opacity: 0;
  animation: search-background-fade-in 0.15s forwards;

  @keyframes search-background-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const SearchContainerWrapper = styled.div`
  height: 100%;
`;

interface SearchContainerLoaderProps {
  showSearch: boolean;
  setShowSearch: (showSearch: boolean) => void;
}

const SearchContainerLoader: React.FC<SearchContainerLoaderProps> = (props) => {
  const navigate = useNavigate();

  const [query, setQuery] = useState<string>('');
  const { data: apps, refetch } = useGetAppsForSearchQuery(
    { nameLike: query },
    { select: (data) => data.apps.edges.map((edge) => edge.node), enabled: false }
  );

  const searchApps = () => {
    refetch();
  };

  return (
    <SearchContainer
      apps={apps || []}
      searchApps={searchApps}
      navigate={navigate}
      query={query}
      setQuery={(query: string) => setQuery(query)}
      showSearch={props.showSearch}
      setShowSearch={props.setShowSearch}
    />
  );
};

export default SearchContainerLoader;
