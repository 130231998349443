export const getCookies = (cookieString: string) => {
  const split = cookieString.split(';');
  let cookieObj: { [id: string]: string } = {};
  split.forEach((e) => {
    const innerSplit = e.split('=');
    if (innerSplit[0] && innerSplit[1]) {
      cookieObj[innerSplit[0].trim()] = innerSplit[1].trim();
    }
  });
  return cookieObj;
};
