import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useBootstrapAppQuery } from 'src/graphql/generated-hooks';
import { DuckSelectors } from 'src/redux/reducks';

const _oldestOrg = (o1: Org, o2: Org): number => {
  return new Date(o1.created).getTime() - new Date(o2.created).getTime();
};
const _hasMostApps = (o1: Org, o2: Org): number => {
  return o2.apps.totalCount - o1.apps.totalCount;
};
const _prioritizePaidPlans = (o1: Org, o2: Org): number => {
  const planWeight = ['studio', 'business', 'teams', 'developer', 'kickstarter', 'free'];
  const o1weight = planWeight.indexOf(o1.plan);
  const o2weight = planWeight.indexOf(o2.plan);
  return o1weight - o2weight;
};

type Org = {
  id: string;
  avatar?: string | undefined | null;
  created: string;
  name: string;
  plan: string;
  memberTotal: number;
  slug: string;
  apps: {
    totalCount: number;
  };
};

// This pattern allows you to discriminate types as long as the consumer waits for isLoading to be false.
export type BootstrapContextLoaded = {
  isLoading: false;
  defaultOrg: Org;
  defaultRoute: string;
  canSwitchOrgs: boolean;
  orgs: Org[];
};
export type BootstrapContext =
  | {
      isLoading: true;
      defaultOrg: null;
      defaultRoute: null;
      canSwitchOrgs: boolean;
      orgs: null;
    }
  | BootstrapContextLoaded;

const bootstrapContext = createContext<BootstrapContext>({
  isLoading: true,
  defaultOrg: null,
  defaultRoute: null,
  canSwitchOrgs: false,
  orgs: null,
});

const BootstrapProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { data: bootstrapQuery, isLoading: isLoadingBootstrapQuery } = useBootstrapAppQuery();
  const userGeneralPermissions = useSelector(DuckSelectors.getUserGeneralPermissions);
  const bootInfo = useSelector(DuckSelectors.getBootInfo);
  const isLoading = !userGeneralPermissions || isLoadingBootstrapQuery || !bootstrapQuery || !bootInfo.isAuthed;

  if (isLoading) {
    const context: BootstrapContext = {
      isLoading,
      defaultOrg: null,
      defaultRoute: null,
      canSwitchOrgs: false,
      orgs: null,
    };

    return <bootstrapContext.Provider value={context}>{children}</bootstrapContext.Provider>;
  }
  const orgs = (bootstrapQuery?.viewer.organizations.edges.map((e) => e.node) ?? [])
    .sort(_oldestOrg)
    .sort(_hasMostApps)
    .sort(_prioritizePaidPlans as any); // eslint-disable-line @typescript-eslint/no-explicit-any -- orgs graphql resolver has a bug -- "plan" should be a required field
  const defaultOrg = orgs[0]; // most paid org > most used org > oldest org

  const isSSOUser = !!userGeneralPermissions?.isSso;

  const defaultRoute = `/org/${defaultOrg.slug}`;

  const canSwitchOrgs = (() => {
    if (isSSOUser) {
      return false;
    }

    const mightHaveStudio = (bootstrapQuery?.viewer.subscriptions || []).length > 1;
    const multipleOrgsInUseOrPaid =
      orgs.filter((o) => {
        const isPaid = !['free', 'kickstarter'].includes(o.plan!);
        const inUse = o.apps.totalCount > 0;
        return isPaid || inUse;
      }).length > 1;

    return mightHaveStudio || multipleOrgsInUseOrPaid;
  })();

  const context: BootstrapContext = {
    isLoading,
    defaultOrg: defaultOrg as any, // eslint-disable-line @typescript-eslint/no-explicit-any -- orgs graphql resolver has a bug -- "plan" should be a required field
    defaultRoute,
    canSwitchOrgs,
    orgs: orgs as any, // eslint-disable-line @typescript-eslint/no-explicit-any -- orgs graphql resolver has a bug -- "plan" should be a required field
  };

  return <bootstrapContext.Provider value={context}>{children}</bootstrapContext.Provider>;
};

function useBootstrapContext() {
  return useContext(bootstrapContext);
}

export { BootstrapProvider, bootstrapContext, useBootstrapContext };
