import React from 'react';
import styled from 'styled-components';

interface ListItemProps {
  title?: JSX.Element | string;
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  className?: string;
  clickable?: boolean;
  children?: React.ReactNode;
}

export class ListItem extends React.PureComponent<ListItemProps> {
  render() {
    return (
      <StyledListItem className={this.props.className} clickable={this.props.clickable} onClick={this.props.onClick}>
        {this.props.children}
      </StyledListItem>
    );
  }
}

export default ListItem;

export const StyledListItem = styled.li`
  padding: 16px;
  border-color: transparent;
  margin-bottom: 0;
  border-top: 1px solid ${(props) => props.theme.colors.indigo[30]};
  list-style: none;
  display: flex;
  width: 100%;
  transition: box-shadow 0.15s ease-out;
  ${(props: { clickable?: boolean }) => {
    if (props.clickable) {
      return `
        cursor: pointer;
        &:hover {
          box-shadow: 0 4px 10px rgba(0,0,0,.08), 0 1px 3px rgba(0,0,0,.04);
        }
      `;
    }
    return '';
  }} &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.colors.indigo[30]};
  }

  &:hover {
    .short-sha a {
      color: #0055eb !important;
      transition: 0.15s ease;
      border-bottom: 1px dotted rgba(9, 85, 235, 0.5);
    }
  }
`;
StyledListItem.displayName = 'StyledListItem';
