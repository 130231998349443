import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IonIcon, close as closeIcon } from '../icon';

// We currently have "legacy" modals that were original designed to be
// used with Redux via the `openModals` or `showConfirm` action, and we have "modern"
// modals that use React portals via the `PortalModal` component.
// The legacy modals rely on a `ModalAnchor` component that implements
// these styles and does some extra stuff that we don't want with portal modals.
// This component implements those styles to make migrating to portals easier.
const CompatModal = ({
  fullscreen = false,
  canClose = true,
  size,
  customStyle,
  customClass,
  headerNotificationsShown,
  children,
  close,
}: {
  fullscreen?: boolean;
  canClose?: boolean;
  size: 'sm' | 'md' | 'lg';
  customStyle?: React.CSSProperties;
  customClass?: string;
  headerNotificationsShown?: number;
  children?: React.ReactNode;
  close: (e: React.MouseEvent | KeyboardEvent) => void;
}) => {
  const modalSizeClass = size && 'modal-' + size;

  let modalStyle = {};
  if (headerNotificationsShown) {
    const headerNotifications = document.querySelectorAll('.header-notification');
    let modalTop = 0;
    // when render() runs because a header is being closed, the header is still in the DOM
    // so only add top offset for the # of headers that will be visible
    // this isn't precise, since the headers could be different heights
    // and we aren't checking which one is closing, but this is easier for now
    for (let i = 0; i < headerNotifications.length; i++) {
      modalTop += headerNotifications[i].clientHeight;
    }
    modalStyle = {
      // TODO: this might need to be a transform for perf
      top: `${modalTop}px`,
    };
  }

  function keyupHandler(event: KeyboardEvent) {
    const code = event.key;
    // Escape key
    if (code === 'Escape') {
      close(event);
      event.preventDefault();
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', keyupHandler);

    function cleanup() {
      document.removeEventListener('keyup', keyupHandler);
    }

    return cleanup;
  }, []);

  return (
    <div className="modal-anchor">
      <div className={'modal-backdrop fade global-action in ' + (fullscreen ? 'fullscreen' : '')} style={modalStyle} />
      <div tabIndex={-1} role="dialog" className={'modal in ' + (fullscreen ? 'fullscreen-modal' : '')} style={modalStyle}>
        {fullscreen && canClose ? (
          <button aria-label="Close" className="close" onClick={close}>
            <span aria-hidden="true" className="ion-md-close" />
            <CloseText>ESC</CloseText>
          </button>
        ) : (
          ''
        )}
        <div className={'modal-dialog ' + (modalSizeClass || '') + (' ' + customClass || '')} style={customStyle}>
          <div className="modal-content">
            {!fullscreen && canClose ? (
              <button aria-label="Close" type="button" className="close-modal" onClick={close}>
                <IonIcon icon={closeIcon} highlightBackground="light" />
              </button>
            ) : (
              ''
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const CloseText = styled.div`
  font-size: 11px;
  color: ${(props) => props.theme.colors.carbon[60]};
  margin-top: 40px;
  letter-spacing: 0.06em;
`;

export default CompatModal;
