import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from 'src/components/loading-spinner/LoadingSpinner';

const PartialLoadingSplash = ({ className }: { className?: string }): JSX.Element => {
  return (
    <PartialLoadingSplashContainer className={className}>
      <LoadingSpinner />
    </PartialLoadingSplashContainer>
  );
};

export default PartialLoadingSplash;

const PartialLoadingSplashContainer = styled.div`
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .spinner {
    position: absolute;
    left: calc(50% - (36px / 2));
    top: calc(50% - (24px / 2));
  }
`;
