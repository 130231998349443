import React from 'react';
import styled from 'styled-components';
import { background, borders, layout, position, size, space, typography } from 'styled-system';
import { StyleProps } from './Style';

export interface DivProps extends StyleProps, React.HTMLAttributes<HTMLDivElement> {}

export const Div = styled.div<DivProps>`
  ${background}
  ${borders}
  ${layout}
  ${position}
  ${size}
  ${space}
  ${typography}
`;

Div.displayName = 'Div';

export default Div;
