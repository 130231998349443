import { Token } from '@stripe/stripe-js';
import { QueryClient, useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';
import { Organization } from 'src/graphql/generated';
import { useAuthState } from 'src/redux/selectors/useAuthState';
import { callApiThrowError, Products, ProductPlan } from 'src/utils';
import { CI_ENVIRONMENT_SLUG } from '../declarations/constants';
import { useUpdateOrgMutation } from '../graphql/generated-hooks';
import { GraphqlClientError } from '../graphql/graphql-client';
import { Invoice, StripeSubscription, Subscription, Customer } from '../interfaces';
import { DuckActions } from '../redux/reducks';
import { KEYS } from './query-keys';

export interface CreateSubscriptionPayload {
  organization: Pick<Organization, 'id' | 'name' | 'email'>;
  plan: string;
  cardInfo?: Token | null;
  metadata?: object;
  quantity?: number;
  coupon?: string;
}

export interface CancelSubscriptionPayload {
  customer: Pick<Organization, 'id'>;
  subscription: StripeSubscription;
  reason: string;
}

interface UpsertCustomerCardPayload {
  card: Token;
  customer: Pick<Organization, 'id' | 'name' | 'email'>;
}

interface upgradeToAnnualPlanPayload {
  organization: Pick<Organization, 'id'>;
  plan: ProductPlan;
}

/**
 * Invalidate all billing queries that start with ['customer', customerId...]
 */
const resetBilling = async (queryClient: QueryClient, customerId: string) => {
  await queryClient.invalidateQueries({ queryKey: KEYS.customer(customerId) });
};

export const useUpgradeToAnnualPlan = (options?: UseMutationOptions<void, GraphqlClientError, upgradeToAnnualPlanPayload>) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateOrg } = useUpdateOrgMutation();

  return useMutation<void, GraphqlClientError, upgradeToAnnualPlanPayload>(
    async ({ organization, plan }: upgradeToAnnualPlanPayload) => {
      if (plan.annualVersion) {
        await updateOrg({ input: { orgId: organization.id, plan: plan.annualVersion } });
      }
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        if (variables.plan.annualVersion) {
          await resetBilling(queryClient, variables.organization.id);
        }
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export const useUpsertCustomerCard = (options?: UseMutationOptions<Customer, ApiError, UpsertCustomerCardPayload>) => {
  const authState = useAuthState();
  const token = authState.credentials?.token;
  const queryClient = useQueryClient();

  return useMutation<Customer, ApiError, UpsertCustomerCardPayload>(
    async ({ card, customer }: UpsertCustomerCardPayload) => {
      const response = (await callApiThrowError<Customer>(
        {
          endpoint: `/billing/customers/${customer.id}/cards`,
          method: 'PUT',
          body: {
            name: customer.name,
            email: customer.email,
            card,
          },
        },
        token
      )) as ApiSuccessResponse<Customer>;
      return response.data;
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(KEYS.customer(variables.customer.id));
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export const useCancelSubscription = (options?: UseMutationOptions<undefined, ApiError, CancelSubscriptionPayload>) => {
  const authState = useAuthState();
  const queryClient = useQueryClient();
  const token = authState.credentials?.token;

  return useMutation<undefined, ApiError, CancelSubscriptionPayload>(
    async ({ customer, subscription, reason }: CancelSubscriptionPayload) => {
      const response = (await callApiThrowError<undefined>(
        {
          endpoint: `/billing/customers/${customer.id}/subscriptions/${subscription.plan.id}`,
          method: 'DELETE',
          body: {
            reason,
          },
        },
        token
      )) as ApiSuccessResponse<undefined>;
      return response.data;
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await resetBilling(queryClient, variables.customer.id);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export const useCreateSubscription = (options?: UseMutationOptions<StripeSubscription, ApiError, CreateSubscriptionPayload>) => {
  const authState = useAuthState();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const token = authState.credentials?.token;

  return useMutation<StripeSubscription, ApiError, CreateSubscriptionPayload>(
    async ({ organization, cardInfo, plan, quantity = 1, coupon }: CreateSubscriptionPayload) => {
      const response = (await callApiThrowError<StripeSubscription>(
        {
          endpoint: `/billing/customers/${organization.id}/subscriptions`,
          method: 'POST',
          body: {
            plan,
            email: organization.email,
            name: organization.name,
            card: cardInfo || null,
            quantity,
            coupon,
          },
        },
        token
      )) as ApiSuccessResponse<StripeSubscription>;

      if (CI_ENVIRONMENT_SLUG === 'production') {
        // https://support.google.com/tagmanager/answer/6107169
        dataLayer.push({
          event: 'subscription',
          transactionId: `${organization.id}-${response.data.start}`,
          transactionTotal: response.data.plan.amount / 100,
          transactionProducts: [
            {
              sku: response.data.plan.product,
              name: response.data.plan.nickname,
              price: response.data.plan.amount / 100,
              quantity: 1,
            },
          ],
        });
      }

      return response.data;
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await resetBilling(queryClient, variables.organization.id);
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
        dispatch(DuckActions.reloadAllUserInfos({}));
      },
    }
  );
};

export const useGetCurrentCustomer = (orgId?: string, options?: UseQueryOptions<Customer>) => {
  const authState = useAuthState();
  const token = authState.credentials?.token;

  return useQuery<Customer, ApiError>(
    KEYS.customer(orgId),
    async () => {
      const response = (await callApiThrowError<Customer>(
        {
          endpoint: `/billing/customers/${orgId}`,
          method: 'GET',
        },
        token
      )) as ApiSuccessResponse<Customer>;
      return response.data;
    },
    {
      ...options,
      enabled: options?.enabled !== false && !!orgId,
    }
  );
};

export const useCheckDelinquency = (orgId?: string) => {
  const flags = useFlags();
  const { data: subscription } = useGetAppflowSubscription(orgId, { enabled: flags.enforceDelinquency, staleTime: 1000 * 60 * 5 });

  if (flags.enforceDelinquency) {
    return subscription?.status === 'past_due';
  } else {
    return false;
  }
};

export const useGetInvoices = (orgId?: string, options?: UseQueryOptions<Invoice[]>) => {
  const authState = useAuthState();
  const token = authState.credentials?.token;

  return useQuery<Invoice[], ApiError>(
    KEYS.invoices(orgId),
    async () => {
      const response = (await callApiThrowError<Invoice[]>(
        {
          endpoint: `/billing/customers/${orgId}/invoices`,
          method: 'GET',
        },
        token
      )) as ApiSuccessResponse<Invoice[]>;
      return response.data;
    },
    {
      ...options,
      enabled: options?.enabled !== false && !!orgId,
    }
  );
};

export const useGetAppflowSubscription = (orgId?: string, options?: UseQueryOptions<Subscription | undefined>) => {
  const authState = useAuthState();
  const token = authState.credentials?.token;

  return useQuery<Subscription | undefined, ApiError>(
    KEYS.appflowSubscription(orgId),
    async () => {
      const response = (await callApiThrowError<Subscription[]>(
        {
          endpoint: `/billing/customers/${orgId}/subscriptions`,
          method: 'GET',
        },
        token
      )) as ApiSuccessResponse<Subscription[]>;
      return response.data.length ? response.data[0] : undefined;
    },
    {
      ...options,
      enabled: options?.enabled !== false && !!orgId,
    }
  );
};

export const useGetAppflowStripeSubscription = (orgId?: string, options?: UseQueryOptions<StripeSubscription | undefined>) => {
  const authState = useAuthState();
  const token = authState.credentials?.token;

  return useQuery<StripeSubscription | undefined, ApiError>(
    KEYS.appflowStripeSubscription(orgId),
    async () => {
      const response = (await callApiThrowError<Customer>(
        {
          endpoint: `/billing/customers/${orgId}`,
          method: 'GET',
        },
        token
      )) as ApiSuccessResponse<Customer>;
      return response.data.subscriptions.length === 1
        ? response.data.subscriptions[0]
        : response.data.subscriptions.find((sub) => Products[sub.plan.product].product === 'Appflow');
    },
    {
      ...options,
      enabled: options?.enabled !== false && !!orgId,
    }
  );
};

export const useGetStudioStripeSubscription = (orgId?: string, options?: UseQueryOptions<StripeSubscription | null | undefined>) => {
  const authState = useAuthState();
  const token = authState.credentials?.token;

  return useQuery<StripeSubscription | null | undefined, ApiError>(
    KEYS.studioStripeSubscription(orgId),
    async () => {
      const response = (await callApiThrowError<Customer>(
        {
          endpoint: `/billing/customers/${orgId}`,
          method: 'GET',
        },
        token
      )) as ApiSuccessResponse<Customer>;
      return response.data.subscriptions.length === 1
        ? null
        : response.data.subscriptions.find((sub) => Products[sub.plan.product].product === 'Studio');
    },
    {
      ...options,
      enabled: options?.enabled !== false && !!orgId,
    }
  );
};

interface UpdateSubscriptionOverridesPayload {
  customerId?: string;
  orgId?: string;
  subscription?: StripeSubscription;
  overageBilling: boolean;
}

export const useUpdateOverageBillingOverrides = (
  options?: Omit<
    UseMutationOptions<Pick<Subscription['limits'], 'overageBilling'>, unknown, UpdateSubscriptionOverridesPayload, unknown>,
    'mutationFn'
  >
) => {
  const authState = useAuthState();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const token = authState.credentials?.token;

  return useMutation<Pick<Subscription['limits'], 'overageBilling'>, ApiErrorResponse, UpdateSubscriptionOverridesPayload>(
    async ({ orgId, subscription, overageBilling }: UpdateSubscriptionOverridesPayload) => {
      const response = (await callApiThrowError<StripeSubscription>(
        {
          endpoint: `/billing/customers/${orgId}/subscriptions/${subscription?.plan.id}/overage-billing`,
          method: 'PATCH',
          body: {
            overage_billing: overageBilling,
          },
        },
        token
      )) as ApiSuccessResponse<StripeSubscription>;
      return response.data as unknown as Pick<Subscription['limits'], 'overageBilling'>;
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        resetBilling(queryClient, variables?.orgId || '');
        options?.onSuccess?.(data, variables, context);
        dispatch(DuckActions.reloadAllUserInfos({}));
      },
    }
  );
};
