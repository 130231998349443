import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { theme } from 'src/styled';

export interface ModalHeaderProps {
  className?: string;
  logoClass?: string;
  logoStyle?: React.CSSProperties;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  style?: React.CSSProperties;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ className, logoClass, logoStyle, title, subTitle }) => {
  return (
    <div className={`modal-header ${className || ''}`}>
      {logoClass && logoStyle && <div className={logoClass} style={logoStyle} />}
      <div className="modal-title-wrapper">
        <ModalTitle>{title}</ModalTitle>
        {subTitle && <span className="sub-title">{subTitle}</span>}
      </div>
    </div>
  );
};

export const ModalBody: React.FC<PropsWithChildren<{ style?: React.CSSProperties }>> = ({ children, style }) => {
  return (
    <div style={style} className="modal-body">
      {children}
    </div>
  );
};

export const ModalFooter: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="modal-footer">{children}</div>;
};

const ModalTitle = styled.h3`
  font-family: ${(props) => props.theme.fonts.text};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes[28]};
  line-height: ${(props) => props.theme.lineHeights['title']};
  letter-spacing: ${(props) => props.theme.letterSpacings['tight']};
  color: ${(props) => props.theme!.colors.carbon[100]};
  margin-top: 0;
  margin-bottom: 0;
`;

ModalTitle.defaultProps = {
  theme: theme,
};

export const ModalBold = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  color: ${(props) => props.theme!.colors.carbon[90]};
`;

export const LeftButton = styled.div`
  margin-right: auto;
`;

export const RightButton = styled.div`
  margin-left: 12px;
`;
