import { ThemeType } from '@ionic-internal/ionic-ds/dist/tokens/tokens';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface TextProps {
  color?: string;
  size?: keyof ThemeType['fontSizes'];
  font?: keyof ThemeType['fonts'];
  fontWeight?: keyof ThemeType['fontWeights'];
  letterSpacing?: keyof ThemeType['letterSpacings'];
  lineHeight?: keyof ThemeType['lineHeights'];
  uppercase?: boolean;
  className?: string;
  style?: React.CSSProperties;
  block?: boolean;
}

export const Text: React.FC<PropsWithChildren<TextProps>> = (props) => <StyledText {...props}>{props.children}</StyledText>;

const StyledText = styled.span<TextProps>`
  font-size: ${(props) => {
    if (props.size) return props.theme.fontSizes[props.size!];
    return 'inherit';
  }};
  font-family: ${(props) => props.theme.fonts[props.font!]};
  line-height: ${(props) => props.theme.lineHeights[props.lineHeight!]};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};

  ${(props) => (props.block ? 'display: block;' : '')};

  ${(props) => (props.color ? `color: ${props.color};` : props.theme.colors.carbon[80])};

  letter-spacing: ${(props) => {
    if (props.uppercase) return props.theme.letterSpacings.wide;
    return props.theme.letterSpacings[props.letterSpacing!];
  }};

  font-weight: ${(props) => props.theme.fontWeights[props.fontWeight!]};

  b {
    font-weight: 700;
  }
`;

StyledText.defaultProps = {
  size: '14',
  font: 'text',
  letterSpacing: 'solid',
  lineHeight: 'title',
  fontWeight: 'medium',
  uppercase: false,
};

export default Text;
