import React from 'react';
import styled from 'styled-components';

interface MenuListProps {
  className?: string;
  children: (React.ReactElement | null)[];
  spacing?: number;
}

export const MenuList: React.FC<MenuListProps> = ({ className, children, spacing = 24 }) => {
  return (
    <MenuListContainer className={className} spacing={spacing}>
      {children
        .filter((child) => child !== null)
        .map((child, index) => (
          <li key={index}>{child}</li>
        ))}
    </MenuListContainer>
  );
};

MenuList.displayName = 'MenuList';

const MenuListContainer = styled.ul`
  padding: 0;
  margin: 0;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  > li {
    margin-bottom: ${(props: { spacing: number }) => props.spacing}px;
    }
  }
`;
MenuListContainer.displayName = 'MenuListContainer';

export default MenuList;
