import React from 'react';
import styled from 'styled-components';
import { flexbox, FlexboxProps } from 'styled-system';
import { Div } from './Div';
import { StyleProps } from './Style';

export interface FlexProps extends FlexboxProps, StyleProps, React.HTMLAttributes<HTMLDivElement> {}

export const Flex = styled(Div)<FlexProps>`
  display: flex;
  ${flexbox}
`;

Flex.displayName = 'Flex';

export interface ColumnProps extends FlexProps {}

export const Column: React.FC<ColumnProps> = (props) => {
  return <Flex flexDirection="column" {...props} />;
};

Column.displayName = 'Column';

export interface RowProps extends FlexProps {}

export const Row: React.FC<RowProps> = (props) => {
  return <Flex flexDirection="row" {...props} />;
};

Row.displayName = 'Row';
