import styled from 'styled-components';

const FormColumn = styled.div`
  width: 100%;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
FormColumn.displayName = 'FormColumn';

export default FormColumn;
