import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';
import { AMPLITUDE_API_KEY, CI_COMMIT_SHA, CI_ENVIRONMENT_SLUG, IS_MOCKING, SENTRY_DSN } from 'src/declarations/constants';
import { DashRoot } from 'src/features/_dashboard/pages/_dashboard/DashRoot';
import { BEAMER_PRODUCT_ID, BeamerConfig, BrowserStorage } from 'src/utils';
// This imports ALL our sass
import './stylesheets/index.scss';

async function prepare() {
  if (IS_MOCKING) {
    const { startWorker } = await import('./mocks/browser');
    return startWorker();
  }
}

// Beamer Config
declare global {
  interface Window {
    beamer_config: BeamerConfig;
  }
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
window.beamer_config = new BeamerConfig({
  product_id: BEAMER_PRODUCT_ID,
  selector: '.beamerTrigger',
  bounce: false,
  button: false,
  lazy: true,
  multi_user: true,
  top: -9,
  left: 5,
});

// Sentry Config
if (SENTRY_DSN !== '') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: CI_ENVIRONMENT_SLUG,
    release: CI_COMMIT_SHA,
  });
}

// Amplitude Config
if (AMPLITUDE_API_KEY) {
  amplitude.init(AMPLITUDE_API_KEY, { defaultTracking: false });
}

if (CI_ENVIRONMENT_SLUG === 'production') {
  const userId = BrowserStorage.userId.get();

  if (userId) {
    dataLayer.push({ userId: userId });
  }
}

prepare().then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  return root.render(<DashRoot />);
});
