import { Action as ReduxAction } from 'redux';
import { StateStore } from 'src/interfaces/StateStore';

export interface Action<T extends string, P> extends ReduxAction<T> {
  type: T;
  payload: P;
}

export interface ActionCall<T extends Action<any, any>> {
  (payload: T['payload']): T;
  type: T['type'];
}

export interface IActions {
  [key: string]: Action<any, any>;
}

export interface ISelectorsInterface {
  [key: string]: (state: StateStore) => any;
}

export type ActionCalls<Actions extends IActions> = { [K in keyof Actions]: ActionCall<Actions[K]> };

export interface Duck<StoreKey extends keyof StateStore, Actions extends IActions> {
  actions: ActionCalls<Actions>;
  reducer(state: StateStore[StoreKey], action: Action<any, any>): StateStore[StoreKey];
  selectors: ISelectorsInterface;
  initialState: StateStore[StoreKey];
  storeKey: StoreKey;
}

export type AllActions<T extends IActions> = T[keyof T];

export abstract class BaseDuck<StoreKey extends keyof StateStore, Actions extends IActions = {}> implements Duck<StoreKey, Actions> {
  protected mainSelect: (state: StateStore) => StateStore[StoreKey];

  constructor(readonly initialState: StateStore[StoreKey], readonly storeKey: StoreKey) {
    this.mainSelect = (state: StateStore) => state[storeKey];
  }

  actions = {} as ActionCalls<Actions>;

  selectors = {};

  reducer(state: StateStore[StoreKey], action: Action<any, any>) {
    return state || this.initialState;
  }
}
