import { themeData } from '@ionic-internal/ionic-ds/dist/tokens/tokens';
import { ThemeType } from '@ionic-internal/ionic-ds/dist/tokens/tokens';
// eslint-disable-next-line no-restricted-imports
import { DefaultTheme } from 'styled-components';

export const theme = {
  ...themeData,
} as DefaultTheme;

export type { ThemeType };
export type ThemeProps = { theme: ThemeType };
