export enum AppflowPlanSKUs {
  free = 'free',
  kickstarter = 'kickstarter',
  developer = 'developer',
  teams = 'teams',
  business = 'business',
  enterprise = 'enterprise',
}

export enum StudioPlanSKUs {
  studio = 'studio',
  'studio-individual' = 'studio-individual',
  'studio-organization' = 'studio-organization',
}

export type StudioPlanSKU = keyof typeof StudioPlanSKUs;
export type AppflowPlanSKU = keyof typeof AppflowPlanSKUs;
export type PlanSKU = AppflowPlanSKU | StudioPlanSKU;

export type AppflowPlanID =
  | 'free'
  | 'kickstarter'
  | 'developer'
  | 'developer-yearly'
  | 'teams'
  | 'teams-yearly'
  | 'business'
  | 'business-monthly'
  | 'enterprise';
type StudioPlanID = 'studio-individual-monthly' | 'studio-individual-yearly' | 'studio-organization-monthly' | 'studio-organization-yearly';
export type PlanID = AppflowPlanID | StudioPlanID;

export type PlanTerm = 'monthly' | 'annual';

export type AppflowPlan = ProductPlan & {
  product: 'appflow';
  sku: AppflowPlanSKU;
  annualVersion?: AppflowPlanID;
  oppositeTermId: AppflowPlanID;
};

export interface ProductPlan {
  name: string;
  sku: PlanSKU;
  id: PlanID;
  monthly: number | string;
  annual: number | string;
  price: number | string;
  term: PlanTerm;
  product: 'studio' | 'appflow';
  annualVersion?: PlanID;
  oppositeTermId: PlanID;
  orgPlan: boolean;
  tiers?: PlanTier[];
}

export interface PlanTier {
  flatAmount: number | null;
  unitAmount: number | null;
  upTo: number | null;
}

export const Products: { [productId: string]: { name: string; sku: string; product: 'Appflow' | 'Studio' } } = {
  // production
  prod_E2vmn5OWKBZABK: {
    name: 'Appflow Starter',
    product: 'Appflow',
    sku: 'kickstarter',
  },
  prod_NOMnfISUotJIWh: {
    name: 'Appflow Free',
    product: 'Appflow',
    sku: 'free',
  },
  prod_BUXOit7VpiuOuJ: {
    name: 'Appflow Starter Add-On',
    product: 'Appflow',
    sku: 'developer',
  },
  prod_BiloWBVbqj5kPn: {
    name: 'Appflow Growth',
    product: 'Appflow',
    sku: 'teams',
  },
  prod_BTi1KpcZivTqya: {
    name: 'Appflow Scale',
    product: 'Appflow',
    sku: 'business',
  },
  prod_EybgANMeOswmCY: {
    name: 'Studio',
    product: 'Studio',
    sku: 'studio',
  },
  // staging
  prod_DzrIjKT5smaZ2c: {
    name: 'Appflow Starter',
    product: 'Appflow',
    sku: 'kickstarter',
  },
  prod_NFOORDoKajifoZ: {
    name: 'Appflow Free',
    product: 'Appflow',
    sku: 'free',
  },
  prod_BTsHumGs2UYfbs: {
    name: 'Appflow Starter Add-On',
    product: 'Appflow',
    sku: 'developer',
  },
  prod_Beynq82h0odb2L: {
    name: 'Appflow Growth',
    product: 'Appflow',
    sku: 'teams',
  },
  prod_BTo54j80peP4YG: {
    name: 'Appflow Scale',
    product: 'Appflow',
    sku: 'business',
  },
  prod_EtNSMM8RotOXXy: {
    name: 'Studio',
    product: 'Studio',
    sku: 'studio',
  },
};

export const appflowPlanPricing: { [id in AppflowPlanSKU | AppflowPlanID]: AppflowPlan } = {
  developer: {
    name: 'Community',
    sku: 'developer',
    id: 'developer',
    product: 'appflow',
    term: 'monthly',
    price: 49,
    monthly: 49,
    annual: 499,
    annualVersion: 'developer-yearly',
    oppositeTermId: 'developer-yearly',
    orgPlan: false,
  },
  'developer-yearly': {
    name: 'Community',
    sku: 'developer',
    id: 'developer-yearly',
    product: 'appflow',
    term: 'annual',
    price: 499,
    monthly: 49,
    annual: 499,
    oppositeTermId: 'developer',
    orgPlan: false,
  },
  teams: {
    name: 'Basic',
    sku: 'teams',
    id: 'teams',
    term: 'monthly',
    price: 499,
    product: 'appflow',
    monthly: 499,
    annual: 4999,
    annualVersion: 'teams-yearly',
    oppositeTermId: 'teams-yearly',
    orgPlan: true,
    tiers: [
      {
        flatAmount: 499,
        unitAmount: null,
        upTo: 5,
      },
      {
        flatAmount: null,
        unitAmount: 99,
        upTo: null,
      },
    ],
  },
  'teams-yearly': {
    name: 'Basic',
    sku: 'teams',
    id: 'teams-yearly',
    product: 'appflow',
    term: 'annual',
    price: 4999,
    monthly: 499,
    oppositeTermId: 'teams',
    annual: 4999,
    orgPlan: true,
    tiers: [
      {
        flatAmount: 4999,
        unitAmount: null,
        upTo: 5,
      },
      {
        flatAmount: null,
        unitAmount: 999,
        upTo: null,
      },
    ],
  },
  business: {
    name: 'Standard',
    sku: 'business',
    id: 'business',
    term: 'annual',
    product: 'appflow',
    oppositeTermId: 'business-monthly',
    price: 'Starting at $2,499/mo',
    monthly: 'Starting at $2,499/mo',
    annual: 'Starting at $2,499/mo',
    orgPlan: true,
  },
  'business-monthly': {
    name: 'Standard',
    sku: 'business',
    id: 'business-monthly',
    product: 'appflow',
    term: 'monthly',
    oppositeTermId: 'business',
    price: 'Starting at $2,499/mo',
    monthly: 'Starting at $2,499/mo',
    annual: 'Starting at $2,499/mo',
    orgPlan: true,
  },
  enterprise: {
    name: 'Enterprise',
    sku: 'enterprise',
    id: 'enterprise',
    term: 'annual',
    product: 'appflow',
    oppositeTermId: 'enterprise',
    price: 'Custom',
    monthly: 'Custom',
    annual: 'Custom',
    orgPlan: true,
  },
  free: {
    name: 'Free',
    sku: 'free',
    id: 'free',
    product: 'appflow',
    term: 'monthly',
    price: 0,
    monthly: 0,
    annual: 0,
    oppositeTermId: 'free',
    orgPlan: true,
  },
  kickstarter: {
    name: 'Hobby',
    sku: 'kickstarter',
    id: 'kickstarter',
    product: 'appflow',
    term: 'monthly',
    price: 0,
    monthly: 0,
    annual: 0,
    oppositeTermId: 'kickstarter',
    orgPlan: true,
  },
};

export const studioPlanPricing: { [id in StudioPlanSKU | StudioPlanID]: ProductPlan } = {
  studio: {
    name: 'Just Studio',
    sku: 'studio-individual',
    id: 'studio-individual-monthly',
    product: 'studio',
    term: 'monthly',
    price: 29,
    oppositeTermId: 'studio-individual-yearly',
    monthly: 29,
    annual: 299,
    orgPlan: false,
  },
  'studio-individual': {
    name: 'Just Studio',
    sku: 'studio-individual',
    id: 'studio-individual-monthly',
    product: 'studio',
    term: 'monthly',
    price: 29,
    oppositeTermId: 'studio-individual-yearly',
    monthly: 29,
    annual: 299,
    orgPlan: false,
  },
  'studio-individual-monthly': {
    name: 'Just Studio',
    sku: 'studio-individual',
    id: 'studio-individual-monthly',
    product: 'studio',
    term: 'monthly',
    price: 29,
    oppositeTermId: 'studio-individual-yearly',
    monthly: 29,
    annual: 299,
    orgPlan: false,
  },
  'studio-individual-yearly': {
    name: 'Just Studio',
    sku: 'studio-individual',
    id: 'studio-individual-yearly',
    product: 'studio',
    term: 'annual',
    price: 299,
    oppositeTermId: 'studio-individual-monthly',
    monthly: 29,
    annual: 299,
    orgPlan: false,
  },
  'studio-organization': {
    name: 'Studio for Organizations',
    sku: 'studio-organization',
    id: 'studio-organization-monthly',
    product: 'studio',
    term: 'monthly',
    oppositeTermId: 'studio-organization-yearly',
    price: 99,
    monthly: 99,
    annual: 0,
    orgPlan: true,
  },
  'studio-organization-monthly': {
    name: 'Studio for Organizations',
    sku: 'studio-organization',
    id: 'studio-organization-monthly',
    product: 'studio',
    term: 'monthly',
    price: 99,
    oppositeTermId: 'studio-organization-yearly',
    monthly: 99,
    annual: 0,
    orgPlan: true,
  },
  'studio-organization-yearly': {
    name: 'Studio for Organizations',
    sku: 'studio-organization',
    id: 'studio-organization-yearly',
    product: 'studio',
    term: 'annual',
    oppositeTermId: 'studio-organization-monthly',
    price: 0,
    monthly: 0,
    annual: 0,
    orgPlan: true,
  },
};

export const StudioPlanIndividual = studioPlanPricing['studio-individual'];
export const StudioPlanIndividualYearly = studioPlanPricing['studio-individual-yearly'];
export const StudioPlanOrganization = studioPlanPricing['studio-organization'];
export const StudioPlanOrganizationYearly = studioPlanPricing['studio-organization-yearly'];

export function isAppflowPlanSKU(sku: string): sku is AppflowPlanSKU {
  return ['developer', 'teams', 'starter', 'business', 'enterprise', 'kickstarter', 'free'].includes(sku);
}

function isStudioPlanSKU(sku: string): sku is StudioPlanSKU {
  return ['studio-individual', 'studio-organization'].includes(sku);
}

export function isPlanSKU(sku: string | undefined): sku is PlanSKU {
  return sku !== undefined && (isAppflowPlanSKU(sku) || isStudioPlanSKU(sku));
}

export function isAppflowPlanID(id: string): id is AppflowPlanID {
  return appflowPlanPricing.hasOwnProperty(id);
}

function isStudioPlanID(id: string): id is StudioPlanID {
  return studioPlanPricing.hasOwnProperty(id);
}

export function isPlanID(id: string | null): id is PlanID {
  return id !== null && (isAppflowPlanID(id) || isStudioPlanID(id));
}

export function isPlanTerm(term: string | undefined): term is PlanTerm {
  return term === 'monthly' || term === 'annual';
}

const allPricing = { ...appflowPlanPricing, ...studioPlanPricing };

export default allPricing;
