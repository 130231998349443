import debounce from 'debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ItemInfo, ItemInfoTitle, ListItem } from 'src/components';

interface App {
  id: string;
  name: string;
  icon: string;
}

export interface SearchAppsProps {
  searchApps: () => void;
  apps: App[];
  isOpen: boolean;
  onCloseSearch: () => void;
  onNavigateToApp: (app: App) => void;
  query: string;
  setQuery: (query: string) => void;
}

export const SearchApps: React.FC<SearchAppsProps> = ({ searchApps, apps, isOpen, onCloseSearch, onNavigateToApp, query, setQuery }) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isOpen) {
      setActiveIndex(-1);
      if (query.length > 0) {
        setQuery('');
        searchApps();
      }
    } else if (searchInputRef && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen, setActiveIndex, setQuery, searchApps, query.length]);

  const clear = () => {
    if (searchInputRef && searchInputRef.current) {
      searchInputRef.current.blur();
    }

    setActiveIndex(-1);
    setQuery('');
    searchApps();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const code = event.code;
    let newIndex = activeIndex;

    let preventDefault = false;

    if (code === 'ArrowUp') {
      newIndex = Math.max(-1, activeIndex - 1);
      preventDefault = true;
    } else if (code === 'ArrowDown') {
      // Down arrow
      newIndex = Math.min(apps.length - 1, activeIndex + 1);
      preventDefault = true;
    } else if (code === 'Enter') {
      const app = apps[activeIndex];
      if (app) {
        clear();
        onCloseSearch();
        onNavigateToApp(app);
        preventDefault = true;
      }
    } else if (code === 'Escape') {
      clear();
      onCloseSearch();
      preventDefault = true;
    }

    setActiveIndex(newIndex);
    preventDefault && event.preventDefault();
  };

  const handleFocus = () => {
    setActiveIndex(-1);
  };

  const debounceChange = useMemo(() => {
    return debounce(searchApps, 600);
  }, [searchApps]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;

    // Keep active index bound by length of result set
    const newIndex = Math.min(activeIndex, apps.length - 1);
    setQuery(value);
    debounceChange();
    setActiveIndex(newIndex);
  };

  let searchResultsClass = apps.length > 0 ? 'active' : '';

  return (
    <SearchWrapper className={isOpen ? 'open ' : ''}>
      <div className={'search-form'}>
        <SearchInput
          id="header-search"
          type="text"
          name="search"
          placeholder="Search my apps"
          autoComplete="off"
          autoFocus
          value={query}
          onClick={(e) => e.stopPropagation()}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          ref={searchInputRef}
        />

        <SearchResult className={searchResultsClass}>
          {apps.map((app, index) => {
            return (
              <SearchResultItem key={app.id} className={index === activeIndex ? 'active' : ''}>
                <Link to={`/app/${app.id}`} onClick={() => onCloseSearch()}>
                  <ItemInfo title={app.name} subTitle={app.id.toUpperCase()} icon={app.icon} />
                </Link>
              </SearchResultItem>
            );
          })}
        </SearchResult>
      </div>
    </SearchWrapper>
  );
};

const SearchWrapper = styled.div`
  z-index: 1;
  left: 64px;
  top: 0;
  background-color: white;
  height: 100%;
  width: 320px;
  pointer-events: all;
  overflow: auto;
  opacity: 1;
  padding: 0;
  border-radius: 0 8px 8px 0;
  transform: translateX(-320px);
  transition: transform 0.15s;

  &.open {
    transform: translateX(0px);
    display: block;
    box-shadow: 8px 0 64px rgba(0, 8, 32, 0.1);
  }
`;

const SearchInput = styled.input`
  border: 0;
  outline: none;
  width: 100%;
  background-color: rgba(white, 0.15);
  color: #272c37;
  height: 32px;
  font-size: 18px;
  box-shadow: none;
  margin: 20px 0;
  padding: 0 20px;
  font-weight: 400;
  letter-spacing: -0.01em;

  &::placeholder {
    color: #838d9d;
    font-size: 1em;
    display: inline-block;
  }
`;

const SearchResult = styled.ul`
  background: white;
  position: relative;
  top: -90000px;
  width: 100%;
  list-style: none;
  padding: 0;
  opacity: 0;
  transform: translate3d(0, -10px, 0);
  transition: opacity 0.25s, border-radius 0.25s, transform 0.25s cubic-bezier(0.36, 0.66, 0.04, 1), top 0.25s step-end;

  &.active {
    top: 0;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.25s, transform 0.25s cubic-bezier(0.36, 0.66, 0.04, 1);
  }
`;

// TODO: Have this be ItemInfoSmall
const SearchResultItem = styled(ListItem)`
  transition: 0.25s background;
  padding: 0;

  &:hover,
  &.active {
    background: #f3f5f8;
  }

  ${ItemInfoTitle} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  a {
    padding: 14px 16px;
    width: 100%;
  }
`;

export default SearchApps;
