import { addDays, isPast } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { HubSpotModal, Text } from 'src/components';
import { alertCircle, checkmarkCircle } from 'src/components/icon';
import { HubspotFormsIds } from 'src/declarations/constants';
import { Organization } from 'src/graphql/generated';
import { useGetCurrentUserQuery, useGetDataForNotificationBarQuery, useResendVerificationMutation } from 'src/graphql/generated-hooks';
import { StateStore, UserMetadata } from 'src/interfaces';
import { useGetAppflowSubscription } from 'src/queries/billing';
import { useCurrentOrgSlug } from 'src/queries/currentOrgSlug';
import { DuckSelectors } from 'src/redux/reducks';
import { theme } from 'src/styled/theme';
import { BrowserStorage, planPricing } from 'src/utils';
import HeaderNotification from '../header-notification/HeaderNotification';

export interface NotificationBarProps extends ConnectedProps<typeof connector> {
  hasApps: boolean;
  downloadCount: number;
  org: Pick<Organization, 'id' | 'buildCreditsAvailable' | 'slug'>;
  onResendVerificationEmail: () => void;
  userMetadata?: UserMetadata;
  navigate: (path: string) => void;
}

export const NotificationBar: React.FC<NotificationBarProps> = ({ org, user, downloadCount, navigate, onResendVerificationEmail }) => {
  const [impersonationSecondsLeft, setImpersonationSecondsLeft] = useState<number>(0);
  const { data: subscription } = useGetAppflowSubscription(org.id);
  const [showLimitIncreaseModal, setShowLimitIncreaseModal] = useState<boolean>();
  const [headerNotificationsShown, setHeaderNotificationsShown] = useState(0);

  const showHeaderNotification = () => {
    setHeaderNotificationsShown(headerNotificationsShown + 1);
  };

  const hideHeaderNotification = () => {
    setHeaderNotificationsShown(headerNotificationsShown - 1);
  };

  const handleUsageHeaderButtonClick = () => {
    if (subscription?.name !== planPricing.business.sku && subscription?.name !== planPricing.teams.sku) {
      navigate(`/org/${org.slug}/settings/subscriptions`);
    } else {
      setShowLimitIncreaseModal(true);
    }
  };

  const shouldShowDeployCountUsageHeader = () => {
    if (subscription && subscription.name !== planPricing.business.sku) {
      return downloadCount >= subscription.limits.deployCount;
    }
    return false;
  };

  let buildCreditsUsageHeader: JSX.Element | undefined;
  // we don't need to check build credits if overage billing is explicitly enabled
  if (subscription?.limits.overageBilling !== true && org.buildCreditsAvailable === false) {
    buildCreditsUsageHeader = (
      <HeaderNotification
        icon={alertCircle}
        text={
          <Text>
            <Text fontWeight="semibold">Build credits limit reached!</Text> To continue building applications, please review your plan
            options.
          </Text>
        }
        textColor={theme.colors.white}
        color={theme.colors.red[90]}
        buttonText="Review plan"
        showCloseButton
        buttonAction={handleUsageHeaderButtonClick}
        showHeaderNotification={showHeaderNotification}
        hideHeaderNotification={hideHeaderNotification}
      />
    );
  }

  const shouldShowEmailVerificationHeader = () => {
    if (!user) {
      return;
    }

    const dayAfterUserWasCreated = addDays(new Date(user.created!), 1);
    return !user.verified && user.created && isPast(dayAfterUserWasCreated);
  };

  const unimpersonate = async () => {
    BrowserStorage.overwriteTime.delete();
    BrowserStorage.overwriteToken.delete();
    BrowserStorage.overwriteUserId.delete();
    BrowserStorage.recentApps.delete();

    // This very intentionally, very lazily uses a full page reload to reload all the user info
    // and remove any info that might be persisted from the impersonated user.
    window.location.href = '/';
  };

  let deployCountUsageHeaderText: string | undefined, deployCountUsageButtonText: string | undefined;

  if (shouldShowDeployCountUsageHeader()) {
    if (subscription) {
      const deployCount = subscription.limits.deployCount.toLocaleString();
      deployCountUsageHeaderText = `You have hit the Deploy limit of ${deployCount} for ${
        planPricing[subscription.name as keyof typeof planPricing].name
      }.`;
      deployCountUsageButtonText = 'Upgrade now';
      if (subscription.name === planPricing.teams.sku || subscription.name === planPricing.business.sku) {
        deployCountUsageHeaderText += ' Contact us to keep sending live updates.';
        deployCountUsageButtonText = 'Contact us';
      } else if (subscription.name === planPricing.developer.sku) {
        deployCountUsageHeaderText += ` To keep sending live updates you must upgrade to ${planPricing.teams.name}.`;
      } else if (subscription.name === planPricing.kickstarter.sku || subscription.name === planPricing.free.sku) {
        deployCountUsageHeaderText += ` To keep sending live updates you must upgrade to ${planPricing.developer.name}.`;
      }
    }
  }

  useEffect(() => {
    const impersonationTimeString = BrowserStorage.overwriteTime.get();
    const impersonationTime = impersonationTimeString ? parseInt(impersonationTimeString, 10) : 0;
    if (impersonationTime) {
      const impersonateDuration = 60 * 60; // 1 hour in seconds
      setImpersonationSecondsLeft(impersonateDuration - Math.floor((new Date().getTime() - impersonationTime) / 1000));
      const interval = setInterval(() => {
        setImpersonationSecondsLeft((prevState) => {
          const nextState = prevState - 1;
          if (nextState <= 0) {
            clearInterval(interval);
            unimpersonate();
          }

          return nextState;
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }

    // We bind to the user because we want to re-run this effect when the user changes
    // like, during impersonation
  }, [user]);

  return (
    <NotificationBarContainer>
      {impersonationSecondsLeft > 0 && (
        <HeaderNotification
          icon={checkmarkCircle}
          text={`You are currently impersonating ${user?.email}. (Session expires ${new Intl.RelativeTimeFormat('en', {
            numeric: 'auto',
          }).format(Math.floor(impersonationSecondsLeft / 60), 'minutes')})`}
          textColor="#5f4100"
          color={theme.colors.yellow[60]}
          showCloseButton={false}
          buttonText="Stop Impersonating"
          buttonTextColor="#5f4100"
          buttonAction={unimpersonate}
          skipTimedShow={true}
          showHeaderNotification={showHeaderNotification}
          hideHeaderNotification={hideHeaderNotification}
        />
      )}
      {impersonationSecondsLeft <= 0 && shouldShowEmailVerificationHeader() && (
        <HeaderNotification
          icon={checkmarkCircle}
          text={`Your email address is ${user?.email}. We need to verify your email to continue using the service.`}
          textColor="#5f4100"
          color={theme.colors.yellow[60]}
          showCloseButton={false}
          buttonText="Resend verification email"
          buttonTextColor="#5f4100"
          buttonAction={() => onResendVerificationEmail()}
          showHeaderNotification={showHeaderNotification}
          hideHeaderNotification={hideHeaderNotification}
        />
      )}
      {shouldShowDeployCountUsageHeader() && deployCountUsageHeaderText && (
        <HeaderNotification
          icon={alertCircle}
          text={deployCountUsageHeaderText}
          textColor={theme.colors.white}
          color={theme.colors.red[90]}
          buttonText={deployCountUsageButtonText}
          showCloseButton={true}
          buttonAction={handleUsageHeaderButtonClick.bind(this)}
          showHeaderNotification={showHeaderNotification}
          hideHeaderNotification={hideHeaderNotification}
        />
      )}
      {buildCreditsUsageHeader}
      {showLimitIncreaseModal && (
        <HubSpotModal
          formId={HubspotFormsIds.live_update_limit_increase}
          title="Request Limit Increase"
          subtitle="To better help us allocate resources for your needs. Someone will contact you at the email address provided to increase your limits."
          successTitle="Your request has been submitted"
          successSubtitle="We’ve received your info and a member of our team will get in touch as soon as possible."
          closeModal={() => setShowLimitIncreaseModal(false)}
        />
      )}
    </NotificationBarContainer>
  );
};

const mapStateToProps = (state: StateStore) => {
  const user = DuckSelectors.getUser(state);

  return {
    user,
  };
};

const connector = connect(mapStateToProps, {});

interface NotificationBarLoaderProps extends ConnectedProps<typeof connector> {}

const NotificationBarLoader: React.FC<NotificationBarLoaderProps> = (props) => {
  const navigate = useNavigate();
  const { mutate: resendVerificationEmail } = useResendVerificationMutation();
  const currentOrgSlug = useCurrentOrgSlug();

  const { data: { hasApps, downloadCount, organization } = { hasApps: false, downloadCount: 0 }, isLoading: isLoadingHasApps } =
    useGetDataForNotificationBarQuery(
      { slug: currentOrgSlug! },
      {
        select: (data) => ({
          hasApps: data.apps.totalCount > 0,
          downloadCount: data.organization?.deployCount[0]?.total || 0,
          organization: data.organization,
        }),
        enabled: !!currentOrgSlug,
      }
    );

  const { data: userData, isLoading: isLoadingUser } = useGetCurrentUserQuery();
  const userMetadata = userData?.viewer?.dashMetadata ? (JSON.parse(userData?.viewer?.dashMetadata?.data) as UserMetadata) : undefined;

  const handleResendVerificationEmail = () => {
    resendVerificationEmail({ input: {} });
  };

  if (isLoadingUser || isLoadingHasApps || hasApps === undefined || !organization) {
    return <></>;
  }

  return (
    <NotificationBar
      {...props}
      org={organization}
      hasApps={hasApps}
      downloadCount={downloadCount}
      onResendVerificationEmail={handleResendVerificationEmail}
      userMetadata={userMetadata}
      navigate={navigate}
    />
  );
};

export default connector(NotificationBarLoader);

const NotificationBarContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1004;
`;
