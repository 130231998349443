import * as amplitude from '@amplitude/analytics-browser';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppLoadingSplash } from 'src/components';
import { IS_MOCKING, IS_TEST, LAUNCH_DARKLY_CLIENT_SIDE_ID } from 'src/declarations/constants';
import { DuckSelectors, rawActions } from 'src/redux/reducks';
import { initializeBeamer } from 'src/utils';
import { completeAuthorizationRequest } from 'src/utils/auth';
import { BootstrapProvider } from './BootstrapContext';
import { MainRoutes } from './MainRoutes';

export const BootstrapApp = () => {
  const { bootStart, receiveOAuth2Auth } = rawActions;
  const bootInfo = useSelector(DuckSelectors.getBootInfo);
  const dispatch = useDispatch();
  const user = useSelector(DuckSelectors.getUser);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    const sendAnalytics = (eventName: string, properties: Record<string, unknown>) => {
      amplitude.track(eventName, { ...properties });
    };

    const user_firstname = user.name.split(' ')[0];
    const user_lastname = user.name.replace(user_firstname, '');
    const user_plans = user.orgs
      ?.map((org) => org.plan)
      .reduce((plans, plan) => (plans.includes(plan) ? plans : plans.concat(plan)), [] as string[]); // remove duplicates;

    if (import.meta.env.VITE_DISABLE_BEAMER != 'true') {
      initializeBeamer({
        user_id: String(user.id),
        user_email: user.email,
        user_firstname,
        user_lastname,
        user_plans: user_plans?.join(';'),
        lazy: true,
        onclick: (post_url: string) => {
          sendAnalytics('beamer_open_post', { post: post_url });
        },
        onopen: () => {
          sendAnalytics('beamer_open', {});
        },
      });
    }
  }, [user, dispatch]);

  // Start the bootstrap saga
  // Note the second arg (array) of useEffect signals the variable to watch and rerun the effect on using
  // an empty array ensures it only fires the first time
  const location = useLocation();
  React.useEffect(() => {
    completeAuthorizationRequest().then(({ err, result }) => {
      if (err || result) {
        // if we're doing OAuth2 login receiveOAuth2Auth will bootstrap us from previous state;
        dispatch(receiveOAuth2Auth({ err, result, pathname: location.pathname, search: location.search }));
      } else {
        // otherwise kickoff regular bootstrap with no restored state
        dispatch(bootStart({ pathname: location.pathname, search: location.search }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (bootInfo.isLoading) {
    return <AppLoadingSplash />;
  }

  const ldUser = user
    ? {
        kind: 'user',
        key: `user-${user.id}`,
        name: user.name,
        email: user.email,
      }
    : undefined;

  const pathOverride: string | undefined =
    bootInfo.pathOverride !== window.location.href.split(window.location.origin)[1] ? bootInfo.pathOverride : undefined;

  return (
    <BootstrapProvider>
      <LDProvider
        clientSideID={LAUNCH_DARKLY_CLIENT_SIDE_ID}
        deferInitialization
        context={ldUser}
        options={{ streaming: !(IS_MOCKING || IS_TEST) }}
      >
        <MainRoutes isAuthed={bootInfo.isAuthed} pathOverride={pathOverride} />
      </LDProvider>
    </BootstrapProvider>
  );
};
